import React from "react";
import QuoteOpenIcon from "../../../../../../icons/QuoteOpenIcon";
import scss from "./styles.module.scss";

interface IProps {
  title: string;
  text: string;
  iconColor?: string;
}

const TeamMemberFeature = ({ title, text, iconColor }: IProps) => {
  return (
    <div className={scss.featureWrapper}>
      <div className={scss.iconWrapper} style={{ color: iconColor }}>
        <QuoteOpenIcon width={16} height={12} />
      </div>
      <div className={scss.featureContentWrapper}>
        <h5 className={scss.featureTitle}>{title}</h5>
        <p className={scss.featureDescription}>{text}</p>
      </div>
    </div>
  );
};

export default TeamMemberFeature;
