import React, { memo, SVGProps } from "react";

const ClutchLogoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.4216 10.7819C10.7261 11.3382 9.75239 11.7555 8.7787 11.7555C6.55313 11.7555 5.02305 10.0864 5.02305 7.86079C5.02305 5.63522 6.55313 4.10514 8.7787 4.10514C9.75239 4.10514 10.7261 4.38334 11.4216 5.07883L11.8389 5.49612L14.0644 3.40965L13.508 2.99236C12.2562 1.87957 10.587 1.18408 8.7787 1.18408C4.88395 1.18408 1.96289 4.10514 1.96289 7.99989C1.96289 11.8946 4.88395 14.8157 8.7787 14.8157C10.587 14.8157 12.2562 14.1202 13.508 13.0074L14.0644 12.5901L11.8389 10.3646L11.4216 10.7819Z"
        fill="#E6F4FA"
      />
      <path
        d="M8.63963 10.2256C9.86878 10.2256 10.8652 9.22913 10.8652 7.99999C10.8652 6.77084 9.86878 5.77441 8.63963 5.77441C7.41049 5.77441 6.41406 6.77084 6.41406 7.99999C6.41406 9.22913 7.41049 10.2256 8.63963 10.2256Z"
        fill="#EF4335"
      />
    </svg>
  );
};

export default memo(ClutchLogoIcon);
