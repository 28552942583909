import React from "react";
import scss from "./styles.module.scss";
import { PageBlockText } from "../../../../../../../../api/contracts";
import cn from "classnames";
import resolutionStore from "../../../../../../../../store/ResolutionStore";
import { observer } from "mobx-react-lite";

interface IProps {
  block: PageBlockText;
  stepNumber?: number;
  index: number;
}

const TextBlock = ({ block, stepNumber, index }: IProps) => {
  const { isDesktop, isMobile, isTablet, isLaptop } =
    resolutionStore.resolutionState;

  return (
    <div
      className={cn(scss.container, {
        [scss.horizontal]: block.direction === "horizontal",
      })}
    >
      {/*{block.styles?.borderTop &&*/}
      {!(block.direction === "vertical" && index === 0) && (
        <div className={scss.stepTitleWithSeparatorWrapper}>
          {block.stepTitle && isDesktop && (
            <h5
              className={scss.stepTitleWithSeparator}
            >{`step ${stepNumber}: ${block.stepTitle}`}</h5>
          )}
          {<div className={scss.separator} />}
        </div>
      )}
      <div className={cn(scss.contentWrapper)}>
        <div className={scss.titleWrapper}>
          <h3
            className={cn(scss.title, {
              [scss.withContent]: !!block.description?.length,
            })}
          >
            {block.title}
          </h3>
          {(isMobile ||
            isTablet ||
            (block.direction === "vertical" && (isLaptop || isDesktop))) && (
            <div className={scss.stepTitleWrapper}>
              {block.stepTitle && (
                <h5>
                  {`step ${stepNumber}: `}
                  {!isMobile && <br />}
                  <span>{block.stepTitle}</span>
                </h5>
              )}
            </div>
          )}
        </div>
        {/*{block.direction === "vertical" && (isLaptop || isDesktop) && (*/}
        {/*  <div className={cn(scss.stepTitleWrapper, scss.leftAlign)}>*/}
        {/*    {block.stepTitle && (*/}
        {/*      <h5>*/}
        {/*        {`step ${stepNumber}: `}*/}
        {/*        {!isMobile && <br />}*/}
        {/*        <span>{block.stepTitle}</span>*/}
        {/*      </h5>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*)}*/}
        <div className={scss.descriptionWrapper}>
          {block.description?.map((item, index, array) => (
            <div
              className={cn(scss.descriptionItemWrapper, {
                [scss.withBorder]: !!item.subtitle,
              })}
              key={block.id.toString() + index + block.id.toString()}
            >
              {item.subtitle && (
                <h4 className={scss.subTitle}>{item.subtitle}</h4>
              )}
              {item.text.map((textItem, i, arr) => {
                const lastItemInBlock =
                  index === array.length - 1 && i === arr.length - 1;
                return (
                  <p
                    key={block.id.toString() + i}
                    className={cn(scss.text, {
                      [scss.withoutMargin]: lastItemInBlock,
                    })}
                  >
                    {textItem.title}
                  </p>
                );
              })}
            </div>
          ))}
        </div>
        {isLaptop && block.direction === "horizontal" && (
          <div className={scss.stepTitleWrapper}>
            {block.stepTitle && (
              <h5>
                {`step ${stepNumber}:`}
                <br />
                <span>{block.stepTitle}</span>
              </h5>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(TextBlock);
