import React from "react";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import BlogMainSection from "./components/BlogMainSection/BlogMainSection";
import PageSection from "../../components/shared/PageSection/PageSection";
import { PageContextProvider } from "../../context/PageContext";
import PageSEO from "../components/PageSEO/PageSEO";
import Footer from "../../components/Footer/Footer";
import { Page } from "../../api/contracts";
import PageContent from "../components/PageContent/PageContent";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";
import PageLayoutSidebar from "../../components/Layouts/PageLayoutSidebar/PageLayoutSidebar";
import scss from "../Home/styles.module.scss";

const Blog = (props: Page) => {
  const enabledSidebar = !!props.sidebarWidgets.length;
  return (
    <PageContextProvider page={props}>
      <PageSEO {...props.seo} />
      <MainLayout hasIsland islandBorderColor="var(--dark-2)">
        <PageSection>
          <BlogMainSection />
        </PageSection>
        <PageContent sections={props.mainSections} />
        <ContentLayout
          enabledSidebar={enabledSidebar}
          sidebar={
            <PageLayoutSidebar
              widgets={props.sidebarWidgets}
              sidebar={props.sidebar}
            />
          }
          className={scss.contentLayout}
        >
          {/*<PageSection>*/}
          {/*  <OurTeamAndJoinUsGrid />*/}
          {/*</PageSection>*/}
          <PageContent
            sections={props.contentSections}
            pageWithSidebar={enabledSidebar}
          />
        </ContentLayout>
      </MainLayout>
      <Footer />
    </PageContextProvider>
  );
};

export default Blog;
