import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { PageSection } from "../../../../../../api/contracts";
import ArrowChevronDown from "../../../../../../icons/ArrowChevronDown";
import MenuSelect from "../../../../../../components/shared/MenuSelect/MenuSelect";
import NavMenuItem from "../NavMenuItem/NavMenuItem";
import scss from "./styles.module.scss";

interface IProps {
  navItems: PageSection[];
  classname?: string;
  flexOrder?: number;
  activeIndex: number;
}

const DropdownNavMenu = ({
  navItems,
  classname,
  flexOrder,
  activeIndex,
}: IProps) => {
  const [open, setOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(document.createElement("div"));

  const openHandler = () => {
    setOpen((v) => !v);
  };

  useEffect(() => {
    const onClose = (e: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      )
        setOpen(false);
    };

    window.addEventListener("click", onClose);

    return () => {
      window.removeEventListener("click", onClose);
    };
  }, []);
  return (
    <div
      ref={containerRef}
      className={cn(scss.container, classname)}
      style={{ order: flexOrder }}
    >
      <div onClick={openHandler} className={scss.buttonWrapper}>
        <div className={cn(scss.title)}>
          <div className={cn(scss.menuTitle, { [scss.titleOpen]: open })}>
            Table of contents
          </div>
          <div
            className={cn(scss.menuActiveItem, {
              [scss.menuActiveItemHide]: open,
            })}
          >
            {`${
              activeIndex + 1 > 0
                ? (activeIndex + 1).toString().padStart(2, "0")
                : "01"
            } - ${navItems[activeIndex]?.title || navItems[0]?.title}`}
          </div>
        </div>
        <div className={cn(scss.icon, { [scss.open]: open })}>
          <ArrowChevronDown />
        </div>
      </div>
      <MenuSelect
        canCloseMenuByScroll
        showMenuClassname={scss.menuShow}
        onClose={openHandler}
        open={open}
        target={containerRef.current || undefined}
        placement={"bottom"}
        menuClassname={scss.menu}
      >
        <div className={cn(scss.menuSelectWrapper, { [scss.menuOpen]: open })}>
          {/*<h2 className={scss.menuTitle}>TABLE OF CONTENTS</h2>*/}
          {navItems.map((item, i) => (
            <NavMenuItem
              key={item.id + i}
              title={item.title}
              sectionNumber={i + 1}
              id={item.id}
              isActive={i === activeIndex}
            />
          ))}
        </div>
      </MenuSelect>
    </div>
  );
};

export default DropdownNavMenu;
