import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import TeamMemberCard from "../TeamMemberCard/TeamMemberCard";
import cn from "classnames";
import { TeamMemberDto } from "../../../../../../api/contracts";
import ArrowChevronDown from "../../../../../../icons/ArrowChevronDown";
import { observer } from "mobx-react-lite";
import resolutionStore from "../../../../../../store/ResolutionStore";
import scss from "./styles.module.scss";

interface IProps {
  classname?: string;
  team: TeamMemberDto[];
  activeIndex: number;
  setAnimate: Dispatch<SetStateAction<boolean>>;
  setActive: Dispatch<SetStateAction<number>>;
  listMaxHeight?: number;
}

const TeamMembersList = ({
  classname,
  team,
  setAnimate,
  activeIndex,
  setActive,
  listMaxHeight,
}: IProps) => {
  const listWrapperRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { desktopXlg, laptop, laptopLg, laptopHd } = resolutionStore.resolution;

  const isDesktop = desktopXlg || laptop || laptopHd || laptopLg;

  useEffect(() => {
    if (isDesktop) {
      const button = bottomRef.current;
      if (button) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => setDisabled(entry.isIntersecting));
        });

        observer.observe(button);

        return () => {
          observer.unobserve(button);
        };
      }
    }
  }, [isDesktop]);

  useEffect(() => {
    if (listWrapperRef.current) {
      setShowMore(
        isDesktop &&
          listWrapperRef.current.scrollHeight >
            listWrapperRef.current.clientHeight
      );
    }
  }, [isDesktop]);

  const clickHandler = () => {
    if (listWrapperRef.current && showMore) {
      listWrapperRef.current.scrollBy({
        top: listWrapperRef.current.clientHeight,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={cn(scss.membersList, classname)}>
      <div
        ref={listWrapperRef}
        className={cn(scss.membersItemsWrapper, "hiddenScroll")}
        style={{ maxHeight: listMaxHeight }}
      >
        {team.map((item, i) => (
          <TeamMemberCard
            setAnimate={setAnimate}
            card={item}
            onClick={setActive}
            activeIndex={activeIndex}
            index={i}
            key={item.id}
            parentRef={listWrapperRef}
          />
        ))}
        {isDesktop ? (
          <div ref={bottomRef} className={scss.listBottomZone} />
        ) : null}
      </div>
      {showMore ? (
        <div className={scss.showMoreButtonWrapper}>
          <button
            onClick={clickHandler}
            className={cn(scss.showMoreButton, { [scss.disabled]: disabled })}
          >
            <ArrowChevronDown />
          </button>
          <p className={scss.showMoreTitle}>{`${team.length} members`}</p>
        </div>
      ) : null}
    </div>
  );
};

export default observer(TeamMembersList);
