import { action, computed, makeObservable, observable } from "mobx";
import { ReactNode } from "react";

type Options = {
  overlayColor?: string;
  overlayClassName?: string;
  modalClassName?: string;
};

class ModalStore {
  _opened: boolean = false;
  _content: ReactNode = null;
  _options: Options = {};

  constructor() {
    makeObservable(this, {
      _opened: observable,
      openModal: action,
      closeModal: action,
      state: computed,
    });
  }

  openModal = (content: ReactNode, options: Options = {}) => {
    this._opened = true;
    this._content = content;
    this._options = { ...options };
  };

  closeModal = () => {
    this._opened = false;
    this._content = null;
  };

  get state() {
    return {
      opened: this._opened,
      content: this._content,
      ...this._options,
    };
  }
}

export default new ModalStore();
