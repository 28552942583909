import React, { useCallback, useState } from "react";
import resolutionStore from "../../../../../store/ResolutionStore";
import usePagination from "../../../../../hooks/usePagination";
import { CSSTransition } from "react-transition-group";
import CaseCardItem from "../CaseCardItem/CaseCardItem";
import Pagination from "../../../../Pagination/Pagination";
import { PageBlockCase } from "../../../../../api/contracts";
import { observer } from "mobx-react-lite";
import { useSection } from "../../../../../context/SectionContext";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  cases: PageBlockCase[];
};

const BlockCasesWithPaginationView = ({ cases }: Props) => {
  const [animation, setAnimation] = useState(false);
  const isMobileOrTablet = resolutionStore.isMobileOrTablet;
  const { horizontalViewType } = useSection();

  const contentPerPage = isMobileOrTablet ? cases.length : 1;

  const { setPage, totalPages, lastContentIndex, firstContentIndex, tempPage } =
    usePagination({
      contentPerPage,
      count: cases.length,
    });

  const setPageHandler = useCallback(
    (pageNumber: number) => () => {
      setAnimation(true);
      setTimeout(() => {
        requestAnimationFrame(() => {
          setPage(pageNumber);
          setAnimation(false);
        });
      }, 250);
    },
    [setPage]
  );

  return (
    <>
      <CSSTransition in={animation} timeout={500} classNames="fade-short">
        <div
          className={cn(
            scss.projectsBlockContentFlex,
            "hiddenScroll",
            scss[`view-${horizontalViewType}`]
          )}
        >
          <div className={scss.inner}>
            {cases
              .slice(
                isMobileOrTablet ? 0 : firstContentIndex,
                isMobileOrTablet ? cases.length : lastContentIndex
              )
              .map((item) => (
                <CaseCardItem caseCard={item} key={item.id} />
              ))}
          </div>
          {/*{isMobileOrTablet && (*/}
          {/*    <MobileLastItem*/}
          {/*        title="Want see more?"*/}
          {/*        description="We have created many more projects that we are proud of and can show you"*/}
          {/*        icon={<SettingsIcon />}*/}
          {/*        buttonText="more cases"*/}
          {/*        backgroundImage={endProjectBackground}*/}
          {/*    />*/}
          {/*)}*/}
        </div>
      </CSSTransition>
      {!isMobileOrTablet && (
        <div className={scss.projectsBlockActionsWrapper}>
          <Pagination
            changePageHandler={setPageHandler}
            currentPage={tempPage}
            pagesCount={totalPages}
            withPaddings={false}
            className={scss.projectsBlockPaginationButton}
            isPaginationButtons={true}
          />

          {/*<Button className={scss.projectsBlockShowMoreButton}>*/}
          {/*  Show more cases*/}
          {/*</Button>*/}
        </div>
      )}
    </>
  );
};

export default observer(BlockCasesWithPaginationView);
