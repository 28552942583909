import React, { ReactNode } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import scss from "./styles.module.scss";

type IProps = {
  className?: string;
  children: ReactNode;
  variant: "success" | "outline";
  size: "small" | "medium" | "large" | "XLarge";
  rounding?: "small" | "large";
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
  active?: boolean;
  loading?: boolean;
  uppercase?: boolean;
} & (
  | {
      type?: "button";
      actionType?: "submit" | "reset" | "button" | undefined;
    }
  | {
      type: "link";
      href: string;
    }
  | {
      type: "innerLink";
      to: string;
    }
);

const Button = (props: IProps) => {
  const classname = cn(
    scss.button,
    scss[props.variant],
    scss[props.size],
    scss[`rounding-${props.rounding}`],
    {
      [scss.uppercase]: props.uppercase,
      [scss.fullWidth]: props.fullWidth,
      [scss.disabled]: props.disabled,
      [scss.active]: props.active,
      [scss.loading]: props.loading,
    },
    props.className
  );

  const content = (
    <>
      {props.loading ? (
        <div className={cn(scss.iconWrapper, scss.iconBefore, scss.loader)}>
          <img src="/assets/loader.webp" alt="loader" />
        </div>
      ) : props.iconBefore ? (
        <div className={cn(scss.iconWrapper, scss.iconBefore)}>
          {props.iconBefore}
        </div>
      ) : null}
      {props.children}
      {props.iconAfter && (
        <div className={cn(scss.iconWrapper, scss.iconAfter)}>
          {props.iconAfter}
        </div>
      )}
    </>
  );
  switch (props.type) {
    case "innerLink":
      return (
        <Link to={props.to} className={classname}>
          {content}
        </Link>
      );
    case "link":
      return (
        <a href={props.href} className={classname}>
          {content}
        </a>
      );
    default:
      return (
        <button
          type={props.actionType}
          disabled={props.disabled}
          onClick={!props.disabled ? props.onClick : undefined}
          className={classname}
        >
          {content}
        </button>
      );
  }
};

Button.defaultProps = {
  size: "large",
  variant: "outline",
  type: "button",
  rounding: "large",
};
export default Button;
