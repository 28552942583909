import React, { RefObject } from "react";
import classNames from "classnames";
import { TeamMemberDto } from "../../../../../../api/contracts";
import resolutionStore from "../../../../../../store/ResolutionStore";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import scss from "./styles.module.scss";

interface IProps {
  card: TeamMemberDto;
  onClick: (i: number) => void;
  index: number;
  setAnimate: (status: boolean) => void;
  activeIndex: number;
  parentRef: RefObject<HTMLDivElement>;
}

const TeamMemberCard = ({
  card,
  onClick,
  index,
  activeIndex,
  setAnimate,
  parentRef,
}: IProps) => {
  const isActive = index === activeIndex;
  const { desktop, desktopLg } = resolutionStore.resolution;
  const isMobileOrTablet = resolutionStore.isMobileOrTablet;
  const isHorizontal = desktop || desktopLg || isMobileOrTablet;

  const clickHandler = () => {
    if (isActive) return;
    const activeItem = document.getElementById(index + card.id);
    if (activeItem) {
      if (isHorizontal) {
        const { left, right } = activeItem.getBoundingClientRect();
        if (left < 64 || right > window.innerWidth - 64) {
          activeItem.scrollIntoView({
            behavior: "smooth",
            inline: "center",
            block: "nearest",
          });
        }
      } else {
        const { top, bottom, height } = activeItem.getBoundingClientRect();
        const parentRect = parentRef.current?.getBoundingClientRect();
        if (parentRect) {
          parentRef.current?.scrollBy({
            behavior: "smooth",
            top:
              parentRect.bottom - 64 < bottom
                ? height * 2
                : parentRect.top + 64 > top
                ? -height * 2
                : 0,
          });
        }
      }
    }
    setAnimate(true);
    setTimeout(() => {
      runInAction(() => {
        onClick(index);
        setAnimate(false);
      });
    }, 300);
  };

  return (
    <div
      id={index + card.id}
      onClick={clickHandler}
      style={{ backgroundImage: "url(/assets/memberCardBG.webp)" }}
      className={classNames(scss.memberCardWrapper, {
        [scss.active]: isActive,
      })}
    >
      <img
        className={scss.memberCardImage}
        src={card.photo}
        alt={card.firstName}
      />
      <div className={scss.memberCardTextWrapper}>
        <p className={scss.memberCardName}>{card.firstName}</p>
        <p className={scss.memberCardRole}>{card.role}</p>
      </div>
    </div>
  );
};

export default observer(TeamMemberCard);
