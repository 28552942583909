import React, { useEffect, useState } from "react";
import { PageBlockStory, VideoType } from "../../../../../api/contracts";
import resolutionStore from "../../../../../store/ResolutionStore";
import { observer } from "mobx-react-lite";
import cn from "classnames";
import UmberVideoBlock from "../UmberVideoBlock/UmberVideoBlock";
import YouTubeVideoBlock from "../YouTubeVideoBlock/YouTubeVideoBlock";
import ArrowChevronLeft from "../../../../../icons/ArrowChevronLeft";
import ArrowChevronRight from "../../../../../icons/ArrowChevronRight";
import scss from "./styles.module.scss";

type Props = {
  isActive: boolean;
  story: PageBlockStory;
  nextPrevVideoHandler: (
    id: string,
    direction: "next" | "prev",
    pauseHandler?: () => void
  ) => () => void;
  setActiveItemId: () => void;
};

const VideoItem = ({
  setActiveItemId,
  isActive,
  story,
  nextPrevVideoHandler,
}: Props) => {
  const [active, setActive] = useState(isActive);
  const isMobile = resolutionStore.isMobile;

  useEffect(() => {
    if (!active && isActive) setActive(true);
  }, [isActive, active]);

  if (!active)
    return (
      <div
        className={cn(scss.coverWrapper, {
          [scss.active]: isActive,
        })}
        onClick={setActiveItemId}
      >
        <div className={scss.coverImageWrapper}>
          <img
            className={scss.coverImage}
            src={
              story.videoType === VideoType.UMBER_VIDEO
                ? story.coverImg
                : `https://i.ytimg.com/vi_webp/${story.videoId}/maxresdefault.webp`
            }
            alt={story.title}
          />
        </div>
        <div className={cn(scss.storyTitleWrapper, scss.shown)}>
          <div className={scss.playButton}>
            <span className={cn(scss.playIcon)} />
          </div>
          <p className={scss.storyTitle}>{story.title}</p>
        </div>
        <div
          className={scss.storyAuthor}
        >{`${story.author?.firstName} - ${story.author?.shortRole}`}</div>
      </div>
    );

  return (
    <div
      className={cn(scss.storiesModalItem, {
        [scss.active]: isActive,
      })}
      id={story.id}
    >
      {!isMobile && (
        <button
          className={scss.leftArrow}
          onClick={nextPrevVideoHandler(story.id, "prev")}
        >
          <ArrowChevronLeft />
        </button>
      )}
      {!isMobile && (
        <button
          className={scss.rightArrow}
          onClick={nextPrevVideoHandler(story.id, "next")}
        >
          <ArrowChevronRight />
        </button>
      )}
      {story.videoType === VideoType.YOUTUBE_VIDEO ? (
        <YouTubeVideoBlock
          story={story}
          nextPrevVideoHandler={nextPrevVideoHandler}
          isActive={isActive}
          setActiveItemId={setActiveItemId}
        />
      ) : (
        <UmberVideoBlock
          nextPrevVideoHandler={nextPrevVideoHandler}
          onClick={setActiveItemId}
          story={story}
          disableDefaultHover
          isActive={isActive}
          fixedSize
        />
      )}
    </div>
  );
};

export default observer(VideoItem);
