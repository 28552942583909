import React, { CSSProperties, useState } from "react";
import Section from "../../../../components/shared/Section/Section";
import { WorkflowStageDto } from "../../../../api/contracts";
import cn from "classnames";
import { CSSTransition } from "react-transition-group";
import HeaderNavLink from "../../../../components/Header/components/HeaderNavLink/HeaderNavLink";
import scss from "./styles.module.scss";

type Props = {
  workflowStages: WorkflowStageDto[];
};

const HomeWorkflowsSection = ({ workflowStages }: Props) => {
  const [tabActiveIndex, setTabActiveIndex] = useState(0);
  const [animate, setAnimate] = useState(false);

  const clickHandler = (index: number) => () => {
    if (tabActiveIndex !== index) {
      setAnimate(true);
      setTimeout(() => {
        requestAnimationFrame(() => {
          setTabActiveIndex(index);
          setAnimate(false);
        });
      }, 150);
    }
  };

  return (
    <Section
      title="to build a product, we go through the following stages"
      subtitle="how we build a product"
    >
      <div className={scss.stagesWrapper}>
        <div className={cn(scss.tabsWrapper, "hiddenScroll")}>
          {workflowStages.map((item, i) => {
            const variables = {
              "--stage-primary-color": item.accentColor,
            } as CSSProperties;
            return (
              <div
                style={variables}
                key={i}
                className={cn(scss.tabItem, {
                  [scss.active]: i === tabActiveIndex,
                })}
                onClick={clickHandler(i)}
              >
                {item.title}
              </div>
            );
          })}
        </div>
        <div className={scss.stepsWrapper}>
          <CSSTransition
            in={animate}
            timeout={300}
            classNames={{
              enter: scss.enter,
              enterActive: scss.enterActive,
              exit: scss.exit,
              exitActive: scss.exitActive,
            }}
          >
            <div>
              {workflowStages[tabActiveIndex].steps.map((step, i) => (
                <div className={scss.stepItemWrapper} key={i}>
                  <div
                    className={scss.stepDot}
                    style={{
                      color: workflowStages[tabActiveIndex].accentColor,
                    }}
                  />
                  <div className={scss.stepTitle}>{step}</div>
                </div>
              ))}
            </div>
          </CSSTransition>
        </div>
      </div>
      <HeaderNavLink
        type="link"
        to="/workflow"
        className={scss.navLink}
        contentColor="var(--green-2)"
        borderColor="var(--green-2)"
      >
        more about workflow
      </HeaderNavLink>
    </Section>
  );
};

export default HomeWorkflowsSection;
