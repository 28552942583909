import { useParams } from "react-router-dom";
import Case from "../Case/Case";
import NotFound from "../NotFound/NotFound";
import React from "react";
import Team from "../Team/Team";
import Workflow from "../Workflow/Workflow";
import { useFetchPage } from "../../api/query";
import Blog from "../Blog/Blog";
import { useGetQueryParam } from "../../hooks/useGetQueryParam";

const Page = () => {
  const { slug } = useParams();
  const { pathname, param } = useGetQueryParam(slug as string, "tag");

  const { data, status } = useFetchPage({
    slug: slug as string,
    isBlog: pathname.startsWith("/blog"),
    tag: param || undefined,
  });

  if (status === "loading") return null;

  if (!data) return <NotFound />;

  switch (data.type) {
    case "CASE":
      return <Case {...data} />;
    case "TEAM":
      return <Team {...data} />;
    case "WORKFLOW":
      return <Workflow {...data} />;
    case "BLOG":
      return <Blog {...data} key={slug} />;
    default:
      return <NotFound />;
  }
};

export default Page;
