import { memo, SVGProps } from "react";

const ClutchIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="85"
      height="25"
      viewBox="0 0 85 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.9719 0.36792H25.8592V24.3679H21.9719V0.36792Z"
        fill="white"
      />
      <path
        d="M39.3803 16.7623C39.3803 20.4806 36.3381 20.8186 35.324 20.8186C32.9578 20.8186 32.6198 18.6215 32.6198 17.4384V8.14258H28.7325V17.2693C28.7325 19.4665 29.4085 21.4947 30.7606 22.6778C31.9437 23.8609 33.4648 24.3679 35.324 24.3679C36.6761 24.3679 38.3663 24.0299 39.3803 23.0158V24.3679H43.2677V8.14258H39.3803V16.7623Z"
        fill="white"
      />
      <path
        d="M50.8732 2.22705H46.9859V8.14254H44.1127V11.8609H46.9859V24.3679H50.8732V11.8609H53.7465V8.14254H50.8732V2.22705Z"
        fill="white"
      />
      <path
        d="M65.9156 19.4664C65.0705 20.1425 63.8874 20.6495 62.7043 20.6495C60.0001 20.6495 58.1409 18.6213 58.1409 15.9171C58.1409 13.2129 60.0001 11.3537 62.7043 11.3537C63.8874 11.3537 65.0705 11.6918 65.9156 12.5368L66.4226 13.0439L69.1268 10.5087L68.4508 10.0016C66.9297 8.64951 64.9015 7.80444 62.7043 7.80444C57.9719 7.80444 54.4226 11.3537 54.4226 16.0861C54.4226 20.8185 57.9719 24.3678 62.7043 24.3678C64.9015 24.3678 66.9297 23.5228 68.4508 22.1706L69.1268 21.6636L66.4226 18.9594L65.9156 19.4664Z"
        fill="white"
      />
      <path
        d="M82.8169 9.49468C81.6338 8.31158 80.4507 7.80454 78.5915 7.80454C77.2394 7.80454 75.8873 8.14257 74.8732 9.15665V0.36792H70.9859V24.3679H74.8732V15.4102C74.8732 11.6919 77.4084 11.3538 78.4225 11.3538C80.7887 11.3538 80.6197 13.551 80.6197 14.7341V24.1989H84.507V14.9031C84.507 12.706 84 10.6778 82.8169 9.49468Z"
        fill="white"
      />
      <path
        d="M62.5352 18.7903C64.0287 18.7903 65.2394 17.5796 65.2394 16.0861C65.2394 14.5926 64.0287 13.3818 62.5352 13.3818C61.0417 13.3818 59.831 14.5926 59.831 16.0861C59.831 17.5796 61.0417 18.7903 62.5352 18.7903Z"
        fill="#EF4335"
      />
      <path
        d="M16.5634 18.4525C15.2113 19.8046 13.1831 20.6497 10.9859 20.6497C6.76056 20.6497 3.71831 17.2694 3.71831 12.706C3.71831 8.14267 6.76056 4.76239 11.1549 4.76239C13.1831 4.76239 15.2113 5.60746 16.7324 7.12858L17.2394 7.63563L19.7746 5.10041L19.2676 4.59337C17.0704 2.39619 14.1972 1.21309 11.1549 1.21309C4.73239 1.04408 0 6.1145 0 12.706C0 19.2976 4.73239 24.368 10.9859 24.368C14.0282 24.368 17.0704 23.1849 19.0986 20.9877L19.6056 20.4807L17.0704 17.9455L16.5634 18.4525Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(ClutchIcon);
