import React from "react";
import { PageBlockReview } from "../../../../../../../api/contracts";
import resolutionStore from "../../../../../../../store/ResolutionStore";
import { nameAbbreviation } from "../../../../../../../utils/nameUtils";
import CalendarWithDays from "../../../../../../../icons/CalendarWithDays";
import { getMonthDateRange } from "../../../../../../../utils/dateUtils";
import LocationIcon from "../../../../../../../icons/LocationIcon";
import QuoteOpenIcon from "../../../../../../../icons/QuoteOpenIcon";
import ClutchIcon from "../../../../../../../icons/ClutchIcon";
import StarRating from "../../../../../../shared/StarRating/StarRating";
import { observer } from "mobx-react-lite";
import scss from "./styles.module.scss";

type Props = {
  review: PageBlockReview;
};

const ModalReviewItem = ({ review }: Props) => {
  const { phone, phoneLg, tablet } = resolutionStore.resolution;
  const isMobileOrTablet = phoneLg || phone || tablet;
  const isMobile = phoneLg || phone;

  const reviewInfo = (
    <div className={scss.infoWrapper}>
      <div className={scss.authorInfo}>
        <div className={scss.authorName}>
          {nameAbbreviation(review.author.authorName, 16)}
        </div>
        <div
          className={scss.authorRole}
        >{`${review.author.authorRole}, ${review.author.companyName}`}</div>
      </div>
      <div className={scss.reviewInfo}>
        <div className={scss.cooperationDates}>
          <CalendarWithDays />
          {getMonthDateRange(review.dateRange)}
        </div>
        <div className={scss.companyLocation}>
          <LocationIcon />
          {review.author.companyLocation}
        </div>
      </div>
    </div>
  );

  return (
    <div className={scss.reviewContainer}>
      <div className={scss.headerSection}>
        {review.author.authorImg && (
          <div className={scss.authorAvatarWrapper}>
            <img
              className={scss.authorAvatar}
              src={review.author.authorImg}
              alt={review.author.authorName}
            />
          </div>
        )}
        {review.author.companyLogoImg && (
          <div className={scss.logoWrapper}>
            <img
              className={scss.logo}
              src={
                isMobileOrTablet
                  ? review.author.companySmallLogoImg
                  : review.author.companyLogoImg
              }
              alt={review.author.companyName}
            />
          </div>
        )}
        {!isMobile && reviewInfo}
      </div>
      <div className={scss.contentSection}>
        {isMobile && reviewInfo}
        <div className={scss.titleBlock}>
          <div className={scss.iconWrapper}>
            <QuoteOpenIcon />
          </div>
          <div className={scss.title}>{review.content.title}</div>
          {typeof review.clutchRating === "number" && (
            <div className={scss.ratingWrapper}>
              <div className={scss.clutchIconWrapper}>
                <ClutchIcon />
              </div>
              <StarRating rating={review.clutchRating} />
            </div>
          )}
        </div>
        <div className={scss.descriptionBlockBlock}>
          {review.content.description.map((text, i) => (
            <p key={i} className={scss.textItem}>
              {text}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(ModalReviewItem);
