import React from "react";
import HeaderNavLink from "../Header/components/HeaderNavLink/HeaderNavLink";
import sidebarStore from "../../store/SidebarStore";
import { observer } from "mobx-react-lite";
import scss from "./styles.module.scss";

const BurgerMenu = () => {
  const clickHandler = () => sidebarStore.closeMenu();

  return (
    <div className={scss.container}>
      <HeaderNavLink
        onClick={clickHandler}
        to="/"
        activeContentColor={"var(--green-2)"}
        gradientBackground
        position={"left-side-first"}
        className={scss.item}
        activeClassname={scss.itemActive}
      >
        home
      </HeaderNavLink>
      <HeaderNavLink
        onClick={clickHandler}
        to="/workflow"
        activeContentColor={"var(--green-2)"}
        gradientBackground
        position={"left-side-first"}
        className={scss.item}
        activeClassname={scss.itemActive}
      >
        workflow
      </HeaderNavLink>
      {/*<HeaderNavLink*/}
      {/*  onClick={clickHandler}*/}
      {/*  to="/blog"*/}
      {/*  activeContentColor={"var(--green-2)"}*/}
      {/*  gradientBackground*/}
      {/*  position={"left-side-first"}*/}
      {/*  classname={scss.item}*/}
      {/*  activeClassname={scss.itemActive}*/}
      {/*>*/}
      {/*  blog*/}
      {/*</HeaderNavLink>*/}
      <HeaderNavLink
        onClick={clickHandler}
        to="/team"
        activeContentColor={"var(--green-2)"}
        gradientBackground
        position={"left-side-first"}
        className={scss.item}
        activeClassname={scss.itemActive}
      >
        team
      </HeaderNavLink>
    </div>
  );
};

export default observer(BurgerMenu);
