import React, { ReactNode } from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

interface IProps {
  children: ReactNode;
  classname?: string;
  hasIsland?: boolean;
  islandBorderColor?: string;
}

const MainLayout = ({
  classname,
  children,
  hasIsland,
  islandBorderColor,
}: IProps) => {
  return (
    <div className={cn(scss.container)}>
      <div className={cn(scss.subContainer, classname)}>
        {hasIsland && (
          <div className={scss.island} style={{ color: islandBorderColor }} />
        )}
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
