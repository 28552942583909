import useMenuOutsideClick from "./hooks/useOutsideClick";
import { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import usePopupPosition from "../../Popup/hooks/usePopupPosition";
import { PopupPlacement } from "../../Popup/utils/popupPosition";
import scss from "./styles.module.scss";

interface IProps {
  open: boolean;
  target: HTMLElement;
  onClose: () => void;
  children: ReactNode;
  containerId?: string;
  canCloseMenuByScroll?: boolean;
  placement?: PopupPlacement;
  menuClassname?: string;
  showMenuClassname?: string;
}

const MenuSelect = ({
  open,
  onClose,
  target,
  children,
  containerId,
  canCloseMenuByScroll,
  placement = "bottom-start",
  menuClassname,
  showMenuClassname,
}: IProps) => {
  const _root = useRef<HTMLDivElement>(document.createElement("div"));
  const _menu = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    _root.current = document.createElement("div");
    _menu.current = document.createElement("div");
    _menu.current.classList.add(scss.menu);
    if (menuClassname) _menu.current.classList.add(menuClassname);
    const container = document.getElementById(containerId as string);
    if (container) container.appendChild(_root.current);
    else document.body.appendChild(_root.current);
  }, [containerId, menuClassname]);

  useMenuOutsideClick(_menu, open, target, onClose, canCloseMenuByScroll);
  usePopupPosition({
    root: _root,
    popup: _menu,
    target,
    open,
    placement,
    indentX: 0,
    indentY: 0,
    showClassName: showMenuClassname || scss.menuShow,
  });
  return open ? createPortal(children, _menu.current) : null;
};

MenuSelect.defaultProps = {
  containerId: "__popup",
};

export default MenuSelect;
