import React from "react";
import { PageBlockEmbed } from "../../../api/contracts";
import scss from "./styles.module.scss";

type Props = {
  block: PageBlockEmbed;
};
const BlockEmbed = (props: Props) => {
  return (
    <div
      className={scss.block}
      dangerouslySetInnerHTML={{
        __html: props.block.html,
      }}
    />
  );
};

export default BlockEmbed;
