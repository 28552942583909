import React, { MouseEvent, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { PageBlockStory, VideoType } from "../../../../../api/contracts";
import { runInAction } from "mobx";
import SoundOnIcon from "../../../../../icons/SoundOnIcon";
import SoundOffIcon from "../../../../../icons/SoundOffIcon";
import scss from "./styles.module.scss";
import { useFetchStoryById } from "../../../../../api/query";
import PreloaderIcon from "../../../../../icons/PreloaderIcon";

interface IProps {
  story: PageBlockStory;
  onClick?: () => void;
  className?: string;
  disableDefaultHover?: boolean;
  isActive?: boolean;
  fixedSize?: boolean;
  nextPrevVideoHandler: (
    id: string,
    direction: "next" | "prev",
    pauseHandler?: () => void
  ) => () => void;
}

const UmberVideoBlock = ({
  story,
  onClick,
  className,
  disableDefaultHover,
  isActive,
  fixedSize,
  nextPrevVideoHandler,
}: IProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [duration, setDuration] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [videoSrc, setVideoSrc] = useState("");

  const { data, status } = useFetchStoryById(story.id);

  useEffect(() => {
    if (status === "success") setVideoSrc(data.videoSrc);
  }, [data, status]);

  const handlePlayPauseClick = () => {
    if (isActive) {
      if (isPlaying) {
        videoRef.current?.pause();
      } else {
        videoRef.current?.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const el = videoRef.current;
    if (el) {
      const handleTimeUpdate = () => {
        if (el.currentTime && el.duration) {
          const percent = (el.currentTime / el.duration) * 100;
          setProgress(percent);
        }
      };
      el.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        el.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, []);

  const muteHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (videoRef.current) videoRef.current.muted = !videoRef.current.muted;
  };

  useEffect(() => {
    if (isActive && duration) {
      runInAction(() => {
        videoRef.current?.play();
        setIsPlaying(true);
      });
    } else if (!isActive && duration) {
      runInAction(() => {
        videoRef.current?.pause();
        setIsPlaying(false);
      });
    }
  }, [duration, isActive]);

  const convertDuration = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const durationSetter = () => {
    if (videoRef.current?.duration) {
      setDuration(convertDuration(Math.round(videoRef.current.duration)));
    }
  };

  return (
    <div
      onClick={isActive ? handlePlayPauseClick : onClick}
      className={cn(
        scss.storyWrapper,
        {
          [scss.withoutDefaultHover]: disableDefaultHover,
          [scss.isPaused]: isActive && !isPlaying,
          [scss.fixedSize]: fixedSize,
        },
        className
      )}
    >
      {status === "loading" && story.videoType === VideoType.UMBER_VIDEO && (
        <div
          className={scss.preloaderWrapper}
          style={{ backgroundImage: `url(${story.coverImg})` }}
        >
          <PreloaderIcon className={scss.preloader} />
        </div>
      )}
      <div className={scss.playBigButton}>
        <span className={cn(scss.playBigIcon)} />
      </div>
      <video
        onEnded={nextPrevVideoHandler(story.id, "next")}
        onLoadedData={durationSetter}
        ref={videoRef}
        className={scss.storyVideo}
        src={videoSrc}
        controls={false}
      />
      <div className={cn(scss.storyTitleWrapper, { [scss.shown]: !isPlaying })}>
        <div className={scss.playButton}>
          <span className={cn(scss.playIcon)} />
        </div>
        <p className={scss.storyTitle}>{story.title}</p>
      </div>
      <div className={cn(scss.progressBar, { [scss.shown]: isPlaying })}>
        <div
          className={scss.progressBarLine}
          style={{ width: `${progress}%` }}
        />
      </div>
      <div
        className={cn(scss.storyAuthor, { [scss.shown]: !isPlaying })}
      >{`${story.author.firstName} - ${story.author.shortRole}`}</div>
      <div className={cn(scss.storyDuration, { [scss.shown]: !isPlaying })}>
        {duration}
      </div>
      <button
        onClick={muteHandler}
        className={cn(scss.soundIconWrapper, { [scss.shown]: isPlaying })}
      >
        <SoundOffIcon
          className={cn(scss.soundIcon, {
            [scss.shownIcon]: videoRef.current?.muted,
          })}
        />
        <SoundOnIcon
          className={cn(scss.soundIcon, {
            [scss.shownIcon]: !videoRef.current?.muted,
          })}
        />
      </button>
    </div>
  );
};

export default UmberVideoBlock;
