import { /*ProjectCardDto, StoryDto,*/ TeamMemberDto } from "../contracts";

// const projectCardImageLarge = require("./images/projectCardImageLarge.webp");
// const projectCardImageSmall = require("./images/projectCardImageSmall.webp");
const storyVideo1 = require("./video/HowToAddCustomSVGIcons.mp4");
const storyVideo2 = require("./video/HomeScreen.mp4");
const storyVideo3 = require("./video/DynamicTypingForCustomSVGIcons.mp4");
const storyVideo4 = require("./video/SharedElement.mp4");

export const team: TeamMemberDto[] = [
  {
    id: "acf35qwe34",
    firstName: "Valentin",
    lastName: "Anishchenko",
    role: "CEO",
    fullRole: "chief executive officer",
    skills: [
      "Product Strategy",
      "Product Design",
      "Design Thinking",
      "Product Management",
      "Agile Development",
      "Communication",
      "Leadership",
      "Data analysis",
      "Customer Focus",
      "Technical Skills",
      "Adaptability",
      "Marketing",
      "Branding",
      "Prototyping",
      "User testing",
      "Risk Management",
    ],
    description: [
      {
        title: "Working with clients",
        text: "Our expertise should be your superpower. That's why building an effective collaboration process is my top priority.",
      },
      {
        title: "Product management",
        text: "Starting with your idea or current product, we build the best way to build the product, taking into account your business goals and customers.",
      },
    ],
    photo: "/assets/photo_valentin.webp",
    backgroundImage: "/assets/background_valentin.webp",
  },
  {
    id: "acf35qwe35",
    firstName: "Artem",
    lastName: "Zdravchev",
    role: "CTO",
    fullRole: "chief technology officer",
    skills: [
      "Technical leadership",
      "Frontend development",
      "Agile Development",
      "Project Management",
      "Software Architecture",
      "DevOps",
      "API Development",
      "Security & Data Privacy",
      "Performance Optimization",
      "Quality Control",
      "Technical Documentation",
      "Resource Allocation",
      "Innovation and Problem-Solving",
    ],
    description: [
      {
        title: "Build the architecture",
        text: "We take into account all the features of your business and long-term goals to build the best architecture.",
      },
      {
        title: "Tech leading",
        text: "Our CTO's approval is like a gold star of excellence - hard to earn, but worth it. So every technical solution we deliver is a shining example of our team's expertise.",
      },
    ],
    photo: "/assets/photo_artem.webp",
    backgroundImage: "/assets/background_artem.webp",
  },
  {
    id: "acf35qwe36",
    firstName: "Andrew",
    lastName: "Pylypenko",
    role: "Front-End",
    fullRole: "Middle Front-End Developer",
    skills: [
      "HTML 5",
      "CSS(SCSS)",
      "ReactJS",
      "React Native",
      "TypeScript",
      "Git",
      "MobX",
      "Redux",
      "NextJS",
      "NodeJS",
      "WebSocket",
      "Performance Optimization",
      "Debugging",
      "Mobile Development",
      "Problem-Solving",
      "Pixel Perfect",
    ],
    description: [
      {
        title: "Front-End Talent",
        text: "Pixel Perfect is the standard for our front-end development. You will receive the product as you saw it on the design.",
      },
      {
        title: "Quality Assurance",
        text: "Bugs, glitches, delays, typos are our main enemies here. We test in detail that your software works the way it should.",
      },
    ],
    photo: "/assets/photo_andrew.webp",
    backgroundImage: "/assets/background_andrew.webp",
  },
  {
    id: "acf35qwe37",
    firstName: "Victor",
    lastName: "Kaminskiy",
    role: "Design",
    fullRole: "Designer",
    skills: [
      "Figma",
      "Photoshop",
      "Blender",
      "Illustrator",
      "User Research & Analysis",
      "Prototyping",
      "UI design principles",
      "User-Centered Design",
      "Information Architecture",
      "Usability Testing",
      "Design Thinking",
      "Mobile Design",
      "Responsive Design",
      "Iconography & Typography",
      "Motion Design",
      "Design Systems",
    ],
    description: [
      {
        title: "User Interface",
        text: "We focus on creating good impression design for your target audience and improve engagement and conversion.",
      },
      {
        title: "Design System",
        text: "Design and development are one and the same, so a design system is created for each project, making development processes easier and faster.",
      },
    ],
    photo: "/assets/photo_victor.webp",
    backgroundImage: "/assets/background_victor.webp",
  },
  {
    id: "acf35qwe38",
    firstName: "Anastasia",
    lastName: "Luchnikova",
    role: "BA",
    fullRole: "Business Analyst",
    skills: [
      "Business Process Modeling",
      "Data Modeling",
      "Systems Analysis",
      "Use Case Development",
      "Stakeholder Management",
      "Cost-Benefit Analysis",
      "Process Improvement",
      "Financial Analysis",
      "Marketing Research",
    ],
    description: [
      {
        title: "Business Analytics",
        text: "We build projects based on data because it shows us the right direction for product development.",
      },
      {
        title: "Launch",
        text: "This is a key moment in the business building phase, for which our team prepares especially and controls all the nuances during the launch process.",
      },
    ],
    photo: "/assets/photo_anastasia.webp",
    backgroundImage: "/assets/background_anastasia.webp",
  },
  {
    id: "acf35qwe39",
    firstName: "Kateryna",
    lastName: "Zdravcheva",
    role: "UX Expert",
    fullRole: "User Experience Expert",
    skills: [
      "Product Strategy",
      "Market Analysis",
      "Design Sprints",
      "Lean UX",
      "Product Management",
      "User Research & Analysis",
      "Usability Testing",
      "Prototyping",
      "Interaction Design",
      "User-Centered Design",
      "Design Thinking",
      "Mobile Design",
      "Data Visualization",
      "Accessibility Standards",
    ],
    description: [
      {
        title: "User Experience",
        text: "Projects should solve user problems and achieve business goals, and achieving these two goals is our mission on this stage.",
      },
      {
        title: "Research",
        text: "We strive to understand the product and competitors on a level with our stakeholders and even better, in order to formulate the best proposals on our part.",
      },
    ],
    photo: "/assets/photo_kate.webp",
    backgroundImage: "/assets/background_kate.webp",
  },
];

export const stories: {
  id: string;
  title: string;
  videoSrc: string;
  author: {
    firstName: string;
    role: string;
  };
}[] = [
  {
    id: "gbd76hjg89",
    title: "Best practices for remote team management",
    videoSrc: storyVideo3,
    author: {
      firstName: "Valentin",
      role: "CEO",
    },
  },
  {
    id: "jkl45ghf76",
    title: "The importance of code reviews in software development",
    videoSrc: storyVideo4,
    author: {
      firstName: "Artem",
      role: "CTO",
    },
  },
  {
    id: "poi98hgf43",
    title: "Improving website performance with front-end optimization",
    videoSrc: storyVideo1,
    author: {
      firstName: "Andrew",
      role: "Front-End",
    },
  },
  {
    id: "jhy76tfr43",
    title: "Design principles for creating effective user interfaces",
    videoSrc: storyVideo2,
    author: {
      firstName: "Victor",
      role: "Designer",
    },
  },
  {
    id: "fgh32swd98",
    title: "How to design a successful branding strategy",
    videoSrc: storyVideo3,
    author: {
      firstName: "Anastacia",
      role: "Designer",
    },
  },
  {
    id: "qwe54kjh32",
    title: "The benefits of using design systems in UI/UX design",
    videoSrc: storyVideo4,
    author: {
      firstName: "Kateryna",
      role: "Designer",
    },
  },
  {
    id: "vbn87hgf65",
    title: "Effective project management tips for remote teams",
    videoSrc: storyVideo1,
    author: {
      firstName: "Valentin",
      role: "CEO",
    },
  },
  {
    id: "mnb43jhg87",
    title: "Optimizing database performance in web applications",
    videoSrc: storyVideo2,
    author: {
      firstName: "Artem",
      role: "CTO",
    },
  },
  {
    id: "bvc98hgf32",
    title: "Creating responsive designs with CSS grid",
    videoSrc: storyVideo3,
    author: {
      firstName: "Andrew",
      role: "Front-End",
    },
  },
  {
    id: "tre67hgf12",
    title: "The role of typography in graphic design",
    videoSrc: storyVideo4,
    author: {
      firstName: "Victor",
      role: "Designer",
    },
  },
  {
    id: "bnm21jkl87",
    title: "Building scalable applications with microservices architecture",
    videoSrc: storyVideo1,
    author: {
      firstName: "Artem",
      role: "CTO",
    },
  },
  {
    id: "poi09mnb76",
    title: "Creating engaging animations with CSS and JavaScript",
    videoSrc: storyVideo2,
    author: {
      firstName: "Andrew",
      role: "Front-End",
    },
  },
  {
    id: "vcx34ghj76",
    title: "The role of color theory in visual design",
    videoSrc: storyVideo3,
    author: {
      firstName: "Victor",
      role: "Designer",
    },
  },
  {
    id: "hju87tre21",
    title: "Using wireframes and prototypes in the design process",
    videoSrc: storyVideo4,
    author: {
      firstName: "Anastacia",
      role: "Designer",
    },
  },
  {
    id: "qwe98asd76",
    title: "The importance of testing and debugging in software development",
    videoSrc: storyVideo1,
    author: {
      firstName: "Kateryna",
      role: "Designer",
    },
  },
  {
    id: "rty12fgh76",
    title: "Agile project management methodologies for software development",
    videoSrc: storyVideo2,
    author: {
      firstName: "Valentin",
      role: "CEO",
    },
  },
  {
    id: "vbn65fgh98",
    title: "Optimizing website accessibility for all users",
    videoSrc: storyVideo3,
    author: {
      firstName: "Andrew",
      role: "Front-End",
    },
  },
  {
    id: "mnb98hju76",
    title: "The role of storytelling in UX design",
    videoSrc: storyVideo4,
    author: {
      firstName: "Victor",
      role: "Designer",
    },
  },
  {
    id: "hjg65trewq",
    title: "Effective communication strategies for remote teams",
    videoSrc: storyVideo1,
    author: {
      firstName: "Valentin",
      role: "CEO",
    },
  },
  {
    id: "poi98nbv43",
    title: "Creating scalable and maintainable CSS with BEM methodology",
    videoSrc: storyVideo2,
    author: {
      firstName: "Andrew",
      role: "Front-End",
    },
  },
  {
    id: "bvc76tyu21",
    title: "Designing for mobile-first user experiences",
    videoSrc: storyVideo3,
    author: {
      firstName: "Anastacia",
      role: "Designer",
    },
  },
  {
    id: "rew12jkl43",
    title:
      "Using Git for version control in collaborative software development",
    videoSrc: storyVideo4,
    author: {
      firstName: "Kateryna",
      role: "Designer",
    },
  },
];

// export const projectCards: ProjectCardDto[] = [
//   {
//     id: "rew12jkl44",
//     title:
//       "Creation of website design for a company that deals with betting on eSports events.",
//     description:
//       "We created the structure and visual style, focusing on the ease of use of the site for users.",
//     tags: ["Research", "Web-site", "Mobile APP"],
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//   },
//   {
//     id: "htr58lmn02",
//     title: "Designing a responsive website for a fitness center",
//     description:
//       "We focused on creating a modern and sleek design, with an emphasis on showcasing the gym's facilities and services.",
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//     tags: ["Web-design", "Fitness", "Responsive"],
//   },
//   {
//     id: "abc123xyz456",
//     title: "Developing a mobile app for a grocery delivery service",
//     description:
//       "We designed a user-friendly interface with a streamlined checkout process to enhance the customer experience.",
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//     tags: ["Mobile-app", "Grocery", "Delivery"],
//   },
//   {
//     id: "qwe456rty789",
//     title: "Designing an e-commerce website for a fashion brand",
//     description:
//       "We aimed to create a visually stunning online store with a focus on simplicity and ease of navigation.",
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//     tags: ["Web-design", "E-commerce", "Fashion"],
//   },
//   {
//     id: "asd123zxc456",
//     title: "Creating a landing page for a new software product",
//     description:
//       "We designed a clean and minimalist landing page to effectively communicate the benefits of the product to potential customers.",
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//     tags: ["Landing-page", "Software", "Product"],
//   },
//   {
//     id: "poi987mnb654",
//     title: "Developing a mobile app for a taxi-hailing service",
//     description:
//       "We created a seamless user experience with features like real-time tracking and multiple payment options.",
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//     tags: ["Mobile-app", "Taxi", "Hailing"],
//   },
//   {
//     id: "lki234ytr098",
//     title: "Designing a website for a non-profit organization",
//     description:
//       "We focused on creating a website that would effectively communicate the organization's mission and goals to donors and volunteers.",
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//     tags: ["Web-design", "Non-profit", "Charity"],
//   },
//   {
//     id: "rty432qwe321",
//     title: "Creating a blog for a food and recipe website",
//     description:
//       "We designed a visually appealing and user-friendly blog to showcase the website's recipes and food-related content.",
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//     tags: ["Blog", "Food", "Recipes"],
//   },
//   {
//     id: "zxc654poi987",
//     title: "Developing a mobile app for a language learning platform",
//     description:
//       "We designed a gamified user experience with features like interactive lessons and progress tracking.",
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//     tags: ["Mobile-app", "Language-learning", "Gamification"],
//   },
//   {
//     id: "mnb098lki234",
//     title: "Designing a website for a real estate agency",
//     description:
//       "We created a clean and professional website that would effectively showcase the agency's properties and services.",
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//     tags: ["Web-design", "Real estate", "Property"],
//   },
//   {
//     id: "vbn098lkj765",
//     title: "Creating a landing page for a new book release",
//     description:
//       "We designed a visually appealing landing page to effectively promote the book and encourage pre-orders.",
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//     tags: ["Landing-page", "Book", "Promotion"],
//   },
//   {
//     id: "uyt567asd890",
//     title: "Developing a mobile app for a music streaming service",
//     description:
//       "We designed a personalized user experience with features like curated playlists and song recommendations.",
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//     tags: ["Mobile-app", "Music", "Streaming"],
//   },
//   {
//     id: "jkl123asd456",
//     title: "Designing a website for a travel agency",
//     description:
//       "We aimed to create a visually stunning website that would effectively showcase the agency's destinations and services.",
//     image: {
//       large: projectCardImageLarge,
//       small: projectCardImageSmall,
//     },
//     tags: ["Web-design", "Travel", "Tourism"],
//   },
// ];
