import React from "react";
import cn from "classnames";
import WhatsAppIcon from "../../../icons/WhatsAppIcon";
import scss from "./styles.module.scss";

type Props = {
  size?: number;
};

const WhatsApp = ({ size }: Props) => {
  return (
    <div
      className={cn(scss.icon, scss.whatsApp)}
      style={{ width: size, height: size }}
    >
      <WhatsAppIcon />
    </div>
  );
};

export default WhatsApp;
