import { createContext, ReactNode, useContext } from "react";
import { Page } from "../api/contracts";

const PageContext = createContext<Page | undefined>(undefined);

type Props = {
  page: Page;
  children: ReactNode;
};
export const PageContextProvider = (props: Props) => {
  return (
    <PageContext.Provider value={props.page}>
      {props.children}
    </PageContext.Provider>
  );
};

export const usePage = () => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error(
      `You need to wrap the Component into a PageContextProvider to provide the functionality of PageContext.`
    );
  }
  return context;
};
