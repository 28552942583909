import { FormEvent, useState } from "react";
import isEmail from "validator/lib/isEmail";
import Field from "./Field";
import modalStore from "../../../../store/ModalStore";
import Button from "../../../../components/shared/Button/Button";
import { useSendContactMessage } from "../../../../api/query";
import scss from "./styles.module.scss";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const sendMessage = useSendContactMessage();

  const [isValid, setValid] = useState<{
    name: boolean | null;
    email: boolean | null;
    message: boolean | null;
  }>({
    name: null,
    email: null,
    message: null,
  });

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (submitting) return;
    e.preventDefault();
    const _name = !!name.trim();
    const _email = isEmail(email);
    const _message = !!message.trim();
    setValid({
      name: _name,
      email: _email,
      message: _message,
    });
    if (!_name || !_email || !_message) return;
    setSubmitting(true);
    sendMessage
      .mutateAsync({
        name,
        email,
        message,
      })
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
      });
  };

  const onChangeName = (value: string) => {
    setName(value);
    setValid((p) => ({ ...p, name: null }));
  };

  const onChangeEmail = (value: string) => {
    setEmail(value);
    setValid((p) => ({ ...p, email: null }));
  };

  const onChangeMessage = (value: string) => {
    setMessage(value);
    setValid((p) => ({ ...p, message: null }));
  };

  return (
    <div className={scss.container}>
      {success ? (
        <div
          style={{ backgroundImage: "url(/assets/contacts-cover.webp)" }}
          className={scss.successContainer}
        >
          <div className={scss.successInner}>
            <div className={scss.successTitle}>
              The first step has been taken, <span>congratulations!</span>
            </div>
            <div className={scss.successDescription}>
              We have received your letter and are already arguing over who will
              reply to you first.
            </div>
          </div>
          <Button
            size="XLarge"
            rounding="small"
            onClick={modalStore.closeModal}
          >
            back to home
          </Button>
        </div>
      ) : (
        <form onSubmit={onSubmit} className={scss.form}>
          <Field
            name="name"
            type="input"
            label="Name"
            value={name}
            errorMessage="This field is required"
            isValid={isValid.name}
            onChange={onChangeName}
            disabled={sendMessage.isLoading}
          />
          <Field
            name="email"
            type="input"
            label="Email"
            value={email}
            errorMessage="Please enter the correct email address"
            isValid={isValid.email}
            onChange={onChangeEmail}
            disabled={sendMessage.isLoading}
          />

          <Field
            name="message"
            type="textarea"
            label="Tell us about your project"
            value={message}
            errorMessage="This field is required"
            isValid={isValid.message}
            onChange={onChangeMessage}
            disabled={sendMessage.isLoading}
          />

          <Button
            size="XLarge"
            variant="success"
            rounding="small"
            actionType="submit"
            loading={sendMessage.isLoading}
            className={scss.submit}
            disabled={submitting}
          >
            send application
          </Button>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
