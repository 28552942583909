import {memo, SVGProps} from 'react';

const ArrowChevronDown = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M6 7.5L12 1.83401L10.5894 0.5L6 4.8367L1.41165 0.5L0 1.83306L6 7.5Z" fill="currentColor"/>
        </svg>

    );
};

export default memo(ArrowChevronDown);