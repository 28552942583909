import scss from "./styles.module.scss";

type Props = {
  title: string;
  description: string;
  image: string | undefined;
};

const WorkflowCard = (props: Props) => {
  return (
    <div className={scss.card}>
      <div className={scss.cardTitle}>{props.title}</div>
      <div className={scss.cardDescription}>{props.description}</div>
      <img className={scss.cardImg} src={props.image} alt={props.title} />
      <div className={scss.island} />
    </div>
  );
};

export default WorkflowCard;
