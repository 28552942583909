import React, { CSSProperties, ReactNode, useMemo } from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
  hasIsland?: boolean;
  borderColor?: string;
  islandBorderColor?: string;
  islandClassName?: string;
  style?: CSSProperties
};
const HomeSection = (props: Props) => {
  const style = useMemo(
    () =>
      ({
        "--main-border-color": props.borderColor,
        "--island-border-color": props.islandBorderColor || props.borderColor,
          ...props.style
      } as CSSProperties),
    [props.borderColor, props.islandBorderColor, props.style]
  );
  return (
    <div className={cn(scss.section, props.className)} style={style}>
      {props.hasIsland && (
        <div className={cn(scss.island, props.islandClassName)} />
      )}
      {props.children}
    </div>
  );
};

export default HomeSection;
