import React, { useCallback, useState } from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import { CSSTransition } from "react-transition-group";
import { PageBlockReviews } from "../../../api/contracts";
import resolutionStore from "../../../store/ResolutionStore";
import usePagination from "../../../hooks/usePagination";
import Pagination from "../../Pagination/Pagination";
import BlockReviewCard from "./components/BlockReviewCard/BlockReviewCard";
import { useSection } from "../../../context/SectionContext";
import scss from "./styles.module.scss";

type Props = {
  block: PageBlockReviews;
};

const BlockReviews = ({ block }: Props) => {
  const [animation, setAnimation] = useState(false);
  const { phone, phoneLg, tablet } = resolutionStore.resolution;
  const isMobileOrTablet = phone || phoneLg || tablet;

  const { horizontalViewType } = useSection();

  const reviewsArr = Object.values(block.reviews || {});

  const contentPerPage = 1;

  const { setPage, totalPages, lastContentIndex, firstContentIndex, tempPage } =
    usePagination({
      contentPerPage,
      count: reviewsArr.length,
    });

  const setPageHandler = useCallback(
    (pageNumber: number) => () => {
      setAnimation(true);
      setTimeout(() => {
        requestAnimationFrame(() => {
          setPage(pageNumber);
          setAnimation(false);
        });
      }, 250);
    },
    [setPage]
  );
  return (
    <>
      <CSSTransition in={animation} timeout={500} classNames="fade-short">
        <div
          className={cn(
            scss.container,
            "hiddenScroll",
            scss[`view-${horizontalViewType}`]
          )}
        >
          <div className={scss.inner}>
            {reviewsArr
              .slice(
                isMobileOrTablet ? 0 : firstContentIndex,
                isMobileOrTablet ? reviewsArr.length : lastContentIndex
              )
              .map((item, i) => (
                <BlockReviewCard
                  review={item}
                  key={i}
                  animation={animation}
                  reviews={reviewsArr}
                />
              ))}
          </div>
        </div>
      </CSSTransition>
      {!isMobileOrTablet && (
        <div className={scss.blockActionsWrapper}>
          <Pagination
            changePageHandler={setPageHandler}
            currentPage={tempPage}
            pagesCount={totalPages}
            withPaddings={false}
            className={scss.blockPaginationButton}
            nextPrevButtons
            isPaginationButtons={true}
          />
        </div>
      )}
    </>
  );
};

export default observer(BlockReviews);
