import { memo, SVGProps } from "react";

const BehanceLogoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4117_21426)">
        <path
          d="M8.24196 10.8079C8.24196 9.5759 7.65597 8.66524 6.4453 8.32391C7.32863 7.90324 7.7893 7.26591 7.7893 6.27458C7.7893 4.31993 6.32797 3.84326 4.64198 3.84326H0L0 13.6572H4.77198C6.5613 13.6572 8.24196 12.8019 8.24196 10.8079ZM2.16399 5.51859H4.19465C4.97531 5.51859 5.67797 5.73725 5.67797 6.63992C5.67797 7.47324 5.13131 7.80858 4.35798 7.80858H2.16399V5.51859ZM2.16332 11.9906L2.16332 9.2879H4.52198C5.47464 9.2879 6.07731 9.6839 6.07731 10.6892C6.07731 11.6806 5.35798 11.9906 4.47731 11.9906H2.16332Z"
          fill="#5780FF"
        />
        <path
          d="M12.5308 13.843C14.2335 13.843 15.3361 13.0784 15.8668 11.453H14.1388C13.9528 12.0597 13.1861 12.3804 12.5915 12.3804C11.4441 12.3804 10.8415 11.7104 10.8415 10.5717H15.9834C16.1461 8.29307 14.8768 6.35107 12.5301 6.35107C10.3575 6.35107 8.88281 7.97907 8.88281 10.1117C8.88281 12.325 10.2801 13.843 12.5308 13.843ZM12.4695 7.82573C13.4528 7.82573 13.9475 8.40173 14.0301 9.34373H10.8441C10.9088 8.40973 11.5308 7.82573 12.4695 7.82573Z"
          fill="#5780FF"
        />
        <path
          d="M10.4448 4.3584H14.4368V5.32506H10.4448V4.3584Z"
          fill="#5780FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_4117_21426">
          <rect
            width="15.9999"
            height="15.9999"
            fill="white"
            transform="translate(0 0.843262)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(BehanceLogoIcon);
