import React from "react";
import { PageBlockPosts } from "../../../../../api/contracts";
import scss from "./styles.module.scss";
import PostCard from "../PostCard/PostCard";
import HeaderNavLink from "../../../../Header/components/HeaderNavLink/HeaderNavLink";

type Props = {
  block: PageBlockPosts;
};

const PostsWithFilters = ({ block }: Props) => {
  return (
    <div className={scss.container}>
      <div className={scss.titleWrapper}>
        <h2 className={scss.tagName}>{block.currentTag}</h2>
        <HeaderNavLink
          className={scss.showMoreButton}
          type={"link"}
          to={`/blog?tag=${block.currentTag}`}
          paddings="large"
        >
          Show More
        </HeaderNavLink>
      </div>
      <div className={scss.contentWrapper}>
        {block.posts.map((item) => (
          <PostCard postInfo={item} key={item.slug} type="small" theme="dark" />
        ))}
      </div>
    </div>
  );
};

export default PostsWithFilters;
