import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from "react";

const useQueryParams = () => {
  const { pathname, search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  return { pathname, query };
};

export const useGetQueryParam = (slug: string, paramName: string) => {
  const navigate = useNavigate();
  const { pathname, query } = useQueryParams();
  const param = query.get(paramName);
  const paramRef = useRef(param);
  const prevSlug = useRef(slug);

  if (slug !== prevSlug.current) {
    paramRef.current = param;
    prevSlug.current = slug;
  }

  useEffect(() => {
    if (param) {
      navigate(window.location.pathname, { replace: true });
    }
  }, [param]);

  return { pathname, param: paramRef.current };
};
