import { memo, SVGProps } from "react";

const InfoFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1C7.3345 1 4.93964 2.15875 3.29168 4C1.86656 5.59227 1 7.69494 1 10C1 12.3051 1.86656 14.4077 3.29168 16C4.93964 17.8412 7.3345 19 10 19C12.4153 19 14.6084 18.0485 16.225 16.5C17.9351 14.8618 19 12.5552 19 10C19 7.58467 18.0485 5.39156 16.5 3.77503C14.8618 2.06486 12.5552 1 10 1ZM10 5C10.4142 5 10.75 5.33579 10.75 5.75V7.25C10.75 7.66421 10.4142 8 10 8C9.58579 8 9.25 7.66421 9.25 7.25V5.75C9.25 5.33579 9.58579 5 10 5ZM10 10C10.4142 10 10.75 10.3358 10.75 10.75L10.75 14.25C10.75 14.6642 10.4142 15 10 15C9.58579 15 9.25 14.6642 9.25 14.25L9.25 10.75C9.25 10.3358 9.58579 10 10 10Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(InfoFill);
