import { observer } from "mobx-react-lite";
import { CSSProperties, MouseEvent, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import ModalStore from "../../store/ModalStore";
import scss from "./styles.module.scss";

const Modal = observer(() => {
  const state = ModalStore.state;
  const root = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    root.current.id = "modal";
    document.body.append(root.current);
  }, []);

  useEffect(() => {
    if (state.opened) {
      document.body.classList.add("modalOpen");
    } else {
      document.body.classList.remove("modalOpen");
    }
  }, [state.opened]);

  const onOutsideClick = (e: MouseEvent) => {
    if (e.currentTarget === e.target) ModalStore.closeModal();
  };

  const style = {
    "--modal-overlay-bg": state.overlayColor,
  } as CSSProperties;

  return createPortal(
    <div
      style={style}
      className={classNames(scss.overlay, state.overlayClassName, {
        [scss.active]: state.opened,
      })}
      onClick={onOutsideClick}
    >
      <div
        className={classNames(
          scss.modal,
          {
            [scss.active]: state.opened,
          },
          state.modalClassName
        )}
      >
        {state.content}
      </div>
    </div>,
    root.current
  );
});

export default Modal;
