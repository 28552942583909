import React, { useRef } from "react";
import popupStore from "../../../../../../store/PopupStore";
import cn from "classnames";
import TeamMemberSkillsPopup from "./TeamMemberSkillsPopup";
import scss from "./styles.module.scss";

interface IProps {
  skills: string[];
  maxSkillsViewCount?: number;
}

const TeamMemberSkills = ({ skills, maxSkillsViewCount = 3 }: IProps) => {
  const showMoreRef = useRef<HTMLButtonElement>(null);

  const clickHandler = () => {
    if (showMoreRef.current) {
      popupStore.openPopup(
        showMoreRef.current,
        <TeamMemberSkillsPopup skills={skills.slice(maxSkillsViewCount)} />
      );
    }
  };

  const viewedSkills =
    skills.length > maxSkillsViewCount
      ? skills.slice(0, maxSkillsViewCount)
      : skills;

  return (
    <div className={scss.skillsWrapper}>
      {viewedSkills.map((item, i) => (
        <span key={i} className={scss.skillItem}>
          {item}
        </span>
      ))}
      {skills.length > maxSkillsViewCount ? (
        <button
          onClick={clickHandler}
          ref={showMoreRef}
          className={cn(scss.skillItem, scss.skillItemButton)}
        >
          Other Skills
        </button>
      ) : null}
    </div>
  );
};

export default TeamMemberSkills;
