import {memo, SVGProps} from 'react';

const ArrowLongRight = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M12.936 4.8L10.872 6.872L12 8L16 4L12 0L10.872 1.128L12.936 3.2H0V4.8H12.936Z"
                  fill="currentColor"/>
        </svg>

    );
};

export default memo(ArrowLongRight);