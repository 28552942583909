import { memo, SVGProps } from "react";

const OlxLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="60"
      height="56"
      viewBox="0 0 60 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.9473 14.7466V41.7124H30.2078V14.7466H36.9473ZM16.7289 16.9879C22.9336 16.9879 27.9666 22.0209 27.9666 28.2256C27.9666 34.4303 22.9336 39.4632 16.7289 39.4632C10.5242 39.4632 5.49121 34.4303 5.49121 28.2256C5.49121 22.0209 10.5242 16.9879 16.7289 16.9879ZM43.962 21.4861L45.9358 23.46L47.9097 21.4861H52.6753V26.2517L50.7014 28.2256L52.6753 30.1994V34.965H47.9097L45.9358 32.9912L43.962 34.965H39.1964V30.1994L41.1703 28.2256L39.1964 26.2517V21.4861H43.962ZM16.7289 23.7352C14.2517 23.7352 12.2385 25.7484 12.2385 28.2256C12.2385 30.7027 14.2517 32.7159 16.7289 32.7159C19.2061 32.7159 21.2192 30.7027 21.2192 28.2256C21.2192 25.7484 19.2061 23.7352 16.7289 23.7352Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(OlxLogo);
