import { axiosClient } from "./apiClient";
import { Page, PageBlockCase, PageBlockPosts } from "./contracts";
import { UseFetchPostDto } from "./query";

export const fetchPage = (slug: string, tag?: string): Promise<Page> => {
  const config = tag ? { params: { tag } } : {};
  return axiosClient
    .get(`/server/api/pages/${slug}`, config)
    .then(({ data }) => data);
};

export const sendContactMessage = (params: {
  name: string;
  email: string;
  message: string;
}) =>
  axiosClient.post("/server/api/contacts", {
    data: params,
  });

export const fetchCasePreview = (slug: string): Promise<PageBlockCase> =>
  axiosClient.get(`server/api/case/${slug}`).then(({ data }) => data);

export const fetchStoryById = (id: string): Promise<{ videoSrc: string }> =>
  axiosClient.get(`server/api/videos/${id}`).then(({ data }) => data);

export const fetchPosts = ({
  pageNumber,
  mainPostSlug,
  tag,
  pageSize,
}: UseFetchPostDto): Promise<Partial<PageBlockPosts>> =>
  axiosClient
    .get(`server/api/posts`, {
      params: {
        pageNumber: pageNumber,
        tag: tag,
        mainPostSlug: mainPostSlug,
        pageSize: pageSize,
      },
    })
    .then(({ data }) => data);
