import { memo, SVGProps } from "react";

const MercedesBenzLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="213"
      height="56"
      viewBox="0 0 213 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_5395_3100"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="205"
        height="54"
      >
        <path
          d="M4.42969 1.78794H208.236V54.2121H4.42969V1.78794Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_5395_3100)">
        <path
          d="M153.603 29.0176H159.22V30.3391H153.603V29.0176Z"
          fill="currentColor"
        />
        <path
          d="M60.1855 20.9998L59.6827 34.2669V34.4969C59.6827 35.6217 60.0096 35.8519 61.7693 35.9796V36.5933C60.4872 36.4911 59.9844 36.4654 59.2805 36.4654C58.5767 36.4654 58.0739 36.4911 56.7666 36.5933V35.9796C58.5264 35.8519 58.7776 35.6474 58.8279 34.2669L59.356 21.7156V21.5624C59.356 20.3861 59.004 20.1306 57.2694 20.0284V19.3894C58.2498 19.466 58.6268 19.4918 59.3307 19.4918C60.261 19.4918 60.7639 19.466 61.719 19.3637L66.3951 32.2986L71.1464 19.3637C72.2778 19.466 72.8057 19.4918 73.5599 19.4918C74.1883 19.4918 74.5906 19.466 75.5457 19.3894V20.0284C73.8364 20.1306 73.4591 20.3861 73.4591 21.5366V21.7156L73.7358 34.2669C73.761 35.6474 73.9872 35.8519 75.7973 35.9796V36.5933C74.2136 36.4397 73.6102 36.3886 72.6548 36.3886C71.6995 36.3886 71.0961 36.4397 69.5124 36.5933V35.9796C71.2219 35.8773 71.5989 35.5962 71.5989 34.3948V34.2669L71.3726 21.0255L67.2247 32.4263L66.923 33.27L66.571 34.2158L66.2443 35.1872L65.9677 36.0309H65.5904L65.3142 35.2129L64.962 34.2158L64.6101 33.2189L64.3084 32.3241L60.1855 20.9998Z"
          fill="currentColor"
        />
        <path
          d="M79.0635 29.2565C79.3149 26.317 80.2702 24.8086 81.8792 24.8086C83.5133 24.8086 84.3428 26.2402 84.4938 29.2565H79.0635ZM82.3318 35.954C80.0441 35.954 79.0384 34.1645 79.0132 30.1768H86.5049C86.4546 26.2402 84.8709 24.1439 81.9043 24.1439C78.8874 24.1439 77.002 26.6237 77.002 30.5603C77.002 34.497 78.8874 36.7979 82.1306 36.7979C84.5942 36.7979 86.2032 35.2382 86.5301 32.6053H85.5245C85.2226 34.7781 84.1166 35.954 82.3318 35.954Z"
          fill="currentColor"
        />
        <path
          d="M88.0016 25.0898V24.6297C89.4345 24.5019 90.2893 24.3486 91.3202 24.0416C91.4458 24.9876 91.496 25.3964 91.5463 26.1633V26.4446C92.0992 24.9876 93.1804 24.1439 94.4875 24.1439C95.7445 24.1439 96.6748 24.9876 96.6748 26.2144C96.6748 26.9559 96.2475 27.4417 95.6189 27.4417C95.0406 27.4417 94.5884 27.0836 94.5884 26.5725C94.5884 26.5214 94.6135 26.4193 94.6385 26.3169L94.6888 26.0612V25.8311C94.6888 25.4732 94.3869 25.2175 93.9344 25.2175C92.6273 25.2175 91.7975 26.8282 91.7975 29.3587V34.7014C91.7975 35.8005 92.0491 36.0051 93.5824 36.0816V36.5933C92.2 36.4652 91.6719 36.4395 90.7921 36.4395C89.887 36.4395 89.3592 36.4652 87.9766 36.5933V36.0816C89.51 36.0051 89.7865 35.8005 89.7865 34.7014V26.7514V26.3423C89.7614 25.2432 89.6356 25.1153 88.3285 25.0898H88.0016Z"
          fill="currentColor"
        />
        <path
          d="M106.292 23.9142V28.1319H105.738C105.286 25.908 104.356 24.8341 102.822 24.8341C100.937 24.8341 99.8305 26.9049 99.8305 30.5604C99.8305 34.1393 100.811 35.9541 102.747 35.9541C104.306 35.9541 105.236 34.8293 105.537 32.5798H106.568C106.241 35.3151 104.808 36.798 102.546 36.798C99.6296 36.798 97.6436 34.2925 97.6436 30.5604C97.6436 26.7515 99.6546 24.144 102.621 24.144C103.702 24.144 104.381 24.4251 105.186 25.2175L105.864 23.9142H106.292Z"
          fill="currentColor"
        />
        <path
          d="M110.419 29.2566C110.67 26.317 111.625 24.8087 113.234 24.8087C114.869 24.8087 115.698 26.2402 115.849 29.2566H110.419ZM113.687 35.9541C111.4 35.9541 110.394 34.1646 110.368 30.1769H117.86C117.81 26.2402 116.226 24.144 113.26 24.144C110.243 24.144 108.357 26.6238 108.357 30.5604C108.357 34.497 110.243 36.798 113.486 36.798C115.95 36.798 117.559 35.2383 117.885 32.6053H116.88C116.578 34.7781 115.472 35.9541 113.687 35.9541Z"
          fill="currentColor"
        />
        <path
          d="M124.824 36.1072C123.014 36.1072 122.059 34.1902 122.059 30.5347C122.059 26.8282 122.939 25.0131 124.749 25.0131C126.609 25.0131 127.564 26.9557 127.564 30.7139C127.564 34.2668 126.634 36.1072 124.824 36.1072ZM129.551 33.9345V19.1763C128.294 19.517 127.162 19.7215 125.754 19.8751V20.3095C127.338 20.3095 127.539 20.4886 127.539 21.8177V26.2913C126.609 24.7576 125.88 24.2973 124.523 24.2973C121.833 24.2973 119.947 26.8791 119.947 30.5603C119.947 34.2413 121.858 36.8235 124.598 36.8235C126.031 36.8235 127.011 36.1587 127.715 34.7271C127.816 35.6983 127.816 35.8006 128.067 36.7722C129.098 36.4396 129.526 36.3373 131.009 36.1587L131.386 36.1074V35.6729C129.701 35.6215 129.551 35.4938 129.551 33.9345Z"
          fill="currentColor"
        />
        <path
          d="M134.399 29.2565C134.65 26.317 135.605 24.8086 137.214 24.8086C138.848 24.8086 139.678 26.2402 139.829 29.2565H134.399ZM137.667 35.954C135.379 35.954 134.373 34.1645 134.348 30.1768H141.84C141.79 26.2402 140.206 24.1439 137.239 24.1439C134.222 24.1439 132.337 26.6237 132.337 30.5603C132.337 34.497 134.222 36.7979 137.465 36.7979C139.929 36.7979 141.538 35.2382 141.865 32.6053H140.859C140.558 34.7781 139.452 35.954 137.667 35.954Z"
          fill="currentColor"
        />
        <path
          d="M143.974 36.8236V32.5542L144.527 32.5287C144.979 34.8549 146.035 35.9795 147.795 35.9795C149.278 35.9795 150.384 34.9824 150.384 33.6788C150.384 32.8096 149.907 32.2217 148.675 31.6593L147.719 31.2246L146.563 30.6881C144.728 29.8187 144.149 29.1029 144.149 27.6206C144.149 25.601 145.708 24.2208 147.92 24.2208C148.901 24.2208 149.63 24.4509 150.485 25.0388L150.912 23.9906H151.365V27.9527H150.786C150.56 25.908 149.73 24.9365 148.147 24.9365C146.814 24.9365 145.784 25.8568 145.784 27.0582C145.784 28.0808 146.16 28.413 148.448 29.4354L149.504 29.8957C151.414 30.739 152.094 31.5571 152.094 33.0654C152.094 35.2128 150.41 36.7466 148.046 36.7466C146.89 36.7466 145.984 36.4397 145.029 35.6729L144.426 36.8236H143.974Z"
          fill="currentColor"
        />
        <path
          d="M166.072 28.0042C167.405 28.0042 168.159 28.2339 168.737 28.822C169.315 29.4101 169.642 30.5092 169.642 31.7361C169.642 34.2925 168.385 35.7242 166.147 35.7242H165.243C163.91 35.7242 163.684 35.5193 163.684 34.3947V28.0042H166.072ZM163.684 20.2586H165.821C167.933 20.2586 168.862 21.3067 168.862 23.684C168.862 24.8595 168.586 25.7034 168.033 26.3681C167.48 27.0071 166.801 27.2116 165.393 27.2116H163.684V20.2586ZM171.1 23.6329C171.1 22.3033 170.547 21.1276 169.592 20.3862C168.737 19.7472 167.756 19.4659 166.123 19.4659H159.511V20.1053C161.321 20.2073 161.572 20.412 161.572 21.7923V34.1904C161.572 35.5707 161.371 35.7495 159.511 35.9031V36.5164H165.871C167.731 36.5164 168.737 36.2864 169.793 35.6216C171.05 34.8037 171.88 33.2445 171.88 31.634C171.88 30.2536 171.302 29.0011 170.321 28.2339C169.793 27.8251 169.24 27.595 168.335 27.4159C170.145 26.726 171.1 25.4223 171.1 23.6329Z"
          fill="currentColor"
        />
        <path
          d="M175.982 29.2566C176.234 26.317 177.189 24.8087 178.798 24.8087C180.432 24.8087 181.262 26.2402 181.412 29.2566H175.982ZM179.251 35.9541C176.963 35.9541 175.957 34.1646 175.932 30.1769H183.423C183.373 26.2402 181.789 24.144 178.823 24.144C175.806 24.144 173.921 26.6238 173.921 30.5604C173.921 34.497 175.806 36.798 179.049 36.798C181.513 36.798 183.122 35.2383 183.449 32.6053H182.443C182.142 34.7781 181.035 35.9541 179.251 35.9541Z"
          fill="currentColor"
        />
        <path
          d="M184.754 25.1921V24.7576C186.213 24.5529 187.067 24.3486 188.073 23.9907C188.199 24.5783 188.274 25.2432 188.324 26.2146C189.229 24.8342 190.26 24.2206 191.718 24.2206C193.227 24.2206 194.534 25.013 195.037 26.2656C195.288 26.8791 195.414 27.5949 195.414 28.5663V34.7016C195.414 35.8005 195.665 36.0053 197.199 36.0818V36.5933C195.866 36.4654 195.363 36.4397 194.458 36.4397C193.528 36.4397 193.025 36.4654 191.718 36.5933V36.0818C193.076 36.0309 193.402 35.7751 193.402 34.7527V28.4897C193.402 26.2656 192.749 25.3711 191.14 25.3711C189.431 25.3711 188.526 26.4703 188.526 28.5663V34.7527C188.526 35.7751 188.852 36.0309 190.159 36.0818V36.5933C188.852 36.4654 188.35 36.4397 187.445 36.4397C186.565 36.4397 186.037 36.4654 184.729 36.5933V36.0818C186.263 36.0053 186.514 35.8005 186.514 34.7016V26.8025V26.4193C186.489 25.3711 186.338 25.2175 185.081 25.2175C185.006 25.2175 184.88 25.1921 184.754 25.1921Z"
          fill="currentColor"
        />
        <path
          d="M198.175 36.5164L204.988 25.1154H201.896C200.261 25.1154 199.86 25.4733 198.954 27.7738H198.451L199.03 24.4509H207.678L200.84 35.852H203.781C205.491 35.852 206.195 35.1614 206.949 32.8354H207.477L206.823 36.5164H198.175Z"
          fill="currentColor"
        />
      </g>
      <path
        d="M41.9976 38.2585C43.8635 35.1612 44.847 31.6142 44.8418 28C44.8418 16.9855 35.8962 8.05676 24.8622 8.05676C13.8277 8.05676 4.88283 16.9859 4.88283 28C4.8777 31.6143 5.86141 35.1614 7.72782 38.2585L7.6551 38.3166L7.74193 38.2831C11.2389 44.0711 17.5972 47.9432 24.8622 47.9432C32.1275 47.9432 38.485 44.0711 41.9817 38.2831L42.0685 38.3166L41.9976 38.2585ZM42.3873 28C42.3907 31.0541 41.5885 34.0553 40.0615 36.7019L27.7017 26.7651L25.1668 10.5156C34.704 10.6781 42.3873 18.441 42.3873 28ZM7.33923 28C7.33923 18.4414 15.0217 10.6785 24.559 10.5156L22.024 26.7651L9.66428 36.7019C8.13772 34.0551 7.33585 31.054 7.33923 28ZM24.8629 45.4919C18.6446 45.4919 13.1866 42.2559 10.0764 37.382L24.8629 31.6719L39.6494 37.3823C36.5388 42.2559 31.0818 45.4919 24.8629 45.4919Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(MercedesBenzLogo);
