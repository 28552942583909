import { memo, SVGProps } from "react";

const StarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.98258 1.44948L9.05215 4.74128C9.25296 5.35931 9.82889 5.77775 10.4787 5.77775H13.9399C14.4243 5.77775 14.6257 6.39756 14.2338 6.68226L11.4337 8.7167C10.9079 9.09867 10.6879 9.77572 10.8887 10.3938L11.9583 13.6855C12.108 14.1462 11.5808 14.5293 11.1889 14.2446L8.38873 12.2101C7.863 11.8282 7.1511 11.8282 6.62537 12.2101L3.8252 14.2446C3.43335 14.5293 2.90611 14.1462 3.05578 13.6855L4.12535 10.3938C4.32616 9.77572 4.10617 9.09867 3.58044 8.7167L0.780275 6.68226C0.388418 6.39756 0.589806 5.77775 1.07417 5.77775H4.53537C5.18521 5.77775 5.76114 5.35931 5.96195 4.74128L7.03152 1.44948C7.1812 0.988823 7.8329 0.988823 7.98258 1.44948Z"
        fill="currentColor"
        fillOpacity={props.fillOpacity || "0"}
        stroke="currentColor"
      />
    </svg>
  );
};

export default memo(StarIcon);
