import React, { useMemo } from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

type BaseProps = {
  mobileScroll?: false;
  columns: {
    xs?: number;
    sm?: number;
    md: number;
    lg: number;
  };
};

type ScrollProps = {
  mobileScroll: true;
  columns: {
    md: number;
    lg: number;
  };
};

type Props = { children: React.ReactNode; className?: string } & (
  | BaseProps
  | ScrollProps
);
const Grid = (props: Props) => {
  const variables = useMemo(
    () =>
      ({
        "--columns-xs": !props.mobileScroll ? props.columns.xs : 1,
        "--columns-sm": !props.mobileScroll ? props.columns.sm : 1,
        "--columns-md": props.columns.md,
        "--columns-lg": props.columns.lg,
      } as React.CSSProperties),
    [props]
  );

  return (
    <div
      style={variables}
      className={cn(
        scss.grid,
        {
          [scss.mobileScroll]: props.mobileScroll,
          hiddenScroll: props.mobileScroll,
        },
        props.className
      )}
    >
      <div
        className={cn(scss.gridInner, {
          [scss.mobileScroll]: props.mobileScroll,
        })}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Grid;
