import React, { useCallback, useEffect, useRef } from "react";
import popupStore from "../../store/PopupStore";
import { createPortal } from "react-dom";
import KeyCode from "../../constants/KeyCode";
import usePopupPosition from "./hooks/usePopupPosition";
import { observer } from "mobx-react-lite";
import scss from "./styles.module.scss";

const Popup = () => {
  const _root = useRef<HTMLDivElement>();
  const _popup = useRef<HTMLDivElement>();

  const {
    Component,
    open,
    target,
    placement,
    indentX,
    indentY,
    closeByEsc,
    disableOverlay,
  } = popupStore.state;

  useEffect(() => {
    _root.current = document.createElement("div");
    _popup.current = document.createElement("div");
    _popup.current.className = scss.popup;
    document.body.appendChild(_root.current);
  }, []);

  useEffect(() => {
    if (!open && _popup.current) {
      _popup.current.style.maxHeight = "unset";
      _popup.current.style.maxWidth = "unset";
    }
  }, [open]);

  useEffect(() => {
    const closeByScroll = () => popupStore.closePopup();
    const handler = (e: KeyboardEvent) => {
      if (
        !disableOverlay &&
        ~[KeyCode.TAB, KeyCode.PAGE_DOWN, KeyCode.PAGE_UP].indexOf(e.keyCode)
      ) {
        e.stopPropagation();
        e.preventDefault();
      }
      return e.keyCode === KeyCode.ESC && popupStore.closePopup();
    };

    if (open) {
      window.addEventListener("scroll", closeByScroll, true);
      window.addEventListener("resize", closeByScroll, true);

      if (closeByEsc) window.addEventListener("keydown", handler, true);
      return () => {
        window.addEventListener("scroll", closeByScroll, true);
        window.addEventListener("resize", closeByScroll, true);
        if (closeByEsc) {
          window.removeEventListener("keydown", handler, true);
        }
      };
    }
  }, [open, closeByEsc, disableOverlay]);

  const position = usePopupPosition({
    root: _root,
    popup: _popup,
    target,
    open,
    placement,
    indentX,
    indentY,
  });

  position();

  const overlayMouseDown = useCallback(() => {
    popupStore.closePopup();
  }, []);

  return _root.current && _popup.current ? (
    <>
      {createPortal(
        open && !disableOverlay && (
          <div className={scss.layer} onMouseDown={overlayMouseDown} />
        ),
        _root.current
      )}
      {createPortal(
        <div>
          {Component}
          <div className={scss.hide} id="__popup" />
        </div>,
        _popup.current
      )}
    </>
  ) : null;
};

export default observer(Popup);
