import { ReactNode } from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  children: ReactNode;
  hideMobilePadding?: boolean;
  hideTabletPadding?: boolean;
  hideDesktopXLGPadding?: boolean;
  className?: string;
};
const PageSection = (props: Props) => {
  return (
    <div
      className={cn(
        scss.section,
        {
          [scss.hideMobilePadding]: props.hideMobilePadding,
          [scss.hideTabletPadding]: props.hideTabletPadding,
          [scss.hideDesktopXLGPadding]: props.hideDesktopXLGPadding,
        },
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

export default PageSection;
