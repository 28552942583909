import React from "react";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import PageSection from "../../components/shared/PageSection/PageSection";
import CaseHeader from "./components/CaseHeader/CaseHeader";
import { Page } from "../../api/contracts";
import Footer from "../../components/Footer/Footer";
import PageContent from "../components/PageContent/PageContent";
import PageSEO from "../components/PageSEO/PageSEO";
import { PageContextProvider } from "../../context/PageContext";
import PageLayoutSidebar from "../../components/Layouts/PageLayoutSidebar/PageLayoutSidebar";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";
import scss from "../Home/styles.module.scss";

const Case = (props: Page) => {
  const enabledSidebar = !!props.sidebarWidgets?.length;
  return (
    <PageContextProvider page={props}>
      <PageSEO {...props.seo} />
      <MainLayout>
        <PageSection>
          <CaseHeader header={props.header} title={props.title} />
        </PageSection>
        <PageContent sections={props.mainSections} />
        <ContentLayout
          enabledSidebar={enabledSidebar}
          sidebar={
            <PageLayoutSidebar
              widgets={props.sidebarWidgets}
              sidebar={props.sidebar}
            />
          }
          className={scss.contentLayout}
        >
          <PageContent
            sections={props.contentSections}
            pageWithSidebar={enabledSidebar}
          />
        </ContentLayout>
      </MainLayout>
      <Footer />
    </PageContextProvider>
  );
};

export default Case;
