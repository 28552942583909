import { Helmet } from "react-helmet";
import { PageSeo } from "../../../api/contracts";

const PageSEO = ({
  title = "",
  description = "",
  ogTitle = "",
  ogDescription = "",
  ogUrl = window.location.host,
  ogType = "website",
  ogImage = "",
  ogImageWidth = 0,
  ogImageHeight = 0,
}: PageSeo) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content={ogType} />
      <meta
        property="og:image"
        content={ogImage ? `${window.location.origin}${ogImage}` : ""}
      />
      <meta property="og:image:width" content={String(ogImageWidth)} />
      <meta property="og:image:height" content={String(ogImageHeight)} />
    </Helmet>
  );
};

export default PageSEO;
