import { observer } from "mobx-react-lite";
import { useEffect, useRef, MouseEvent } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import sidebarStore from "../../store/SidebarStore";
import scss from "./styles.module.scss";

const Sidebar = observer(() => {
  const state = sidebarStore.state;
  const root = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    root.current.id = "sidebar";
    document.body.append(root.current);
  }, []);

  const onOutsideClick = (e: MouseEvent) => {
    if (e.currentTarget === e.target) sidebarStore.closeMenu();
  };

  return createPortal(
    <div
      className={classNames(scss.overlay, { [scss.active]: state.opened })}
      onClick={onOutsideClick}
    >
      <div className={classNames(scss.panel, { [scss.active]: state.opened })}>
        {state.content}
      </div>
    </div>,
    root.current
  );
});

export default Sidebar;
