import React, { useEffect } from "react";
import usePreloader from "../../hooks/usePreloader";
import Footer from "../../components/Footer/Footer";
import PageSection from "../../components/shared/PageSection/PageSection";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import HomeSection from "../../components/Layouts/HomeSection/HomeSection";
import NotFoundLogo from "./NotFoundLogo";
import Button from "../../components/shared/Button/Button";
import PageSEO from "../components/PageSEO/PageSEO";
import scss from "./styles.module.scss";

const NotFound = () => {
  const preloader = usePreloader();
  useEffect(() => {
    preloader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <PageSEO title="Umber - Page Not Found" />
      <MainLayout>
        <PageSection className={scss.section}>
          <HomeSection
            hasIsland
            islandBorderColor="#5c6b8d"
            borderColor="var(--dark-2)"
            islandClassName={scss.island}
            className={scss.container}
            style={{ backgroundImage: "url(/assets/not-found-bg.webp)" }}
          >
            <div className={scss.box}>
              <div className={scss.title}>
                ooops....
                <br />
                something went wrong
              </div>
              <div className={scss.icon}>
                <NotFoundLogo />
              </div>
              <div className={scss.description}>
                Sorry, the page you are looking for doesn't exist or has been
                removed.
              </div>
              <Button
                className={scss.button}
                type="innerLink"
                to="/"
                fullWidth={false}
                variant="success"
                rounding="small"
                size="large"
                uppercase
              >
                Back to home
              </Button>
            </div>
          </HomeSection>
        </PageSection>
      </MainLayout>
      <Footer />
    </>
  );
};

export default NotFound;
