import React from "react";

const NotFoundLogo = () => (
  <svg
    width="248"
    height="248"
    viewBox="0 0 248 248"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.0796 7.30225C77.9397 27.941 81.4163 48.6591 83.3025 69.2371C85.3499 91.5725 86.1617 114.095 86.1617 136.512C86.1617 160.881 81.6263 184.21 79.1399 208.328C78.4023 215.482 78.5742 222.648 78.8876 229.814C79.0175 232.782 78.2149 235.64 78.2149 238.517"
      stroke="#424A5B"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M164.873 14.8707C164.62 29.3436 165.08 43.7738 165.231 58.2419C165.414 75.8147 164.318 93.3076 164.138 110.863C163.895 134.524 163.624 158.188 164.138 181.838C164.403 194.027 166.009 206.319 166.009 218.461C166.009 220.008 165.667 229.285 167.144 228.3"
      stroke="#424A5B"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M18.0463 79.5806C17.7235 82.1629 75.8389 78.2699 81.5999 77.6675C98.0882 75.9432 114.556 74.1768 131.152 73.9253C147.474 73.678 163.618 74.6723 179.884 75.7964C196.33 76.9329 212.894 76.5533 229.373 76.5533C230.404 76.5533 237.491 76.632 237.152 77.3101"
      stroke="#424A5B"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M9.34229 166.996C12.4013 169.749 17.7549 170.659 21.662 170.759C26.7247 170.889 31.7783 170.42 36.8409 170.402C45.6701 170.369 54.2891 171.099 63.078 171.831C80.2732 173.264 97.7437 171.608 114.922 170.591C133.046 169.518 151.197 168.803 169.309 167.542C183.313 166.568 196.785 163.59 210.872 163.59C217.622 163.59 224.387 163.49 231.076 164.326C232.28 164.476 236.016 165.753 236.016 164.725"
      stroke="#424A5B"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M31.8926 221.852C30.7207 221.103 29.793 220.175 29.1094 219.068C28.4258 217.929 28.084 216.708 28.084 215.406C28.084 214.104 28.2793 212.558 28.6699 210.768C29.0605 208.977 29.5488 207.317 30.1348 205.787C30.7533 204.257 31.4857 202.776 32.332 201.344C33.2109 199.911 34.2038 198.642 35.3105 197.535C37.752 195.159 40.5189 193.971 43.6113 193.971C47.778 193.971 50.9681 195.501 53.1816 198.561C55.2324 201.327 56.2578 204.762 56.2578 208.863C56.2578 213.714 54.7116 217.49 51.6191 220.191C48.5918 222.796 44.5716 224.098 39.5586 224.098C36.6615 224.098 34.1061 223.349 31.8926 221.852ZM30.9648 215.064C30.9648 219.459 34.0898 221.656 40.3398 221.656C44.832 221.656 48.2012 220.501 50.4473 218.189C52.7259 215.878 53.8652 212.59 53.8652 208.326C53.8652 208.196 53.8652 208.066 53.8652 207.936C53.8652 204.192 52.9375 201.327 51.082 199.342C49.2266 197.356 46.6875 196.363 43.4648 196.363C43.1068 196.363 42.7812 196.363 42.4883 196.363C42.2279 196.363 41.9837 196.363 41.7559 196.363C40.0306 196.363 38.3216 197.535 36.6289 199.879C35.0339 202.027 33.6667 204.778 32.5273 208.131C31.4857 211.191 30.9648 213.502 30.9648 215.064Z"
      fill="white"
    />
    <path
      d="M44.8682 140.732C44.1846 140.732 43.7939 140.537 43.6963 140.146L43.8916 130.479H40.3271L34.4678 130.43H30.9521C30.529 130.43 30.1058 130.365 29.6826 130.234C29.2594 130.104 29.0479 129.762 29.0479 129.209C30.7731 126.995 32.4495 124.766 34.0771 122.52C39.2529 115.456 42.8011 111.924 44.7217 111.924C46.8701 111.924 47.9443 115.7 47.9443 123.252C47.9443 124.229 47.9281 125.27 47.8955 126.377L58.6865 126.23C58.6865 127.077 58.5726 127.695 58.3447 128.086C58.1494 128.477 57.9704 128.688 57.8076 128.721L48.7744 129.453C48.3187 130.723 48.042 132.155 47.9443 133.75C47.7816 136.354 47.7002 138.535 47.7002 140.293L44.8682 140.732ZM43.8916 116.465C43.1755 117.441 42.2965 118.402 41.2549 119.346C40.2458 120.257 39.2529 121.185 38.2764 122.129C37.2998 123.04 36.4209 123.968 35.6396 124.912C34.8584 125.856 34.3701 126.833 34.1748 127.842C34.4027 127.842 34.9072 127.842 35.6885 127.842C36.4697 127.842 37.5928 127.76 39.0576 127.598C40.5225 127.402 42.1338 127.207 43.8916 127.012V116.465Z"
      fill="white"
    />
    <path
      d="M189.893 53.8516C188.721 53.1029 187.793 52.1751 187.109 51.0684C186.426 49.929 186.084 48.7083 186.084 47.4062C186.084 46.1042 186.279 44.5579 186.67 42.7676C187.061 40.9772 187.549 39.3171 188.135 37.7871C188.753 36.2572 189.486 34.776 190.332 33.3438C191.211 31.9115 192.204 30.6419 193.311 29.5352C195.752 27.1589 198.519 25.9707 201.611 25.9707C205.778 25.9707 208.968 27.5007 211.182 30.5605C213.232 33.3275 214.258 36.7617 214.258 40.8633C214.258 45.7135 212.712 49.4896 209.619 52.1914C206.592 54.7956 202.572 56.0977 197.559 56.0977C194.661 56.0977 192.106 55.349 189.893 53.8516ZM188.965 47.0645C188.965 51.459 192.09 53.6562 198.34 53.6562C202.832 53.6562 206.201 52.5007 208.447 50.1895C210.726 47.8783 211.865 44.5905 211.865 40.3262C211.865 40.196 211.865 40.0658 211.865 39.9355C211.865 36.1921 210.938 33.3275 209.082 31.3418C207.227 29.3561 204.688 28.3633 201.465 28.3633C201.107 28.3633 200.781 28.3633 200.488 28.3633C200.228 28.3633 199.984 28.3633 199.756 28.3633C198.031 28.3633 196.322 29.5352 194.629 31.8789C193.034 34.0273 191.667 36.778 190.527 40.1309C189.486 43.1908 188.965 45.502 188.965 47.0645Z"
      fill="white"
    />
    <path
      d="M202.868 140.732C202.185 140.732 201.794 140.537 201.696 140.146L201.892 130.479H198.327L192.468 130.43H188.952C188.529 130.43 188.106 130.365 187.683 130.234C187.259 130.104 187.048 129.762 187.048 129.209C188.773 126.995 190.45 124.766 192.077 122.52C197.253 115.456 200.801 111.924 202.722 111.924C204.87 111.924 205.944 115.7 205.944 123.252C205.944 124.229 205.928 125.27 205.896 126.377L216.687 126.23C216.687 127.077 216.573 127.695 216.345 128.086C216.149 128.477 215.97 128.688 215.808 128.721L206.774 129.453C206.319 130.723 206.042 132.155 205.944 133.75C205.782 136.354 205.7 138.535 205.7 140.293L202.868 140.732ZM201.892 116.465C201.175 117.441 200.297 118.402 199.255 119.346C198.246 120.257 197.253 121.185 196.276 122.129C195.3 123.04 194.421 123.968 193.64 124.912C192.858 125.856 192.37 126.833 192.175 127.842C192.403 127.842 192.907 127.842 193.688 127.842C194.47 127.842 195.593 127.76 197.058 127.598C198.522 127.402 200.134 127.207 201.892 127.012V116.465Z"
      fill="white"
    />
    <path
      d="M113.893 137.852C112.721 137.103 111.793 136.175 111.109 135.068C110.426 133.929 110.084 132.708 110.084 131.406C110.084 130.104 110.279 128.558 110.67 126.768C111.061 124.977 111.549 123.317 112.135 121.787C112.753 120.257 113.486 118.776 114.332 117.344C115.211 115.911 116.204 114.642 117.311 113.535C119.752 111.159 122.519 109.971 125.611 109.971C129.778 109.971 132.968 111.501 135.182 114.561C137.232 117.327 138.258 120.762 138.258 124.863C138.258 129.714 136.712 133.49 133.619 136.191C130.592 138.796 126.572 140.098 121.559 140.098C118.661 140.098 116.106 139.349 113.893 137.852ZM112.965 131.064C112.965 135.459 116.09 137.656 122.34 137.656C126.832 137.656 130.201 136.501 132.447 134.189C134.726 131.878 135.865 128.59 135.865 124.326C135.865 124.196 135.865 124.066 135.865 123.936C135.865 120.192 134.938 117.327 133.082 115.342C131.227 113.356 128.688 112.363 125.465 112.363C125.107 112.363 124.781 112.363 124.488 112.363C124.228 112.363 123.984 112.363 123.756 112.363C122.031 112.363 120.322 113.535 118.629 115.879C117.034 118.027 115.667 120.778 114.527 124.131C113.486 127.191 112.965 129.502 112.965 131.064Z"
      fill="white"
    />
    <path
      d="M19 234.5C28.9647 222.024 44.3684 213.586 59 198.5C68.6301 188.571 72.7431 184.075 82.5 172C105.464 143.58 136.399 110.554 159.512 82.2527C170.914 68.2921 183.332 54.9083 196.097 42.1811C201.67 36.6243 208.612 32.221 213.5 26C215.183 23.8578 220.5 19 220.5 19"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default NotFoundLogo;
