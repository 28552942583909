import React from "react";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import HomeHeaderSection from "./components/HomeHeaderSection/HomeHeaderSection";
import PageSection from "../../components/shared/PageSection/PageSection";
import { workflowStages } from "../../api/fakeData/fakeDataHomePage";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";
import HomeWorkflowsSection from "./components/HomeWorkflowsSection/HomeWorkflowsSection";
import HomeCooperationTypesSection from "./components/HomeCooperationTypesSection/HomeCooperationTypesSection";
import { Page } from "../../api/contracts";
import NotFound from "../NotFound/NotFound";
import Footer from "../../components/Footer/Footer";
import PageContent from "../components/PageContent/PageContent";
import PageSEO from "../components/PageSEO/PageSEO";
import { useFetchPage } from "../../api/query";
import PageLayoutSidebar from "../../components/Layouts/PageLayoutSidebar/PageLayoutSidebar";
import { PageContextProvider } from "../../context/PageContext";
import scss from "./styles.module.scss";
import HomeLogoListSection from "./components/HomeLogoListSection/HomeLogoListSection";

const HomePage = (props: Page) => {
  const enabledSidebar = !!props.sidebarWidgets?.length;
  return (
    <PageContextProvider page={props}>
      <PageSEO {...props.seo} />
      <MainLayout hasIsland islandBorderColor="var(--dark-2)">
        <PageSection>
          <HomeHeaderSection />
        </PageSection>
        <PageSection>
          <HomeLogoListSection />
        </PageSection>
        <PageContent sections={props.mainSections} />
        <ContentLayout
          enabledSidebar={enabledSidebar}
          sidebar={
            <PageLayoutSidebar
              widgets={props.sidebarWidgets}
              sidebar={props.sidebar}
            />
          }
          className={scss.contentLayout}
        >
          <PageSection>
            <HomeWorkflowsSection workflowStages={workflowStages} />
            <HomeCooperationTypesSection />
          </PageSection>
          <PageContent
            sections={props.contentSections}
            pageWithSidebar={enabledSidebar}
          />
        </ContentLayout>
      </MainLayout>
      <Footer />
    </PageContextProvider>
  );
};

const Home = () => {
  const { data, status } = useFetchPage({ slug: "home" });

  if (status === "loading") return null;

  if (!data) return <NotFound />;

  return <HomePage {...data} />;
};

export default Home;
