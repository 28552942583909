import { MouseEventHandler, ReactNode } from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  children: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  size: "small" | "medium" | "large";
  className?: string;
  enabledPadding: boolean;
};

const Icon = (props: Props) => {
  return (
    <button
      className={cn(scss.icon, scss[props.size], props.className, {
        [scss.enabledPadding]: props.enabledPadding,
      })}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

Icon.defaultProps = {
  size: "medium",
  enabledPadding: true,
};

export default Icon;
