import { memo, SVGProps } from "react";

const HariboLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="95"
      height="56"
      viewBox="0 0 95 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1955 38.5137C23.6343 42.1749 19.0638 40.1185 20.0291 36.8517L25.8201 17.9226C26.6721 15.2183 30.135 15.9507 30.8164 17.9226L36.494 36.8522C37.4021 40.5133 32.5765 42.091 31.2705 38.4856L30.9865 37.4152H25.5928L25.1955 38.5137ZM28.2044 28.7961L26.9834 32.627H29.5949L28.2044 28.7961ZM64.3359 16.2741H69.2092C77.2351 16.2741 77.9808 24.6361 75.3439 27.6514C79.2994 32.202 76.9486 40.3938 70.012 40.3938H64.8523C63.5336 40.3938 62.9024 39.8815 62.9024 38.4032V18.0371C62.9024 17.241 63.4187 16.2741 64.3359 16.2741ZM68.1198 25.3735H69.7068C72.133 25.3735 72.133 21.4225 69.7068 21.4225H68.1198V25.3735ZM67.9194 35.1736H69.5063C72.8787 35.1736 73.1369 30.5676 69.5063 30.5676H67.9194V35.1736ZM9.0935 24.5337H13.2105V18.9724C13.2105 15.5609 18.469 15.475 18.469 18.9724V37.6634C18.469 41.2472 13.2105 41.3039 13.2105 37.6634V29.9232H9.0935V37.6634C9.0935 41.0744 3.79199 41.5335 3.79199 37.6634V18.9724C3.79199 15.4177 9.0935 15.3891 9.0935 18.9724V24.5337ZM54.9002 18.8649V37.7282C54.9002 41.2823 60.2164 41.2823 60.2164 37.7282V18.7499C60.2164 15.2525 54.9002 15.3103 54.9002 18.8649ZM78.8312 33.8284C78.8312 37.47 82.4141 40.3933 85.9389 40.3933C89.5208 40.3933 92.757 36.9823 92.757 33.8284V22.5632C92.757 19.983 90.3879 16.1415 85.6503 16.1415C81.3162 16.1415 78.8312 20.0403 78.8312 22.4482V33.8284ZM83.8872 23.2509C83.8872 20.8997 87.6144 20.6706 87.6144 23.2509V33.3417C87.6144 35.7205 83.8872 35.606 83.8872 33.3417V23.2509ZM38.2303 17.6665C38.2303 16.3972 38.8696 15.9361 39.509 15.9361H46.3109C48.6941 15.9361 52.3575 18.8488 52.3575 22.3101C52.3575 26.2329 51.4271 27.5017 49.1016 29.4636L52.3575 36.8467C53.5775 39.7308 49.2758 41.9222 47.7647 38.8076L43.8112 31.0202H43.3465V37.8844C43.3465 41.2306 38.2308 41.2306 38.2308 37.8844L38.2303 17.6665ZM43.2585 25.0787H45.6128C47.9961 25.0787 47.9961 21.2141 45.6128 21.2141H43.2585V25.0787Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(HariboLogo);
