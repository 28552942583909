import React from "react";
import HomeSection from "../../../../components/Layouts/HomeSection/HomeSection";
import BreadCrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";
import { PageHeader } from "../../../../api/contracts";
import { getMonthDateRange } from "../../../../utils/dateUtils";
import CalendarWithDays from "../../../../icons/CalendarWithDays";
import scss from "./styles.module.scss";

type Props = {
  header: PageHeader;
  title: string;
};
const CaseHeader = (props: Props) => {
  return (
    <HomeSection hasIsland borderColor="var(--dark-2)">
      <BreadCrumbs
        pageTitle={props.header.breadCrumbsTitle}
        textColor="var(--white)"
        pathLength={2}
      />
      <h1 className={scss.title}>{props.title}</h1>
      <p className={scss.description}>{props.header.description}</p>

      <div className={scss.info}>
        <div className={scss.authors}>
          {props.header.authors?.map((user, i) => (
            <div key={i} className={scss.author} style={{ zIndex: 10 - i }}>
              <img
                src={user.avatar}
                alt={user.firstName}
                className={scss.authorImg}
              />
            </div>
          ))}
        </div>
        <div className={scss.tags}>
          {props.header.tags?.map(({ title, id }) => (
            <div key={id} className={scss.tag}>
              {title}
            </div>
          ))}
        </div>
        <div className={scss.date}>
          <CalendarWithDays width={16} height={16} />
          {getMonthDateRange(props.header.dateRange)}
        </div>
      </div>
    </HomeSection>
  );
};

export default CaseHeader;
