import React, { ReactNode } from "react";
import HeaderNavLink from "../../Header/components/HeaderNavLink/HeaderNavLink";
import scss from "./styles.module.scss";

interface IProps {
  title: string;
  description: string;
  icon: ReactNode;
  buttonText: string;
  buttonHref?: string;
  backgroundImage: string;
}

const MobileLastItem = ({
  buttonHref,
  buttonText,
  icon,
  title,
  description,
  backgroundImage,
}: IProps) => {
  return (
    <div
      className={scss.mobileEndItemWrapper}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={scss.mobileEndItemIconWrapper}>{icon}</div>
      <div>
        <div className={scss.mobileEndItemTitle}>{title}</div>
        <p className={scss.mobileEndItemDescription}>{description}</p>
      </div>
      <HeaderNavLink
        type={"link"}
        className={scss.mobileEndItemButton}
        disableDefaultHover
        to={buttonHref || " "}
        position={"default"}
      >
        {buttonText}
      </HeaderNavLink>
    </div>
  );
};

export default MobileLastItem;
