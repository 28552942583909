import { memo, SVGProps } from "react";

const ImageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.3333 24H2.66667C1.19391 24 0 22.8061 0 21.3333V2.66667C0 1.19391 1.19391 0 2.66667 0H21.3333C22.8061 0 24 1.19391 24 2.66667V21.3333C24 22.8061 22.8061 24 21.3333 24ZM3.16667 2.66667C2.89052 2.66667 2.66667 2.89052 2.66667 3.16667V20.8333C2.66667 21.1095 2.89052 21.3333 3.16667 21.3333H20.8333C21.1095 21.3333 21.3333 21.1095 21.3333 20.8333V3.16667C21.3333 2.89052 21.1095 2.66667 20.8333 2.66667H3.16667ZM19.4353 17.876C19.6717 18.207 19.4351 18.6667 19.0284 18.6667H5C4.58798 18.6667 4.35279 18.1963 4.6 17.8667L7.6541 13.7945C7.83666 13.5511 8.1925 13.5258 8.40765 13.741L8.92568 14.259C9.14084 14.4742 9.49667 14.4489 9.67924 14.2055L12.9235 9.87979C13.1264 9.60923 13.5338 9.61397 13.7304 9.88917L19.4353 17.876ZM7.33333 10.6667C6.22876 10.6667 5.33333 9.77124 5.33333 8.66667C5.33333 7.5621 6.22876 6.66667 7.33333 6.66667C8.4379 6.66667 9.33333 7.5621 9.33333 8.66667C9.33333 9.77124 8.4379 10.6667 7.33333 10.6667Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(ImageIcon);
