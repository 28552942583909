import React, { useEffect, useRef } from "react";
import YouTube from "react-youtube";
import { PageBlockStory, VideoType } from "../../../../../api/contracts";
import scss from "./styles.module.scss";

type Props = {
  isActive: boolean;
  story: PageBlockStory;
  nextPrevVideoHandler: (
    id: string,
    direction: "next" | "prev",
    pauseHandler?: () => void
  ) => () => void;
  setActiveItemId: () => void;
};

const YouTubeVideoBlock = ({
  isActive,
  story,
  nextPrevVideoHandler,
  setActiveItemId,
}: Props) => {
  const youtubePlayerRef = useRef<YouTube | null>(null);
  const idRef = useRef(`player-${story.id}`);

  useEffect(() => {
    isActive
      ? youtubePlayerRef.current?.internalPlayer.playVideo()
      : youtubePlayerRef.current?.internalPlayer.pauseVideo();
  }, [isActive]);

  const opts = {
    width: 320,
    height: 576,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1, // 0 or 1 to autoplay the video
      rel: 0,
    },
  };

  return (
    <>
      <YouTube
        id={idRef.current}
        ref={(ref) => (youtubePlayerRef.current = ref)}
        iframeClassName={scss.iframe}
        loading="lazy"
        opts={opts}
        videoId={
          story.videoType === VideoType.YOUTUBE_VIDEO
            ? story.videoId
            : undefined
        }
        onEnd={nextPrevVideoHandler(story.id, "next")}
      />
      {!isActive && (
        <div className={scss.transparentCover} onClick={setActiveItemId} />
      )}
    </>
  );
};

export default YouTubeVideoBlock;
