import React from "react";
import { PageBlockStory, VideoType } from "../../../../../api/contracts";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  story: PageBlockStory;
  className?: string;
  onClick?: () => void;
};

const VideoCoverItem = ({ story, onClick, className }: Props) => {
  return (
    <div onClick={onClick} className={cn(scss.storyWrapper, className)}>
      <div className={scss.playBigButton}>
        <span className={cn(scss.playBigIcon)} />
      </div>
      <img
        className={scss.storyImage}
        src={
          story.videoType === VideoType.UMBER_VIDEO
            ? story?.coverImg
            : `https://i.ytimg.com/vi_webp/${story.videoId}/maxresdefault.webp`
        }
        alt={story.title}
      />
      <div className={cn(scss.storyTitleWrapper, scss.shown)}>
        <div className={scss.playButton}>
          <span className={cn(scss.playIcon)} />
        </div>
        <p className={scss.storyTitle}>{story.title}</p>
      </div>
      <div
        className={scss.storyAuthor}
      >{`${story.author?.firstName} - ${story.author?.shortRole}`}</div>
    </div>
  );
};

export default VideoCoverItem;
