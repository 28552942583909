import { memo, SVGProps } from "react";

const CalendarWithDays = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6667 14.6667H3.33333C2.59695 14.6667 2 14.0698 2 13.3334V4.00004C2 3.26366 2.59695 2.66671 3.33333 2.66671H4.66667V1.33337H6V2.66671H10V1.33337H11.3333V2.66671H12.6667C13.403 2.66671 14 3.26366 14 4.00004V13.3334C14 14.0698 13.403 14.6667 12.6667 14.6667ZM3.33333 6.66671V13.3334H12.6667V6.66671H3.33333ZM3.33333 4.00004V5.33337H12.6667V4.00004H3.33333ZM11.3333 12H10V10.6667H11.3333V12ZM8.66667 12H7.33333V10.6667H8.66667V12ZM6 12H4.66667V10.6667H6V12ZM11.3333 9.33337H10V8.00004H11.3333V9.33337ZM8.66667 9.33337H7.33333V8.00004H8.66667V9.33337ZM6 9.33337H4.66667V8.00004H6V9.33337Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(CalendarWithDays);
