import React from "react";
import Section from "../../../../components/shared/Section/Section";
import HeaderNavLink from "../../../../components/Header/components/HeaderNavLink/HeaderNavLink";
import resolutionStore from "../../../../store/ResolutionStore";
import { observer } from "mobx-react-lite";
import scss from "./styles.module.scss";

const HomeCooperationTypesSection = () => {
  const { phone, phoneLg, tablet } = resolutionStore.resolution;
  const isMobile = phone || phoneLg || tablet;
  return (
    <Section subtitle="other Types of cooperation">
      <div className={scss.cardsWrapper}>
        {cards.map((card, i) => (
          <div className={scss.cardItemWrapper} key={i}>
            <h3 className={scss.cardTitle}>{card.title}</h3>
            <p className={scss.cardDescription}>{card.description}</p>
          </div>
        ))}
      </div>
      <div className={scss.meetBlockWrapper}>
        <div className={scss.imageWrapper}>
          <img
            width={380}
            src="/assets/teamImg.webp"
            alt="teamPhoto"
            className={scss.image}
          />
        </div>
        <div className={scss.textWrapper}>
          <h3 className={scss.meetBlockTitle}>
            Hey There! {isMobile && <br />} We are <span>Umber</span> team
          </h3>
          <p className={scss.meetBlockDescription}>
            We would be happy to introduce you to our team
          </p>
        </div>
        <HeaderNavLink type="link" to="/team" className={scss.link}>
          {tablet ? "Meet the Team" : "Meet the Umber Team"}
        </HeaderNavLink>
      </div>
      <div className={scss.statisticsWrapper}>
        {companyStatistics.map((item, i) => (
          <div className={scss.statisticItemWrapper} key={i}>
            <div className={scss.statisticItemMainWrapper}>
              <h3 className={scss.statisticItemMainTitle}>{item.mainTitle}</h3>
              <p className={scss.statisticItemMainValue}>{item.mainValue}</p>
            </div>
            <div className={scss.statisticItemBottomWrapper}>
              {item.bottomValue && (
                <div className={scss.statisticItemBottomValue}>
                  {item.bottomValue}
                </div>
              )}
              <div className={scss.statisticItemBottomTitle}>
                {item.bottomTitle}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default observer(HomeCooperationTypesSection);

const cards = [
  {
    title: "project care",
    description:
      "Get the help you need to meet deadlines and exceed expectations with quickly and efficiently increases your team's capabilities.",
  },
  {
    title: "Prototyping",
    description:
      "Bring your business dreams to life. The ultimate testing ground for your ideas before they take the world by storm.",
  },
  {
    title: "Consultations",
    description:
      "Efficiency is key, and we've got the key to unlock it! Let’s streamline your product development process for maximum success.",
  },
];

const companyStatistics = [
  {
    mainTitle: "Projects Delivered",
    mainValue: "22",
    bottomTitle: "From last year",
    bottomValue: "+3",
  },
  {
    mainTitle: "years on market",
    mainValue: "6",
    bottomTitle: "Services since 2018",
  },
  {
    mainTitle: "Average years of specialist experience",
    mainValue: "5",
    bottomTitle: "Uniting talents into a team",
  },
];
