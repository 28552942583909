import { memo, SVGProps } from "react";

const IdeaIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="28"
      height="42"
      viewBox="0 0 28 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.8616 21.8815C27.2009 19.1192 28.3035 15.5805 27.9279 12.0402C27.5524 8.49998 25.7293 5.24669 22.8579 2.99279C21.2129 1.72839 19.2949 0.830991 17.2433 0.36569C15.1917 -0.0996114 13.0578 -0.121151 10.9965 0.302634C8.68197 0.79358 6.53499 1.83909 4.75605 3.34151C2.9771 4.84393 1.62423 6.75425 0.823806 8.89402C0.0233783 11.0338 -0.198495 13.3332 0.17891 15.5775C0.556315 17.8218 1.52068 19.9378 2.98194 21.7278C4.31371 23.2816 5.07426 25.2132 5.14586 27.2234V27.7806C4.38347 28.487 3.94399 29.4543 3.92365 30.4708V34.3139C3.92365 35.3331 4.34584 36.3106 5.09735 37.0313C5.84885 37.752 6.86812 38.1569 7.93091 38.1569V40.0785C7.93091 40.5881 8.142 41.0768 8.51776 41.4372C8.89351 41.7976 9.40314 42 9.93454 42C10.4659 42 10.9756 41.7976 11.3513 41.4372C11.7271 41.0768 11.9382 40.5881 11.9382 40.0785V38.1569H15.9454V40.0785C15.9454 40.5881 16.1565 41.0768 16.5323 41.4372C16.908 41.7976 17.4177 42 17.949 42C18.4804 42 18.9901 41.7976 19.3658 41.4372C19.7416 41.0768 19.9527 40.5881 19.9527 40.0785V38.1569C21.0155 38.1569 22.0347 37.752 22.7862 37.0313C23.5377 36.3106 23.9599 35.3331 23.9599 34.3139V30.4708C23.9609 29.4653 23.5508 28.4995 22.8179 27.7806V26.9544C22.8764 25.0895 23.5974 23.2997 24.8616 21.8815ZM7.93091 34.3139V30.4708H19.9527V34.3139H7.93091ZM21.7359 19.4796C19.9623 21.4858 18.9333 24 18.8106 26.6277H9.05294C8.86394 23.9656 7.81327 21.4264 6.0475 19.3643C4.9868 18.0877 4.28274 16.5729 4.00175 14.963C3.72077 13.3531 3.87213 11.701 4.44155 10.1627C5.01097 8.62446 5.97967 7.25068 7.25625 6.17102C8.53283 5.09136 10.0752 4.34143 11.7378 3.99199C13.2109 3.67333 14.74 3.67816 16.2108 4.00611C17.6817 4.33407 19.0562 4.97666 20.2316 5.88584C21.407 6.79503 22.3528 7.94728 22.9985 9.25644C23.6441 10.5656 23.9728 11.9978 23.9599 13.4459C23.9725 15.644 23.1861 17.7775 21.7359 19.4796Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(IdeaIcon);
