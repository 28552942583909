import React from "react";
import OlxLogo from "../../../../icons/OlxLogo";
import IconLogo from "../../../../icons/IconLogo";
import HariboLogo from "../../../../icons/HariboLogo";
import MercedesBenzLogo from "../../../../icons/MercedesBenzLogo";
import SyneosHealthLogo from "../../../../icons/SyneosHealthLogo";
import BenzingaLogo from "../../../../icons/BenzingaLogo";
import FileCoinLogo from "../../../../icons/FileCoinLogo";
import AmeriaLogo from "../../../../icons/AmeriaLogo";
import DataRootLabsLogo from "../../../../icons/DataRootLabsLogo";
import scss from "./styles.module.scss";

const HomeLogoListSection = () => {
  const logos = (
    <>
      <OlxLogo />
      <IconLogo />
      <HariboLogo />
      <MercedesBenzLogo />
      <SyneosHealthLogo />
      <BenzingaLogo />
      <FileCoinLogo />
      {/*<InvisibleAiLogo />*/}
      <AmeriaLogo />
      <DataRootLabsLogo />
    </>
  );

  return (
    <div className={scss.container}>
      <div className={scss.logosWrapper}>{logos}</div>
      <div className={scss.logosWrapper}>{logos}</div>
    </div>
  );
};

export default HomeLogoListSection;
