import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowLongRight from "../../icons/ArrowLongRight";
import cn from "classnames";
import resolutionStore from "../../store/ResolutionStore";
import { observer } from "mobx-react-lite";
import scss from "./styles.module.scss";

interface IProps {
  pageTitle?: string;
  textColor?: string;
  pathLength?: number;
}

const BreadCrumbs = ({ pageTitle, textColor, pathLength }: IProps) => {
  const { pathname } = useLocation();
  const breadCrumbs = pathname.split("/").slice(1, pathLength);
  const navigate = useNavigate();
  const isMobile = resolutionStore.isMobile;

  const navigateHandler = (path: string, isLast: boolean) => () => {
    if (!isLast) navigate(`/${path}`);
  };

  return (
    <div className={scss.container}>
      <div className={scss.title}>UMBER</div>
      {breadCrumbs.map((item, i, arr) => {
        const isLastItem = i + 1 === arr.length;
        if (isLastItem && isMobile) return null;
        return (
          <React.Fragment key={item}>
            {isLastItem ? (
              <ArrowLongRight className={scss.icon} />
            ) : (
              <span className={scss.icon}>/</span>
            )}
            <span
              style={{ color: textColor }}
              className={cn(scss.breadCrumb, { [scss.lastCrumb]: isLastItem })}
              onClick={navigateHandler(item, isLastItem)}
            >
              {isLastItem && pageTitle ? pageTitle : item.replace("-", " ")}
            </span>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default observer(BreadCrumbs);
