import React from "react";
import FigureBox from "./components/FigureBox/FigureBox";
import IdeaIcon from "../../../../icons/IdeaIcon";
import ReleaseIcon from "../../../../icons/ReleaseIcon";
import FigureSeparator from "./components/FigureSeparetor/FigureSeparator";
import { observer } from "mobx-react-lite";
import resolutionStore from "../../../../store/ResolutionStore";
import cn from "classnames";
import scss from "./styles.module.scss";

const HomeHeaderSection = () => {
  const { tablet, phoneLg, phone } = resolutionStore.resolution;
  const verticalAlign = tablet || phoneLg || phone;
  return (
    <div
      style={{ backgroundImage: "url(/assets/home-headerSectionBg.webp)" }}
      className={scss.homeHeaderSectionWrapper}
    >
      <h3 className={scss.homeHeaderSectionCaption}>Digital product agency</h3>
      <h1 className={scss.homeHeaderSectionTitle}>
        We help startups and enterprises to define, design, and launch digital
        products.
      </h1>
      <div className={scss.homeHeaderSectionAnimationBlock}>
        <div className={scss.light} />
        <FigureSeparator className={scss.separatorLeft} />
        <FigureSeparator className={scss.separatorRight} />
        <FigureBox
          variant={verticalAlign ? "box-top" : "box-left"}
          className={scss.boxOne}
          mainLineClassname={scss.boxOneLine}
        >
          <div className={cn(scss.boxIconWrapper, scss.boxOneIconWrapper)}>
            <IdeaIcon className={scss.boxIcon} />
          </div>
          <p className={scss.boxText}> from idea</p>
        </FigureBox>
        <FigureBox
          variant={
            verticalAlign ? "box-center-vertical" : "box-center-horizontal"
          }
          className={scss.boxTwo}
          mainLineClassname={scss.boxTwoMainLine}
          secondaryLineClassName={scss.boxTwoSecondaryLine}
        >
          <p className={scss.boxTitle}>
            User Pains & Business Goals Identifier
          </p>
          <p className={scss.boxDescription}>
            The interests of businesses and their customers are key for us, and
            we build products around their needs
          </p>
        </FigureBox>
        <FigureBox
          variant={verticalAlign ? "box-bottom" : "box-right"}
          className={scss.boxThree}
          mainLineClassname={scss.boxThreeLine}
        >
          <div className={cn(scss.boxIconWrapper, scss.boxThreeIconWrapper)}>
            <ReleaseIcon className={scss.boxIcon} />
          </div>
          <p className={scss.boxText}>to release</p>
        </FigureBox>
      </div>
    </div>
  );
};

export default observer(HomeHeaderSection);
