import React, { useCallback, useState } from "react";
import PostCard from "../PostCard/PostCard";
import BlogPostsGridLayout from "./components/PostsGridLayout/PostsGridLayout";
import SearchAndFilter from "./components/SearchAndFilter/SearchAndFilter";
import { observer } from "mobx-react-lite";
import {
  PageBlockPost,
  PageBlockPosts,
  PaginationDto,
} from "../../../../../api/contracts";
import Pagination from "../../../../Pagination/Pagination";
import { useFetchPosts } from "../../../../../api/query";
import { runInAction } from "mobx";
import resolutionStore from "../../../../../store/ResolutionStore";
import scss from "./styles.module.scss";

export enum PostsCurrentType {
  ALL = "ALL",
  SEO = "SEO",
  DEVELOPMENT = "DEVELOPMENT",
  DESIGN = "DESIGN",
}

interface IProps {
  block: PageBlockPosts;
}

const PostsWithMainPostAndPagination = ({ block }: IProps) => {
  const [posts, setPosts] = useState<PageBlockPost[]>(block.posts);
  const [postsCurrentType, setPostsCurrentType] = useState<PostsCurrentType>(
    // PostsCurrentType.ALL
    (block?.currentTag as PostsCurrentType) ||
      block.filterByTag?.title ||
      PostsCurrentType.ALL
  );
  const [paginationState, setPaginationState] = useState<PaginationDto>(
    block.pagination
  );
  const { phone, phoneLg, tablet } = resolutionStore.resolution;
  const isMobile = phone || phoneLg || tablet;
  const mainPost = block?.mainPost;
  const fetchPosts = useFetchPosts();
  const mainPostSlug = mainPost?.slug as string;

  const changePostTypeHandler = useCallback(
    (type: PostsCurrentType) => {
      return async () => {
        if (postsCurrentType !== type) {
          const { posts, pagination } = await fetchPosts.mutateAsync({
            pageNumber: 1,
            tag: type,
            mainPostSlug: mainPostSlug,
            pageSize: paginationState.pageSize,
          });
          runInAction(() => {
            setPostsCurrentType(type);
            setPaginationState(pagination as PaginationDto);
            setPosts(posts as PageBlockPost[]);
          });
        }
      };
    },
    [postsCurrentType, fetchPosts, mainPostSlug, paginationState.pageSize]
  );

  const setPageHandler = useCallback(
    (pageNumber: number) => async () => {
      const { posts, pagination } = await fetchPosts.mutateAsync({
        pageNumber: pageNumber,
        mainPostSlug: mainPostSlug,
        pageSize: paginationState.pageSize,
      });
      runInAction(() => {
        setPaginationState(pagination as PaginationDto);
        setPosts(posts as PageBlockPost[]);
      });
    },
    [paginationState, setPaginationState, setPosts, mainPostSlug, fetchPosts]
  );

  const onShowMoreHandler = useCallback(async () => {
    const { posts, pagination } = await fetchPosts.mutateAsync({
      pageNumber: paginationState.page + 1,
      mainPostSlug: mainPostSlug,
      pageSize: paginationState.pageSize,
    });
    runInAction(() => {
      setPaginationState(pagination as PaginationDto);
      setPosts((prevState) => [...prevState, ...(posts as PageBlockPost[])]);
    });
  }, [paginationState, setPosts, setPaginationState, mainPostSlug, fetchPosts]);

  return (
    <div className={scss.container}>
      {mainPost && <PostCard type={"big"} postInfo={mainPost} />}
      {posts.length ? (
        <>
          <SearchAndFilter
            postsCurrentType={postsCurrentType}
            clickHandler={changePostTypeHandler}
          />
          <BlogPostsGridLayout posts={posts} />
          <Pagination
            changePageHandler={setPageHandler}
            currentPage={paginationState.page}
            pagesCount={paginationState.pageCount}
            showMoreHandler={onShowMoreHandler}
            className={scss.blogPostsPaginationButton}
            isShowMore={true}
            isPaginationButtons={!isMobile}
            showMoreButtonFullWidth={isMobile}
          />
        </>
      ) : null}
    </div>
  );
};

export default observer(PostsWithMainPostAndPagination);
