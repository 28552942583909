import modalStore from "../store/ModalStore";
import ContactUs from "../pages/ContactUs/ContactUs";
import React from "react";

const useOpenContactUs = () => {
  return () => {
    modalStore.openModal(<ContactUs />, { overlayColor: "var(--dark-1)" });
  };
};

export default useOpenContactUs;
