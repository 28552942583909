import React from "react";
import cn from "classnames";
import UmberLogo from "../../icons/UmberLogo";
import HeaderNavLink from "./components/HeaderNavLink/HeaderNavLink";
import resolutionStore from "../../store/ResolutionStore";
import { observer } from "mobx-react-lite";
import MailIcon from "../../icons/MailIcon";
import BurgerButton from "../BurgerMenu/BurgerButton";
import useOpenContactUs from "../../hooks/useOpenContactUs";
import scss from "./styles.module.scss";

const Header = () => {
  const isBurger = resolutionStore.isMobileOrTablet;
  const isMobile = resolutionStore.isMobile;

  const openContactUs = useOpenContactUs();

  return (
    <header className={cn(scss.container)}>
      {isBurger ? (
        <BurgerButton />
      ) : (
        <div className={scss.containerAsideSection}>
          <HeaderNavLink
            to="/"
            activeContentColor={"var(--green-2)"}
            gradientBackground
            position={"left-side-first"}
          >
            home
          </HeaderNavLink>
          <HeaderNavLink
            to="/workflow"
            activeContentColor={"var(--green-2)"}
            gradientBackground
            position={"left-side"}
          >
            workflow
          </HeaderNavLink>
          {/*<HeaderNavLink*/}
          {/*  to="/blog"*/}
          {/*  activeContentColor={"var(--green-2)"}*/}
          {/*  gradientBackground*/}
          {/*  position={"left-side"}*/}
          {/*>*/}
          {/*  blog*/}
          {/*</HeaderNavLink>*/}
          <HeaderNavLink
            to="/team"
            position={"left-side"}
            activeContentColor={"var(--green-2)"}
          >
            team
          </HeaderNavLink>
        </div>
      )}
      <div className={scss.logoWrapper}>
        <UmberLogo />
      </div>
      {isBurger ? (
        <HeaderNavLink
          type="button"
          onClick={openContactUs}
          contentColor={"var(--dark-1)"}
          position={"right-side-last"}
          className={scss.contactUsButton}
          innerWrapperClassName={scss.contactUsButtonInner}
          disableDefaultHover
        >
          {isMobile ? <MailIcon /> : `contact us`}
        </HeaderNavLink>
      ) : (
        <div className={scss.containerAsideSection}>
          <HeaderNavLink
            type="button"
            aria-label="Open ContactUs form"
            onClick={openContactUs}
            contentColor={"var(--dark-1)"}
            position={"right-side-last"}
            className={scss.contactUsButton}
            innerWrapperClassName={scss.contactUsButtonInner}
            disableDefaultHover
          >
            contact us
          </HeaderNavLink>
        </div>
      )}
    </header>
  );
};

export default observer(Header);

// <HeaderNavLink
//     to="/medical"
//     icon={<MedicalIcon />}
//     contentColor={"var(--red-1)"}
//     gradientBackground
//     position={"left-side-first"}
// >
//     medical
// </HeaderNavLink>
// <HeaderNavLink
//     to="/fintech"
//     icon={<FintechIcon />}
//     contentColor={"var(--green-1)"}
//     gradientBackground
//     position={"left-side"}
// >
//     fintech
// </HeaderNavLink>
// <HeaderNavLink
//     to="/crypto"
//     icon={<CryptoIcon />}
//     contentColor={"var(--blue-1)"}
//     gradientBackground
//     position={"left-side"}
// >
//     crypto
// </HeaderNavLink>
