import { memo, SVGProps } from "react";

const DateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.3333 24H2.66667C1.19391 24 0 22.8061 0 21.3333V2.66667C0 1.19391 1.19391 0 2.66667 0H21.3333C22.8061 0 24 1.19391 24 2.66667V21.3333C24 22.8061 22.8061 24 21.3333 24ZM2.66667 9.33333V21.3333H21.3333V9.33333H2.66667ZM2.66667 2.66667V6.66667H21.3333V2.66667H2.66667Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(DateIcon);
