import { memo, SVGProps } from "react";

const ArrowsDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="12"
    viewBox="0 0 32 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.85906 0.9H2.85906V6.28L0.279063 6.04L4.35906 11.66L8.45906 6.04L5.85906 6.28V0.9ZM17.4997 0.9H14.4997V6.28L11.9197 6.04L15.9997 11.66L20.0997 6.04L17.4997 6.28V0.9ZM29.1403 0.9H26.1403V6.28L23.5603 6.04L27.6403 11.66L31.7403 6.04L29.1403 6.28V0.9Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(ArrowsDownIcon);
