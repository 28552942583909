import React from "react";
import { PageWidget, WidgetType } from "../../../api/contracts";
import OurTeam from "../../../pages/Blog/components/OurTeam/OurTeam";

type Props = {
  widget: PageWidget;
};
const PageSidebarWidget = (props: Props) => {
  switch (props.widget.widgetType) {
    case WidgetType.CONTACT_US:
      return (
        <OurTeam
          title={
            props.widget.theme === "light"
              ? "Contact us for a free product development consultation"
              : "excited business idea"
          }
          caption={
            props.widget.theme === "light" ? "Consulting call" : "In case of"
          }
          size="medium"
          sticky={props.widget.sticky}
          stickyOffset={props.widget.stickyOffset}
          theme={props.widget.theme}
          uppercaseAll={props.widget.theme === "dark"}
        />
      );
    default:
      return null;
  }
};

export default PageSidebarWidget;
