import { memo, SVGProps } from "react";
import { NavLink } from "react-router-dom";

const UmberLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <NavLink to="/" aria-label="Home page">
      <svg
        width="120"
        height="31"
        viewBox="0 0 120 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#filter0_d_4038_3304)">
          <path
            d="M19.1345 0H14.2385L10.2289 22.8759C9.84905 24.9862 8.83609 25.5771 7.23224 25.5771C5.6284 25.5771 4.78427 24.9862 5.16413 22.8759L9.17374 0H4.27779L0.141557 23.6356C-0.702572 28.2361 2.33629 29.8822 6.38811 29.8822H6.55694C11.4529 29.8822 14.1963 28.1939 14.9982 23.6356L19.1345 0Z"
            fill="white"
          />
          <path
            d="M39.5304 11.4802L36.3649 29.5445H41.0921L46.2835 0H41.3875L32.6508 17.178L29.9495 0H25.3912L20.1999 29.5445H24.7582L27.9236 11.4802L29.9495 25.2395H32.693L39.5304 11.4802Z"
            fill="white"
          />
          <path
            d="M65.7713 9.53866L66.4044 5.82449C67.0797 2.11032 64.3785 0 60.3267 0H52.6029L47.4115 29.5445H55.0509C59.9468 29.5445 62.7746 27.4342 63.4077 23.72L64.2941 18.8663C64.6739 16.5871 63.9564 14.5612 61.6351 14.1814C64.0408 13.8859 65.3492 12.0711 65.7713 9.53866ZM56.7813 4.22065H59.5247C60.9176 4.22065 61.6773 4.93816 61.3818 6.4998L60.7909 9.91852C60.4955 11.4802 59.3843 12.4537 57.9915 12.4537H55.248L56.7813 4.22065ZM59.4403 18.6131L58.6384 23.0447C58.3852 24.6064 57.3722 25.3239 55.9794 25.3239H53.0672L54.5718 16.6619H57.4841C58.8769 16.6619 59.6936 17.0514 59.4403 18.6131Z"
            fill="white"
          />
          <path
            d="M81.236 29.5445L81.9957 25.2395H73.8077L75.3271 16.7138H82.0801L82.8399 12.4087H76.0868L77.4796 4.30506H85.5411L86.3008 0H73.3434L68.152 29.5445H81.236Z"
            fill="white"
          />
          <path
            d="M99.6212 0H91.9818L86.7905 29.5445H91.6864L93.9428 16.6618H96.5596L98.0174 29.5445H102.871L100.887 17.0936C103.124 16.2073 104.517 14.4346 105.024 11.5224L105.952 6.24656C106.754 1.77267 103.673 0 99.6212 0ZM100.93 7.00628L100.273 10.4707C99.8928 12.581 98.881 12.4412 97.2771 12.4412H94.7025L96.1181 4.30506H98.6927C100.297 4.30506 101.309 4.89595 100.93 7.00628Z"
            fill="white"
          />
        </g>
        <mask
          id="mask0_4038_3304"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="12"
          y="11"
          width="109"
          height="7"
        >
          <path
            d="M12.1072 12.3979H70.991L120.942 11.3979L119.623 17.684L70.1522 16.684H12.1072V12.3979Z"
            fill="url(#paint0_linear_4038_3304)"
          />
        </mask>
        <g mask="url(#mask0_4038_3304)">
          <g filter="url(#filter1_d_4038_3304)">
            <path
              d="M12.1072 12.3979H118.942L117.623 16.684H12.1072V12.3979Z"
              fill="url(#paint1_linear_4038_3304)"
              shapeRendering="crispEdges"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_4038_3304"
            x="0"
            y="0"
            width="107.08"
            height="30.8822"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_4038_3304"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_4038_3304"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_4038_3304"
            x="12.1072"
            y="12.3979"
            width="107.835"
            height="5.28601"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_4038_3304"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_4038_3304"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_4038_3304"
            x1="78.9142"
            y1="14.5409"
            x2="12.1072"
            y2="14.541"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DD5B3A" />
            <stop offset="1" stopColor="#DD5B3A" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_4038_3304"
            x1="78.9142"
            y1="14.5409"
            x2="12.1072"
            y2="14.541"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </NavLink>
  );
};

export default memo(UmberLogo);
