import React from "react";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import TeamHeaderSection from "./components/TeamHeaderSection/TeamHeaderSection";
import TeamMembersSection from "./components/TeamMembersSection/TeamMembersSection";
import { team } from "../../api/fakeData/fakeDataTeamPage";
import PageSection from "../../components/shared/PageSection/PageSection";
import Footer from "../../components/Footer/Footer";
import { Page } from "../../api/contracts";
import PageContent from "../components/PageContent/PageContent";
import PageSEO from "../components/PageSEO/PageSEO";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";
import PageLayoutSidebar from "../../components/Layouts/PageLayoutSidebar/PageLayoutSidebar";
import { PageContextProvider } from "../../context/PageContext";
import scss from "./styles.module.scss";

const Team = (props: Page) => {
  const enabledSidebar = !!props.sidebarWidgets.length;
  return (
    <PageContextProvider page={props}>
      <PageSEO {...props.seo} />
      <MainLayout hasIsland islandBorderColor="var(--dark-2)">
        <PageSection>
          <div
            className={scss.teamHeaderSectionContainer}
            style={{ backgroundImage: "url(/assets/gradients.webp)" }}
          >
            <TeamHeaderSection />
            <TeamMembersSection team={team} />
          </div>
        </PageSection>
        <PageContent sections={props.mainSections} />
        <ContentLayout
          className={scss.contentLayout}
          enabledSidebar={enabledSidebar}
          sidebar={
            <PageLayoutSidebar
              widgets={props.sidebarWidgets}
              sidebar={props.sidebar}
            />
          }
        >
          <PageContent
            sections={props.contentSections}
            pageWithSidebar={enabledSidebar}
          />
        </ContentLayout>
      </MainLayout>
      <Footer />
    </PageContextProvider>
  );
};

export default Team;
