import React, { ReactNode } from "react";
import PageSidebarWidgets from "../PageSidebarWidgets/PageSidebarWidgets";
import { PageSidebar, PageWidget } from "../../../api/contracts";
import scss from "./styles.module.scss";

type Props = {
  widgets: PageWidget[];
  sidebar: PageSidebar;
  beforeWidgets?: ReactNode;
  afterWidgets?: ReactNode;
};
const PageLayoutSidebar = (props: Props) => {
  return (
    <div
      style={{ marginTop: props.sidebar?.marginTop }}
      className={scss.sidebar}
    >
      {props.beforeWidgets}
      <PageSidebarWidgets widgets={props.widgets} />
      {props.afterWidgets}
    </div>
  );
};

export default PageLayoutSidebar;
