import { WorkflowStageDto } from "../contracts";

export const workflowStages: WorkflowStageDto[] = [
  {
    title: "Discovery",
    accentColor: "#3DB8FD",
    steps: [
      "We dive into the business to understand the project at the stakeholder level.",
      "Conduct user research to gather insights on the needs, pain points, and preferences of the target users.",
      "Prepare the documentation with describing full product in details.",
    ],
  },
  {
    title: "Prototyping",
    accentColor: "#FDEA3D",
    steps: [
      "Create a concept for the product, including features, user flows.",
      "Build a low-fidelity prototype to test the concept and gather feedback from target users.",
    ],
  },
  {
    title: "Design",
    accentColor: "#1DEF40",
    steps: [
      "Create high-fidelity wireframes for the product.",
      "Prepare the moodboard to select appropriate stylistics or follow brand book guidelines.",
      "Build a design for all supported screens and prepare the UI Kit for developers.",
    ],
  },
  {
    title: "Development",
    accentColor: "var(--green-gay)",
    steps: [
      "Selecting development tools that work best for business goals.",
      "Constructing the architecture of the product based on already prepared documentation of the product.",
      "Process of the development.",
      "QA Testing of every iteration to get 1:1 results.",
    ],
  },
  {
    title: "Launch",
    accentColor: "#CBFD3D",
    steps: [
      "Conduct user testing to gather feedback and validate the product.",
      "Release the product to the market.",
      "Consolidation of the team for urgent support of the product during the first launch.",
    ],
  },
  {
    title: "Analysis",
    accentColor: "#B983FF",
    steps: [
      "Monitor the product's performance and gather technical feedback to improve the system.",
      "Collect users feedback about usability to consistently work on the improving it.",
    ],
  },
];
