import { MutableRefObject, useEffect } from "react";
import useRefData from "../../../../hooks/useRefData";

const useMenuOutsideClick = (
  ref: MutableRefObject<HTMLDivElement>,
  open: boolean,
  target: HTMLElement,
  onClose: () => void,
  canCloseMenuByScroll?: boolean
) => {
  const _click = useRefData(onClose);
  const _target = useRefData(target);

  useEffect(() => {
    const _onOutSideClick: EventListener = (e) => {
      if (
        ref.current &&
        !ref.current.contains(e.target as Node) &&
        !_target.current?.contains(e.target as Node)
      )
        _click.current();
    };

    if (open) window.addEventListener("click", _onOutSideClick, true);
    if (open && canCloseMenuByScroll)
      document.addEventListener("scroll", onClose, true);
    return () => {
      if (open) window.removeEventListener("click", _onOutSideClick, true);
      if (open && canCloseMenuByScroll)
        document.removeEventListener("scroll", onClose, true);
    };
    // eslint-disable-next-line
  }, [open]);
};

export default useMenuOutsideClick;
