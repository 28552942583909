import React, { ReactNode } from "react";
import UmberLogo from "../../icons/UmberLogo";
import cn from "classnames";
import HeaderNavLink from "../Header/components/HeaderNavLink/HeaderNavLink";
import StarIcon from "../../icons/StarIcon";
import resolutionStore from "../../store/ResolutionStore";
import { observer } from "mobx-react-lite";
import BehanceBGIcon from "../../icons/BehanceBGIcon";
import DribbbleBGIcon from "../../icons/DribbbleBGIcon";
import LinkedInBGIcon from "../../icons/LinkedInBGIcon";
import BehanceLogoIcon from "../../icons/BehanceLogoIcon";
import DribbbleLogoIcon from "../../icons/DribbbleLogoIcon";
import ClutchLogoIcon from "../../icons/ClutchLogoIcon";
import LinkedInLogoIcon from "../../icons/LinkedInLogoIcon";
import scss from "./styles.module.scss";

interface SocialDto {
  textColor?: string;
  title: string;
  rating?: string;
  icon?: ReactNode;
  mobileIcon?: ReactNode;
  href: string;
  label: string;
}

const leftSideButtons: SocialDto[] = [
  {
    title: "behance",
    textColor: "var(--blue-6)",
    icon: <BehanceBGIcon className={scss.behanceBGIcon} />,
    mobileIcon: <BehanceLogoIcon className={scss.socialLogoIcon} />,
    href: "https://www.behance.net/umber-tech",
    label: "Behance",
  },
  {
    title: "dribbble",
    textColor: "var(--purple-1)",
    icon: <DribbbleBGIcon className={scss.dribbleBGIcon} />,
    mobileIcon: <DribbbleLogoIcon className={scss.socialLogoIcon} />,
    href: "https://dribbble.com/umber-tech",
    label: "Dribbble",
  },
];

const rightSideButtons: SocialDto[] = [
  {
    title: "clutch",
    textColor: "var(--red-5)",
    rating: "5.0",
    mobileIcon: <ClutchLogoIcon className={scss.socialLogoIcon} />,
    href: "https://clutch.co/profile/umber-product-design-band#summary",
    label: "Clutch",
  },
  {
    title: "linkedin",
    textColor: "var(--blue-5)",
    icon: <LinkedInBGIcon className={scss.linkedInBGIcon} />,
    mobileIcon: <LinkedInLogoIcon className={scss.socialLogoIcon} />,
    href: "https://www.linkedin.com/company/30702575/",
    label: "LinkedIn",
  },
];

const mobileRightSideButtons: SocialDto[] = [
  {
    title: "clutch",
    textColor: "var(--red-5)",
    rating: "5.0",
    mobileIcon: <ClutchLogoIcon className={scss.socialLogoIcon} />,
    href: "https://clutch.co/profile/umber-product-design-band#summary",
    label: "Clutch",
  },
  {
    title: "linkedin",
    textColor: "var(--blue-5)",
    icon: <LinkedInBGIcon className={scss.linkedInBGIcon} />,
    mobileIcon: <LinkedInLogoIcon className={scss.socialLogoIcon} />,
    href: "https://www.linkedin.com/company/30702575/",
    label: "LinkedIn",
  },
  {
    title: "dribbble",
    textColor: "var(--purple-1)",
    icon: <DribbbleBGIcon className={scss.dribbleBGIcon} />,
    mobileIcon: <DribbbleLogoIcon className={scss.socialLogoIcon} />,
    href: "https://dribbble.com/umber-tech",
    label: "Dribbble",
  },
  {
    title: "behance",
    textColor: "var(--blue-6)",
    icon: <BehanceBGIcon className={scss.behanceBGIcon} />,
    mobileIcon: <BehanceLogoIcon className={scss.socialLogoIcon} />,
    href: "https://www.behance.net/umber-tech",
    label: "Behance",
  },
];

const Footer = () => {
  const { isTablet, isMobile } = resolutionStore.resolutionState;
  const { tablet } = resolutionStore.resolution;
  const isMobileOrTablet = isTablet || isMobile;

  return (
    <footer className={scss.footer}>
      <div className={scss.footerInner}>
        <div className={scss.leftSideSection}>
          {tablet || isMobile ? (
            <UmberLogo width={isMobile ? 96 : undefined} />
          ) : (
            leftSideButtons.map((button, i) => (
              <HeaderNavLink
                key={button.title}
                type="externalLink"
                href={button.href}
                target="_blank"
                paddings={
                  isMobile ? "small" : isMobileOrTablet ? "default" : "large"
                }
                className={cn(scss.linkButton)}
                position={i > 0 ? "left-side" : "left-side-first"}
                contentColor={button.textColor}
                aria-label={button.label}
              >
                <div className={scss.buttonContentWrapper}>
                  {!isMobile && button.rating ? (
                    <>
                      <StarIcon className={scss.starIcon} fillOpacity="0.32" />
                      <span className={scss.ratingValue}>{button.rating}</span>
                      <span className={scss.separator} />
                    </>
                  ) : null}
                  {isMobileOrTablet ? button.mobileIcon : button.icon || null}
                  {!isMobileOrTablet ? button.title : null}
                </div>
              </HeaderNavLink>
            ))
          )}
        </div>
        <div className={scss.rightSideSection}>
          {(tablet || isMobile ? mobileRightSideButtons : rightSideButtons).map(
            (button, i) => {
              const isLastItem = i === 0;
              return (
                <HeaderNavLink
                  key={button.title}
                  type="externalLink"
                  href={button.href}
                  target="_blank"
                  paddings={
                    isMobile
                      ? "small"
                      : isMobileOrTablet || isLastItem
                      ? "default"
                      : "large"
                  }
                  className={scss.linkButton}
                  position={isLastItem ? "right-side-last" : "right-side"}
                  contentColor={button.textColor}
                  aria-label={button.label}
                >
                  <div className={scss.buttonContentWrapper}>
                    {!isMobile && button.rating ? (
                      <>
                        <StarIcon
                          className={scss.starIcon}
                          fillOpacity="0.32"
                        />
                        <span className={scss.ratingValue}>
                          {button.rating}
                        </span>
                        <span className={scss.separator} />
                      </>
                    ) : null}
                    {isMobileOrTablet ? button.mobileIcon : button.icon || null}
                    {!isMobileOrTablet ? button.title : null}
                  </div>
                </HeaderNavLink>
              );
            }
          )}
        </div>
        {!(isMobile || tablet) && (
          <div className={scss.logoWrapper}>
            <UmberLogo height={24} />
          </div>
        )}
        {(isMobile || tablet) && <div className={scss.island} />}
        <p
          className={scss.privacyPolicyText}
        >{`All right reserved © ${new Date().getFullYear()}`}</p>
      </div>
    </footer>
  );
};

export default observer(Footer);
