import React, { useEffect } from "react";
import HeaderNavLink from "../Header/components/HeaderNavLink/HeaderNavLink";
import resolutionStore from "../../store/ResolutionStore";
import { observer } from "mobx-react-lite";
import sidebarStore from "../../store/SidebarStore";
import BurgerMenu from "./BurgerMenu";
import cn from "classnames";
import scss from "./styles.module.scss";

const BurgerButton = () => {
  const isMobile = resolutionStore.isMobile;
  const isOpen = sidebarStore.state.opened;

  useEffect(() => {
    return () => sidebarStore.closeMenu();
  }, []);

  const openBurgerMenuHandler = () => {
    isOpen ? sidebarStore.closeMenu() : sidebarStore.openMenu(<BurgerMenu />);
  };

  return (
    <>
      <HeaderNavLink
        type={"button"}
        gradientBackground
        position={"left-side-first"}
        aria-label="Open Burger menu"
        icon={
          <div
            className={cn(scss.burgerIcon, { [scss.burgerIconOpen]: isOpen })}
          >
            <span className={scss.top} />
            <span className={scss.middle} />
            <span className={scss.bottom} />
          </div>
        }
        onClick={openBurgerMenuHandler}
      >
        {!isMobile ? `menu` : null}
      </HeaderNavLink>
    </>
  );
};

export default observer(BurgerButton);
