import React from "react";
import { PageWidget } from "../../../api/contracts";
import PageSidebarWidget from "./PageSidebarWidget";

type Props = {
  widgets: PageWidget[];
};
const PageSidebarWidgets = (props: Props) => {
  return (
    <>
      {props.widgets?.map((widget) => (
        <PageSidebarWidget key={widget.id} widget={widget} />
      ))}
    </>
  );
};

export default PageSidebarWidgets;
