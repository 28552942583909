import { memo, SVGProps } from "react";

const ArrowChevronLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 8L8.09537 16L10 14.1178L3.80388 7.99467L10 1.87155L8.09537 0L0 8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(ArrowChevronLeft);
