import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import WorkflowHeaderSection from "./components/WorkflowHeaderSection/WorkflowHeaderSection";
import WorkflowPrinciples from "./components/WorkflowPrinciples/WorkflowPrinciples";
import PageSection from "../../components/shared/PageSection/PageSection";
import WorkflowStage from "./components/WorkflowStage/WorkflowStage";
import SettingOutlineIcon from "../../icons/SettingsOutlineIcon";
import DateIcon from "../../icons/DateIcon";
import ImageIcon from "../../icons/ImageIcon";
import CodeIcon from "../../icons/CodeIcon";
import TestingIcon from "../../icons/TestingIcon";
// import { projectCards } from "../../api/fakeData/fakeDataTeamPage";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";
import WorkflowTabs from "./components/WorkflowTabs/WorkflowTabs";
import resolutionStore from "../../store/ResolutionStore";
import WorkflowSidebar from "./components/WorkflowSidebar/WorkflowSidebar";
import ChartIcon from "../../icons/ChartIcon";
import scss from "./styles.module.scss";

// import workshopAuthor from "../../../public/assets/workflow-stage-workshops-author.webp";
// import workshopProductImage from "../../../public/assets/workflow-stage-workshops-product.webp";
// import workshopProjectImage from "../../../public/assets/workflow-stage-workshops-project.webp";
// import workshopPrototypingImage from "../../../public/assets/workflow-stage-workshops-prototyping.webp";
// import workshopConsultationImage from "../../../public/assets/workflow-stage-workshops-consultation.webp";

// import protoImage from "../../../public/assets/workflow-stage-prototyping.webp";
// import protoAuthor from "../../../public/assets/workflow-stage-prototyping-author.webp";

// import designImage from "../../../public/assets/workflow-stage-design.webp";
// import designAuthor from "../../../public/assets/workflow-stage-design-author.webp";

// import devImage from "../../../public/assets/workflow-stage-dev.webp";
// import devAuthor from "../../../public/assets/workflow-stage-dev-author.webp";

// import testingImage from "../../../public/assets/workflow-stage-testing.webp";
// import testingAuthor from "../../../public/assets/workflow-stage-testing-author.webp";

// import analysisImage from "../../../public/assets/workflow-stage-analysis.webp";
// import analysisAuthor from "../../../public/assets/workflow-stage-analysis-author.webp";

import Footer from "../../components/Footer/Footer";
import { Page } from "../../api/contracts";
import PageContent from "../components/PageContent/PageContent";
import PageSEO from "../components/PageSEO/PageSEO";
import { PageContextProvider } from "../../context/PageContext";
// import BlockProjects from "../../components/blocks/BlockProjects/BlockProjects";

const Workflow = (props: Page) => {
  const [index, setIndex] = useState(0);
  const { isLaptop } = resolutionStore.resolutionState;

  return (
    <PageContextProvider page={props}>
      <PageSEO {...props.seo} />
      <MainLayout hasIsland islandBorderColor="var(--dark-2)">
        <PageSection>
          <WorkflowHeaderSection />
        </PageSection>
        <PageSection hideMobilePadding>
          <WorkflowPrinciples />
        </PageSection>
        <PageContent sections={props.mainSections} />
        <div className={scss.divider} />
        {!isLaptop && (
          <PageSection hideDesktopXLGPadding>
            <WorkflowTabs
              position="horizontal"
              activeIndex={index}
              setIndex={setIndex}
            />
          </PageSection>
        )}
        <ContentLayout
          className={scss.layout}
          enabledSidebar
          sidebar={
            <WorkflowSidebar
              index={index}
              setIndex={setIndex}
              widgets={props.sidebarWidgets}
              sidebar={props.sidebar}
            />
          }
        >
          <PageSection>
            {stages[index].map((item, i) => (
              <WorkflowStage
                key={i}
                icon={item.icon}
                label={item.label + (i + 1)}
                title={item.title}
                accentColor={item.accentColor}
                image={item.image}
                author={item.author}
                comment={item.comment}
                steps={item.steps}
              />
            ))}
          </PageSection>
          <PageContent sections={props.contentSections} pageWithSidebar />
        </ContentLayout>
      </MainLayout>
      <Footer />
    </PageContextProvider>
  );
};

export default observer(Workflow);

const stages = [
  [
    {
      icon: <SettingOutlineIcon height="26" width="26" />,
      label: "Stage 0",
      title: "DISCOVERY",
      accentColor: "#3DB8FD",
      image: "/assets/workflow-stage-workshops-product.webp",
      author: "/assets/workflow-stage-workshops-author.webp",
      comment: (
        <span>
          In the beginning, we will work on defining{" "}
          <span className="comment-accent">business goals</span> and{" "}
          <span className="comment-accent">pains of users</span> to prepare the
          best solution
        </span>
      ),
      steps: [
        "We dive into the business to understand the project at the stakeholder level.",
        "Conduct user research to gather insights on the needs, pain points, and preferences of the target users.",
        "Prepare the documentation with describing full product in details.",
      ],
    },
    {
      icon: <DateIcon />,
      label: "Stage 0",
      title: "Prototyping",
      accentColor: "#FDEA3D",
      image: "/assets/workflow-stage-prototyping.webp",
      author: "/assets/workflow-stage-prototyping-author.webp",
      comment: (
        <span>
          Based on the workshop, we develop a{" "}
          <span className="comment-accent">prototype</span> using best practices
          and our experience.
        </span>
      ),
      steps: [
        "Create a concept for the product, including features, user flows.",
        "Build a low-fidelity prototype to test the concept and gather feedback from target users.",
      ],
    },
    {
      icon: <ImageIcon />,
      label: "Stage 0",
      title: "Design",
      accentColor: "#1DEF40",
      image: "/assets/workflow-stage-design.webp",
      author: "/assets/workflow-stage-design-author.webp",
      comment: (
        <span>
          We create <span className="comment-accent">visual language</span> that
          reflects the brand's identity, and fosters deeper engagement with the
          product or service.
        </span>
      ),
      steps: [
        "Create high-fidelity wireframes for the product.",
        "Prepare the moodboard to select appropriate stylistics or follow brand book guidelines.",
        "Build a design for all supported screens and prepare the UI Kit for developers.",
      ],
    },
    {
      icon: <CodeIcon />,
      label: "Stage 0",
      title: "Development",
      accentColor: "var(--green-gay)",
      image: "/assets/workflow-stage-dev.webp",
      author: "/assets/workflow-stage-dev-author.webp",
      comment: (
        <span>
          We design the <span className="comment-accent">architecture</span> in
          accordance with your development strategy for more efficient operation
          in the long term.
        </span>
      ),
      steps: [
        "Selecting development tools that work best for business goals.",
        "Constructing the architecture of the product based on already prepared documentation of the product.",
        "Process of the development.",
        "QA Testing of every iteration to get 1:1 results.",
      ],
    },
    {
      icon: <TestingIcon />,
      label: "Stage 0",
      title: "Testing & Launch",
      accentColor: "#CBFD3D",
      image: "/assets/workflow-stage-testing.webp",
      author: "/assets/workflow-stage-testing-author.webp",
      comment: (
        <span>
          Before launching the product, we will do an{" "}
          <span className="comment-accent">internal launch to test</span>{" "}
          everything and fix bugs and errors.
        </span>
      ),
      steps: [
        "Conduct user testing to gather feedback and validate the product.",
        "Release the product to the market.",
        "Consolidation of the team for urgent support of the product during the first launch.",
      ],
    },
    {
      icon: <ChartIcon />,
      label: "Stage 0",
      title: "Business Analysis & monitoring",
      accentColor: "#B983FF",
      image: "/assets/workflow-stage-analysis.webp",
      author: "/assets/workflow-stage-analysis-author.webp",
      comment: (
        <span>
          After the release, we <span className="comment-accent">analyse</span>{" "}
          the data in detail to improve the solution based on user feedback.
        </span>
      ),
      steps: [
        "Monitor the product's performance and gather technical feedback to improve the system.",
        "Collect users feedback about usability to consistently work on the improving it.",
      ],
    },
  ],
  [
    {
      icon: <SettingOutlineIcon height="26" width="26" />,
      label: "Stage 0",
      title: "DISCOVERY",
      accentColor: "#3DB8FD",
      image: "/assets/workflow-stage-workshops-project.webp",
      author: "/assets/workflow-stage-workshops-author.webp",
      comment: (
        <span>
          In the beginning, we will work on defining{" "}
          <span className="comment-accent">business goals</span> and{" "}
          <span className="comment-accent">pains of users</span> to prepare the
          best solution
        </span>
      ),
      steps: [
        "We dive into the business to understand the project at the stakeholder level.",
        "If we are involved in the product as an additional team, we define the workflow with the goal of being as effective and useful as possible.",
        "Prepare the documentation with describing full project in details.",
      ],
    },
    {
      icon: <DateIcon />,
      label: "Stage 0",
      title: "Prototyping",
      accentColor: "#FDEA3D",
      image: "/assets/workflow-stage-prototyping.webp",
      author: "/assets/workflow-stage-prototyping-author.webp",
      comment: (
        <span>
          Based on the workshop, we develop a{" "}
          <span className="comment-accent">prototype</span> using best practices
          and our experience.
        </span>
      ),
      steps: [
        "Create a concept for the product, including features, user flows.",
        "Build a low-fidelity prototype to test the concept and gather feedback from target users.",
      ],
    },
    {
      icon: <ImageIcon />,
      label: "Stage 0",
      title: "Design",
      accentColor: "#1DEF40",
      image: "/assets/workflow-stage-design.webp",
      author: "/assets/workflow-stage-design-author.webp",
      comment: (
        <span>
          We create <span className="comment-accent">visual language</span> that
          reflects the brand's identity, and fosters deeper engagement with the
          product or service.
        </span>
      ),
      steps: [
        "Create high-fidelity wireframes for the product.",
        "Prepare the moodboard to select appropriate stylistics or follow brand book guidelines.",
        "Build a design for all supported screens and prepare the UI Kit for developers.",
      ],
    },
    {
      icon: <CodeIcon />,
      label: "Stage 0",
      title: "Development",
      accentColor: "var(--green-gay)",
      image: "/assets/workflow-stage-dev.webp",
      author: "/assets/workflow-stage-dev-author.webp",
      comment: (
        <span>
          We design the <span className="comment-accent">architecture</span> in
          accordance with your development strategy for more efficient operation
          in the long term.
        </span>
      ),
      steps: [
        "Selecting development tools that work best for business goals.",
        "Constructing the architecture of the product based on already prepared documentation of the product.",
        "Process of the development.",
        "QA Testing of every iteration to get 1:1 results.",
      ],
    },
    {
      icon: <TestingIcon />,
      label: "Stage 0",
      title: "Testing & Launch",
      accentColor: "#CBFD3D",
      image: "/assets/workflow-stage-testing.webp",
      author: "/assets/workflow-stage-testing-author.webp",
      comment: (
        <span>
          Before launching the product, we will do an{" "}
          <span className="comment-accent">internal launch to test</span>{" "}
          everything and fix bugs and errors.
        </span>
      ),
      steps: [
        "Conduct user testing to gather feedback and validate the product.",
        "Release the product to the market.",
        "Consolidation of the team for urgent support of the product during the first launch.",
      ],
    },
    {
      icon: <ChartIcon />,
      label: "Stage 0",
      title: "Business Analysis & monitoring",
      accentColor: "#B983FF",
      image: "/assets/workflow-stage-analysis.webp",
      author: "/assets/workflow-stage-analysis-author.webp",
      comment: (
        <span>
          After the release, we <span className="comment-accent">analyse</span>{" "}
          the data in detail to improve the solution based on user feedback.
        </span>
      ),
      steps: [
        "Monitor the product's performance and gather technical feedback to improve the system.",
        "Collect users feedback about usability to consistently work on the improving it.",
      ],
    },
  ],
  [
    {
      icon: <SettingOutlineIcon height="26" width="26" />,
      label: "Stage 0",
      title: "DISCOVERY",
      accentColor: "#3DB8FD",
      image: "/assets/workflow-stage-workshops-prototyping.webp",
      author: "/assets/workflow-stage-workshops-author.webp",
      comment: (
        <span>
          In the beginning, we will work on defining{" "}
          <span className="comment-accent">business goals</span> and{" "}
          <span className="comment-accent">pains of users</span> to prepare the
          best solution
        </span>
      ),
      steps: [
        "Work on defining business goals and business ideas.",
        "We dive into the business to understand the project at the stakeholder level.",
        "Conduct user research to gather insights on the needs, pain points, and preferences of the target users.",
      ],
    },
    {
      icon: <DateIcon />,
      label: "Stage 0",
      title: "Prototyping",
      accentColor: "#FDEA3D",
      image: "/assets/workflow-stage-prototyping.webp",
      author: "/assets/workflow-stage-prototyping-author.webp",
      comment: (
        <span>
          Based on the workshop, we develop a{" "}
          <span className="comment-accent">prototype</span> using best practices
          and our experience.
        </span>
      ),
      steps: [
        "Create a concept for the product, including features, user flows, and user interface (UI) design.",
        "Build a few rapid low-fidelity prototype to test the concept and gather feedback from target users.",
        "Prepare the report with results of testing and updated wireframe.",
      ],
    },
  ],
  [
    {
      icon: <SettingOutlineIcon height="26" width="26" />,
      label: "Stage 0",
      title: "DISCOVERY",
      accentColor: "#3DB8FD",
      image: "/assets/workflow-stage-workshops-consultation.webp",
      author: "/assets/workflow-stage-workshops-author.webp",
      comment: (
        <span>
          In the beginning, we will work on defining{" "}
          <span className="comment-accent">business goals</span> and{" "}
          <span className="comment-accent">pains of users</span> to prepare the
          best solution
        </span>
      ),
      steps: [
        "Discuss and get recommendation about building internal processes inside product team from our managers.",
        "Obtain expert advice and receive guidance on technical inquiries from our tech leads.",
        "Consult with our design specialists and receive suggestions for design procedures.",
      ],
    },
  ],
];
