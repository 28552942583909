import React, { ReactNode } from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

type IProps = {
  children: ReactNode;
  className?: string;
  variant?:
    | "box-left"
    | "box-center-horizontal"
    | "box-center-vertical"
    | "box-top"
    | "box-bottom"
    | "box-right";
  mainLineClassname?: string;
  secondaryLineClassName?: string;
};

const FigureBox = ({
  children,
  className,
  variant,
  secondaryLineClassName,
  mainLineClassname,
}: IProps) => {
  switch (variant) {
    case "box-left":
      return (
        <div className={cn(scss.boxWrapper, scss[variant], className)}>
          <div className={cn(scss.island, scss.right)}>
            <div className={cn(scss.islandLine, mainLineClassname)} />
          </div>
          {children}
        </div>
      );
    case "box-right":
      return (
        <div className={cn(scss.boxWrapper, scss[variant], className)}>
          <div className={cn(scss.island, scss.left)}>
            <div className={cn(scss.islandLine, mainLineClassname)} />
          </div>
          {children}
        </div>
      );
    case "box-center-horizontal":
      return (
        <div className={cn(scss.boxWrapper, scss[variant], className)}>
          <div className={cn(scss.island, scss.left)}>
            <div className={cn(scss.islandLine, mainLineClassname)} />
          </div>
          {children}
          <div className={cn(scss.island, scss.right)}>
            <div className={cn(scss.islandLine, secondaryLineClassName)} />
          </div>
        </div>
      );
    case "box-center-vertical":
      return (
        <div className={cn(scss.boxWrapper, scss[variant], className)}>
          <div className={cn(scss.island)}>
            <div className={cn(scss.islandLine, mainLineClassname)} />
          </div>
          {children}
          <div className={cn(scss.island, scss.bottom)}>
            <div className={cn(scss.islandLine, secondaryLineClassName)} />
          </div>
        </div>
      );
    case "box-top":
      return (
        <div className={cn(scss.boxWrapper, scss[variant], className)}>
          <div className={cn(scss.island, scss.bottom)}>
            <div className={cn(scss.islandLine, mainLineClassname)} />
          </div>
          {children}
        </div>
      );
    case "box-bottom":
      return (
        <div className={cn(scss.boxWrapper, scss[variant], className)}>
          <div className={cn(scss.island)}>
            <div className={cn(scss.islandLine, mainLineClassname)} />
          </div>
          {children}
        </div>
      );
    default:
      return null;
  }
};

export default FigureBox;
