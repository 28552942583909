import React, { useCallback, useEffect, useRef } from "react";
import scss from "./styles.module.scss";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import resolutionStore from "../../../../../../store/ResolutionStore";

interface IProps {
  title?: string;
  sectionNumber: number;
  id: string;
  isActive?: boolean;
}

const NavMenuItem = ({ sectionNumber, title, id, isActive }: IProps) => {
  const section = useRef<HTMLDivElement | undefined>(undefined);
  const isLaptop = resolutionStore.isLaptop;

  useEffect(() => {
    section.current = document.getElementById(
      `section-${id}`
    ) as HTMLDivElement;
    // eslint-disable-next-line
  }, []);

  const clickHandler = useCallback(() => {
    const rect = section.current?.getBoundingClientRect();
    if (rect) {
      window.scrollBy({
        top: isLaptop ? rect.top - 72 : rect.top - 72 - 79,
        behavior: "smooth",
      });
    }
  }, [isLaptop]);

  return (
    <div
      onClick={clickHandler}
      className={cn(scss.container, { [scss.active]: isActive })}
    >
      {title}
      <span>{sectionNumber.toString().padStart(2, "0")}</span>
    </div>
  );
};

export default observer(NavMenuItem);
