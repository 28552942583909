import React from "react";
import { PageBlockReview } from "../../../../../api/contracts";
import LocationIcon from "../../../../../icons/LocationIcon";
import CalendarWithDays from "../../../../../icons/CalendarWithDays";
import { observer } from "mobx-react-lite";
import Button from "../../../../shared/Button/Button";
import resolutionStore from "../../../../../store/ResolutionStore";
import { getMonthDateRange } from "../../../../../utils/dateUtils";
import modalStore from "../../../../../store/ModalStore";
import BlockReviewModal from "../BlockReviewModal/BlockReviewModal";
import { nameAbbreviation } from "../../../../../utils/nameUtils";
import cn from "classnames";
import scss from "./styles.module.scss";

type Props = {
  review: PageBlockReview;
  reviews: PageBlockReview[];
  animation?: boolean;
};

const BlockReviewCard = ({ review, animation, reviews }: Props) => {
  const { phone, phoneLg, tablet } = resolutionStore.resolution;
  const isMobile = phone || phoneLg || tablet;

  const imageSrc = review.author.authorImg;

  const openModalHandler = () =>
    modalStore.openModal(
      <BlockReviewModal
        reviews={reviews}
        reviewIndex={reviews.findIndex((item) => item.id === review.id)}
      />,
      {
        modalClassName: scss.modal,
        overlayColor: "#31353de0",
      }
    );

  return (
    <div className={scss.reviewCardWrapper}>
      {isMobile && (
        <div className={scss.mobileLogoWrapper}>
          <div className={scss.mobileLogoInnerWrapper}>
            {review.author.authorImg && (
              <img
                src={review.author.authorImg}
                alt={review.author.authorName}
              />
            )}
          </div>
        </div>
      )}
      <div className={scss.contentWrapper}>
        <div
          className={cn(scss.imageWrapper, { [scss.showImage]: !animation })}
        >
          <div className={scss.imageInnerWrapper}>
            {!animation && (
              <img src={imageSrc} alt={review.author.authorName} />
            )}
          </div>
        </div>

        <h3 className={scss.reviewTitle}>{review.content.title}</h3>
        <p className={scss.reviewDescription}>
          {review.content.description[0]}
        </p>
        <Button className={scss.readMoreButton} onClick={openModalHandler}>
          read more
        </Button>
      </div>
      <div className={scss.authorInfoWrapper}>
        <div className={scss.authorInfoBlock}>
          <div className={scss.authorName}>
            {nameAbbreviation(review.author.authorName, 16)}
          </div>
          <div
            className={scss.authorRole}
          >{`${review.author.authorRole}, ${review.author.companyName}`}</div>
        </div>
        <div className={scss.authorInfoBlock}>
          <div className={scss.cooperationDates}>
            <CalendarWithDays />
            {getMonthDateRange(review.dateRange)}
          </div>
          <div className={scss.companyLocation}>
            <LocationIcon />
            {review.author.companyLocation}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(BlockReviewCard);
