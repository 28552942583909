import React, {
  HTMLAttributeAnchorTarget,
  MouseEvent,
  ReactNode,
  useMemo,
} from "react";
import { NavLink, To, useLocation } from "react-router-dom";
import cn from "classnames";
import scss from "./styles.module.scss";

type IButton = {
  type?: "button";
  disabled?: boolean;
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
};

type INavLink = {
  type?: "link";
  to: To;
  onClick?: (e?: MouseEvent<HTMLAnchorElement>) => void;
};

type INavExternalLink = {
  type?: "externalLink";
  href: string;
  onClick?: (e?: MouseEvent<HTMLAnchorElement>) => void;
  target?: HTMLAttributeAnchorTarget | undefined;
};

type IProps = {
  children: ReactNode;
  icon?: ReactNode;
  withoutIconMargin?: boolean;
  contentColor?: string;
  activeContentColor?: string;
  background?: string;
  disableDefaultHover?: boolean;
  gradientBackground?: boolean;
  className?: string;
  innerWrapperClassName?: string;
  activeClassname?: string;
  borderColor?: string;
  fullWidth?: boolean;
  paddings: "default" | "large" | "small";
  position:
    | "left-side"
    | "right-side"
    | "left-side-first"
    | "left-side-last"
    | "right-side-first"
    | "right-side-last"
    | "default"; // for "right-side" use  flex-direction: row-reverse
} & (IButton | INavLink | INavExternalLink);

const HeaderNavLink = (props: IProps) => {
  const { pathname } = useLocation();
  const isActive = useMemo(
    () =>
      props.type === "link" &&
      ((props.to === "/" && pathname === props.to) ||
        (props.to !== "/" && pathname.startsWith(props.to as string))),
    // eslint-disable-next-line
    [props.type === "link" && props.to, pathname]
  );

  const styles =
    props.borderColor &&
    ["left-side-last", "right-side-last"].includes(props.position)
      ? { borderRightColor: props.borderColor }
      : props.borderColor &&
        ["left-side-first", "right-side-first"].includes(props.position)
      ? { borderLeftColor: props.borderColor }
      : {};

  return props.type === "link" ? (
    <NavLink
      onClick={props.onClick}
      to={props.to}
      className={cn(
        scss.buttonWrapper,
        scss[props.position],
        scss[`${props.paddings}Paddings`],
        {
          [scss.gradientBackground]: isActive,
          [scss.withBackground]: props.background,
          [props.activeClassname as string]: isActive && props.activeClassname,
          [scss.fullWidth]: props.fullWidth,
          [scss.disabledDefaultHover]: props.disableDefaultHover,
        },
        props.className
      )}
      style={{
        color: isActive
          ? props.activeContentColor || props.contentColor
          : props.contentColor,
        ...styles,
      }}
    >
      <div
        className={cn(
          scss.button,
          { [scss.withBackground]: props.background },
          props.innerWrapperClassName
        )}
        style={{ background: props.background, borderColor: props.borderColor }}
      />
      {props.icon && (
        <div
          className={cn(scss.icon, {
            [scss.withoutMargin]: props.withoutIconMargin,
            [scss.iconWithFullWidth]: props.fullWidth,
          })}
        >
          {props.icon}
        </div>
      )}
      <div
        className={cn(scss.content, {
          [scss.contentWithFullWidth]: props.fullWidth,
          [scss.withIcon]: props.icon,
        })}
      >
        {props.children}
      </div>
    </NavLink>
  ) : props.type === "externalLink" ? (
    <a
      onClick={props.onClick}
      target={props.target}
      href={props.href}
      className={cn(
        scss.buttonWrapper,
        scss[props.position],
        scss[`${props.paddings}Paddings`],
        {
          [scss.gradientBackground]: isActive,
          [scss.withBackground]: props.background,
          [props.activeClassname as string]: isActive && props.activeClassname,
          [scss.fullWidth]: props.fullWidth,
          [scss.disabledDefaultHover]: props.disableDefaultHover,
        },
        props.className
      )}
      style={{
        color: isActive
          ? props.activeContentColor || props.contentColor
          : props.contentColor,
        ...styles,
      }}
    >
      <div
        className={cn(
          scss.button,
          { [scss.withBackground]: props.background },
          props.innerWrapperClassName
        )}
        style={{ background: props.background, borderColor: props.borderColor }}
      />
      {props.icon && (
        <div
          className={cn(scss.icon, {
            [scss.withoutMargin]: props.withoutIconMargin,
            [scss.iconWithFullWidth]: props.fullWidth,
          })}
        >
          {props.icon}
        </div>
      )}
      <div
        className={cn(scss.content, {
          [scss.contentWithFullWidth]: props.fullWidth,
          [scss.withIcon]: props.icon,
        })}
      >
        {props.children}
      </div>
    </a>
  ) : props.type === "button" ? (
    <button
      onClick={props.onClick}
      className={cn(
        scss.buttonWrapper,
        scss.cursor,
        scss[props.position],
        scss[`${props.paddings}Paddings`],
        {
          [scss.gradientBackground]: props.gradientBackground,
          [scss.withBackground]: props.background,
          [scss.fullWidth]: props.fullWidth,
          [scss.disabledDefaultHover]: props.disableDefaultHover,
        },
        props.className
      )}
      style={{ color: props.contentColor, ...styles }}
    >
      <div
        className={cn(
          scss.button,
          { [scss.withBackground]: props.background },
          props.innerWrapperClassName
        )}
        style={{ background: props.background, borderColor: props.borderColor }}
      />
      {props.icon && (
        <div
          className={cn(scss.icon, {
            [scss.withoutMargin]: props.withoutIconMargin,
            [scss.iconWithFullWidth]: props.fullWidth,
          })}
        >
          {props.icon}
        </div>
      )}
      <div
        className={cn(scss.content, {
          [scss.contentWithFullWidth]: props.fullWidth,
          [scss.withIcon]: props.icon,
        })}
      >
        {props.children}
      </div>
    </button>
  ) : null;
};

HeaderNavLink.defaultProps = {
  type: "link",
  position: "default",
  paddings: "default",
};

export default HeaderNavLink;
