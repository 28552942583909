import cn from "classnames";
import { useEffect, useRef } from "react";
import scss from "./styles.module.scss";

type Props = {
  setIndex: (number: number) => void;
  title: string;
  description?: string;
  active: boolean;
  index: number;
  position: "vertical" | "horizontal";
};
const WorkflowTab = (props: Props) => {
  const tabRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (descriptionRef.current && props.position === "vertical") {
      if (props.active) {
        descriptionRef.current.style.maxHeight =
          descriptionRef.current.scrollHeight + "px";
      } else {
        descriptionRef.current.style.maxHeight = "0";
      }
    }
  }, [props.active, props.position]);

  return (
    <div
      ref={tabRef}
      onClick={() => {
        if (props.position === "horizontal") {
          tabRef.current?.scrollIntoView({
            block: "nearest",
            inline: "center",
            behavior: "smooth",
          });
        }
        props.setIndex(props.index);
      }}
      className={cn(
        scss.tab,
        { [scss.active]: props.active },
        scss[props.position]
      )}
    >
      <div className={scss.tabContent}>
        <div className={scss.tabTitle}>{props.title}</div>
        <div ref={descriptionRef} className={scss.tabDescription}>
          {props.description}
        </div>
      </div>
    </div>
  );
};

export default WorkflowTab;
