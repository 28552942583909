import React, { useEffect, useState } from "react";
import PostCard from "../../../PostCard/PostCard";
import { observer } from "mobx-react-lite";
import resolutionStore from "../../../../../../../store/ResolutionStore";
import cn from "classnames";
import useResizeObserver from "../../../../../../../hooks/useResizeObserver";
import { PageBlockPost } from "../../../../../../../api/contracts";
import scss from "./styles.module.scss";

interface IProps {
  posts: PageBlockPost[];
}

const PostsGridLayout = ({ posts }: IProps) => {
  const [height, setHeight] = useState<number>(0);
  const isLaptop = resolutionStore.isLaptop;
  const isMobileOrTablet = resolutionStore.isMobileOrTablet;
  const smallTilesOnly = !isLaptop;

  const { containerRef, containerSize } = useResizeObserver();

  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current) setHeight(containerSize.height);
    }, 0);
  }, [containerRef, posts.length, containerSize.height]);

  return (
    <div className={scss.wrapper} style={{ height: height || undefined }}>
      <div ref={containerRef} className={cn(scss.gridContainer)}>
        {posts.map((item, i) => (
          <PostCard
            mountWithAnimate
            index={i}
            key={item.id + item.slug}
            postInfo={item}
            type={
              !isMobileOrTablet
                ? [2, 3, 8, 13, 14, 17, 20, 25, 26, 29].includes(i) &&
                  !smallTilesOnly
                  ? "medium"
                  : "small"
                : "default"
            }
          />
        ))}
      </div>
    </div>
  );
};

export default observer(PostsGridLayout);
