import React from "react";
import cn from "classnames";
import TelegramIcon from "../../../icons/TelegramIcon";
import scss from "./styles.module.scss";

type Props = {
  size?: number;
};

const Telegram = ({ size }: Props) => {
  return (
    <div
      className={cn(scss.icon, scss.telegram)}
      style={{ width: size, height: size }}
    >
      <TelegramIcon />
    </div>
  );
};

export default Telegram;
