import React, { CSSProperties } from "react";
import BreadCrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";
import { observer } from "mobx-react-lite";
import resolutionStore from "../../../../store/ResolutionStore";
import scss from "./styles.module.scss";

const TeamHeaderSection = () => {
  const resolution = resolutionStore.resolutionState;
  const style = {
    backgroundImage: resolution.isMobile
      ? "url(/assets/ourTeamSm.webp)"
      : resolution.isTablet
      ? "url(/assets/ourTeamMd.webp)"
      : "url(/assets/ourTeamLarge.webp)",
  } as CSSProperties;
  return (
    <div className={scss.mainSectionWrapper}>
      <BreadCrumbs pageTitle="Our Team" textColor="var(--white)" />
      <div style={style} className={scss.contentWrapper}>
        <p className={scss.mainSectionDescription}>
          Imagine if you gathered the coolest colleagues you know into a team
        </p>
        <h1 className={scss.mainSectionTitle}>That's exactly what we did</h1>
      </div>
      <div
        style={{ backgroundImage: "url(/assets/crops.webp)" }}
        className={scss.cropsWrapper}
      />
    </div>
  );
};

export default observer(TeamHeaderSection);
