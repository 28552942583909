import preloader from "../store/Preloader";
import { useRef } from "react";

const usePreloader = () => {
  return useRef((show: boolean, type: "GLOBAL" | "PAGE" = "PAGE") => {
    if (show !== preloader._show)
      preloader.showPreloader(show, show ? type : null);
  }).current;
};

export default usePreloader;
