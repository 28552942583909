import { memo, SVGProps } from "react";

const BenzingaLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="174"
      height="56"
      viewBox="0 0 174 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.2443 38.817H42.4905V34.6284H31.1685V29.4893H40.5495V25.3007H31.1685V20.5488H42.5623V16.3602H26.2443V38.817ZM62.0433 30.5101L51.4042 16.3602H44.0719L46.3723 19.2113V38.8874H51.2605V24.1391L62.2589 38.817H66.9674V16.3602H62.0433V30.5101ZM18.8401 27.2718C20.9967 26.8846 22.7938 24.9135 22.7938 22.1328C22.7938 19.1409 20.5654 16.3602 16.1444 16.3602H1.91113L4.13958 19.1409V38.8522H16.6476C21.0686 38.8522 23.3689 36.1419 23.3689 32.7628C23.2611 29.9821 21.3202 27.5886 18.8401 27.2718ZM9.02779 20.584H15.174C16.8274 20.584 17.8697 21.5344 17.8697 22.9423C17.8697 24.3503 16.8274 25.3007 15.174 25.3007H9.02779V20.584ZM15.3896 34.6636H9.02779V29.4893H15.3896C17.2587 29.4893 18.301 30.6509 18.301 32.0588C18.301 33.678 17.1868 34.6284 15.3896 34.6636ZM138.781 30.5101H141.333V33.15C140.003 34.206 138.35 34.8043 136.624 34.8395C132.527 34.8395 129.652 31.7772 129.652 27.659C129.652 23.5407 132.563 20.4784 136.624 20.4784C138.745 20.4784 140.722 21.5696 141.872 23.3295L145.754 21.2176C144.101 18.6481 141.189 16.3602 136.588 16.3602C130.083 16.3602 124.691 20.76 124.691 27.5886C124.691 34.4172 130.011 38.817 136.588 38.817C140.183 38.8874 143.597 37.3739 145.97 34.7691V26.4622H135.474L138.781 30.5101ZM116.676 30.5101L106.001 16.3602H98.7048L100.969 19.2113V38.8874H105.893V24.1391L116.892 38.817H121.564V16.3602H116.676V30.5101ZM162.216 16.3602H152.763L155.027 19.2113L154.632 20.3024L147.299 38.8874H152.835L154.272 35.0859H164.157L165.594 38.8874H171.129L162.216 16.3602ZM155.53 30.8621L159.125 21.1824L162.719 30.8621H155.53ZM90.9412 16.3602H95.8654V38.9226H90.9412V16.3602ZM87.0235 20.232V16.3602H69.735V20.5488H80.5897L69.735 34.9803V38.817H87.2032V34.6284H76.0969L87.0235 20.232Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(BenzingaLogo);
