import { memo, SVGProps } from "react";

const HeartIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.11254e-07 7.80094C-0.000347807 5.70709 0.815339 3.70102 2.26354 2.23409C3.71174 0.767156 5.67082 -0.0374358 7.7 0.00133927C10.1043 -0.0118334 12.3984 1.04009 14 2.89008C15.6016 1.04009 17.8957 -0.0118334 20.3 0.00133927C22.3292 -0.0374358 24.2883 0.767156 25.7365 2.23409C27.1847 3.70102 28.0003 5.70709 28 7.80094C28 15.537 19.0694 21.378 14 26C8.9418 21.339 1.11254e-07 15.5428 1.11254e-07 7.80094Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(HeartIcon);
