import { CSSProperties, ReactNode } from "react";
import ArrowsDownIcon from "../../../../icons/ArrowsDownIcon";
import scss from "./styles.module.scss";

type Props = {
  accentColor: string;
  title: string;
  label: string;
  comment: ReactNode;
  author: string;
  image: string;
  steps: string[];
  icon: ReactNode;
};
const WorkflowStage = (props: Props) => {
  const variables = {
    "--stage-primary-color": props.accentColor,
  } as CSSProperties;

  return (
    <div style={variables} className={scss.section}>
      <div className={scss.header}>
        <div className={scss.headerIcon}>{props.icon}</div>
        <div className={scss.headerTitle}>{props.title}</div>
        <div className={scss.headerLabel}>{props.label}</div>
      </div>
      <div className={scss.stage}>
        <div className={scss.contentLine} />
        <div className={scss.contentSide}>
          <img className={scss.image} src={props.image} alt={props.title} />
          <div className={scss.comment}>
            <img
              className={scss.commentAuthor}
              src={props.author}
              alt="author"
            />
            <div className={scss.commentText}>{props.comment}</div>
          </div>
          <div className={scss.caption}>
            <span className={scss.captionText}>
              at this stage, we are working on
            </span>
            <div className={scss.captionIcon}>
              <ArrowsDownIcon />
            </div>
          </div>
        </div>
      </div>
      {props.steps.map((text, i) => (
        <div className={scss.step} key={i}>
          <span className={scss.stepText}>{text}</span>
        </div>
      ))}
    </div>
  );
};

export default WorkflowStage;
