import { memo, SVGProps } from "react";

const LocationIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00016 14C7.15812 13.2818 6.37762 12.4944 5.66683 11.646C4.60016 10.372 3.3335 8.4747 3.3335 6.6667C3.33255 4.7784 4.46966 3.07563 6.21421 2.35297C7.95876 1.6303 9.96687 2.03019 11.3015 3.36603C12.1791 4.23977 12.6708 5.42829 12.6669 6.6667C12.6669 8.4747 11.4002 10.372 10.3335 11.646C9.62271 12.4944 8.84221 13.2818 8.00016 14ZM8.00016 4.6667C7.28563 4.6667 6.62538 5.0479 6.26811 5.6667C5.91085 6.2855 5.91085 7.0479 6.26811 7.6667C6.62538 8.2855 7.28563 8.6667 8.00016 8.6667C9.10473 8.6667 10.0002 7.77127 10.0002 6.6667C10.0002 5.56213 9.10473 4.6667 8.00016 4.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(LocationIcon);
