import React, { useCallback, useMemo, useState } from "react";
import usePagination from "../../../hooks/usePagination";
import { observer } from "mobx-react-lite";
import resolutionStore from "../../../store/ResolutionStore";
import Pagination from "../../Pagination/Pagination";
import { CSSTransition } from "react-transition-group";
import Button from "../../shared/Button/Button";
import MobileLastItem from "../../shared/MobileLastItem/MobileLastItem";
import HeartIcon from "../../../icons/HeartIcon";
import { PageBlockStories } from "../../../api/contracts";
import modalStore from "../../../store/ModalStore";
import StoriesModal from "./components/StoriesModal/StoriesModal";
import VideoCoverItem from "./components/VideoCoverItem/VideoCoverItem";
import scss from "./styles.module.scss";

const endStoryBackground = require("../../../assets/images/storyBackground.webp");

interface IProps {
  block: PageBlockStories;
}

const BlockStories = ({ block }: IProps) => {
  const [animation, setAnimation] = useState(false);
  const isMobileOrTablet = resolutionStore.isMobileOrTablet;
  const { desktopXlg, desktopCLg } = resolutionStore.resolution;

  const contentPerPage = isMobileOrTablet
    ? block.stories.length
    : desktopXlg || desktopCLg
    ? 4
    : 3;

  const { setPage, totalPages, lastContentIndex, firstContentIndex, tempPage } =
    usePagination({
      contentPerPage,
      count: block.stories.length,
      seeAllItems: isMobileOrTablet,
    });

  const stories = useMemo(() => {
    return block.stories.slice(firstContentIndex, lastContentIndex);
  }, [firstContentIndex, lastContentIndex, block.stories]);

  const setPageHandler = useCallback(
    (pageNumber: number) => () => {
      setAnimation(true);
      setTimeout(() => {
        requestAnimationFrame(() => {
          setPage(pageNumber);
          setAnimation(false);
        });
      }, 250);
    },
    [setPage]
  );

  const itemClickHandler = (id: string) => () => {
    modalStore.openModal(
      <StoriesModal stories={block.stories} activeId={id} />,
      {
        overlayColor: "rgba(0,0,0, 0.8)",
        overlayClassName: scss.overlay,
      }
    );
  };

  return (
    <>
      <CSSTransition in={animation} timeout={500} classNames="fade-short">
        <div className={scss.storiesBlockContentGrid}>
          {stories.map((story) => (
            <VideoCoverItem
              story={story}
              key={story.id}
              onClick={itemClickHandler(story.id)}
            />
          ))}
          {isMobileOrTablet && (
            <MobileLastItem
              title="Want see more?"
              description="We have collected all the stories in one place to make it easier for you to use them"
              icon={<HeartIcon />}
              buttonText="more stories"
              backgroundImage={endStoryBackground}
            />
          )}
        </div>
      </CSSTransition>
      {!isMobileOrTablet && (
        <div className={scss.storiesBlockActionsWrapper}>
          <Pagination
            isPaginationButtons
            changePageHandler={setPageHandler}
            currentPage={tempPage}
            pagesCount={totalPages}
            withPaddings={false}
            className={scss.storiesBlockPaginationButton}
          />

          <Button className={scss.storiesBlockShowMoreButton}>
            Watch more stories
          </Button>
        </div>
      )}
    </>
  );
};

export default observer(BlockStories);
