import { memo, SVGProps } from "react";

const WhatsAppIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="80" height="80" rx="40" fill="#67AD5B" />
      <g clipPath="url(#clip0_4119_49821)">
        <path
          d="M40.005 20H39.995C28.9675 20 20 28.97 20 40C20 44.375 21.41 48.43 23.8075 51.7225L21.315 59.1525L29.0025 56.695C32.165 58.79 35.9375 60 40.005 60C51.0325 60 60 51.0275 60 40C60 28.9725 51.0325 20 40.005 20ZM51.6425 48.2425C51.16 49.605 49.245 50.735 47.7175 51.065C46.6725 51.2875 45.3075 51.465 40.7125 49.56C34.835 47.125 31.05 41.1525 30.755 40.765C30.4725 40.3775 28.38 37.6025 28.38 34.7325C28.38 31.8625 29.8375 30.465 30.425 29.865C30.9075 29.3725 31.705 29.1475 32.47 29.1475C32.7175 29.1475 32.94 29.16 33.14 29.17C33.7275 29.195 34.0225 29.23 34.41 30.1575C34.8925 31.32 36.0675 34.19 36.2075 34.485C36.35 34.78 36.4925 35.18 36.2925 35.5675C36.105 35.9675 35.94 36.145 35.645 36.485C35.35 36.825 35.07 37.085 34.775 37.45C34.505 37.7675 34.2 38.1075 34.54 38.695C34.88 39.27 36.055 41.1875 37.785 42.7275C40.0175 44.715 41.8275 45.35 42.475 45.62C42.9575 45.82 43.5325 45.7725 43.885 45.3975C44.3325 44.915 44.885 44.115 45.4475 43.3275C45.8475 42.7625 46.3525 42.6925 46.8825 42.8925C47.4225 43.08 50.28 44.4925 50.8675 44.785C51.455 45.08 51.8425 45.22 51.985 45.4675C52.125 45.715 52.125 46.8775 51.6425 48.2425Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4119_49821">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(20 20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(WhatsAppIcon);
