import React, { useState } from "react";
import cn from "classnames";
import { TeamMemberDto } from "../../../../api/contracts";
import Section from "../../../../components/shared/Section/Section";
import HeaderNavLink from "../../../../components/Header/components/HeaderNavLink/HeaderNavLink";
import MailIcon from "../../../../icons/MailIcon";
import PhoneIcon from "../../../../icons/PhoneIcon";
import TeamMemberFeature from "./components/TeamMemberFeature/TeamMemberFeature";
import TeamMemberSkills from "./components/TeamMemberSkills/TeamMemberSkills";
import { CSSTransition } from "react-transition-group";
import TeamMembersList from "./components/TeamMembersList/TeamMembersList";
import { observer } from "mobx-react-lite";
import resolutionStore from "../../../../store/ResolutionStore";
import useOpenContactUs from "../../../../hooks/useOpenContactUs";
import scss from "./styles.module.scss";

interface IProps {
  team: TeamMemberDto[];
}

const TeamMembersSection = ({ team }: IProps) => {
  const [active, setActive] = useState<number>(0);
  const [animate, setAnimate] = useState(false);
  const isMobile = resolutionStore.isMobile;
  const animationDuration = 600;

  const openContactUsModal = useOpenContactUs();

  return (
    <Section
      title="Meet the Umber Team"
      subtitle="No need to wait for the intro call, you are already on it"
      className={scss.sectionWrapper}
    >
      <div className={scss.container}>
        <CSSTransition
          in={animate}
          timeout={animationDuration}
          classNames="fade"
        >
          <div
            className={scss.backgroundWrapper}
            style={{
              backgroundImage: ` url(${team[active].backgroundImage})`,
            }}
          />
        </CSSTransition>
        <div className={scss.mainBlock}>
          <div className={scss.contentWrapper}>
            <CSSTransition
              in={animate}
              timeout={animationDuration}
              classNames="transform-x"
            >
              <div>
                <h3 className={scss.teamMemberRole}>{team[active].fullRole}</h3>
                <h2 className={scss.teamMemberName}>
                  {team[active].firstName}
                </h2>
              </div>
            </CSSTransition>
            <CSSTransition
              in={animate}
              timeout={animationDuration}
              classNames="transform-x-50"
            >
              <div className={scss.featuresWrapper}>
                <div className={cn(scss.features, "hiddenScroll")}>
                  {team[active].description.map((feature, i) => (
                    <TeamMemberFeature
                      key={i}
                      title={feature.title}
                      text={feature.text}
                      iconColor={
                        i === 0
                          ? "var(--green-2)"
                          : i === 1
                          ? "var(--blue-4)"
                          : i === 2
                          ? "var(--orange-1)"
                          : "var(--grey-4)"
                      }
                    />
                  ))}
                </div>
              </div>
            </CSSTransition>
            <CSSTransition
              in={animate}
              timeout={animationDuration}
              classNames="transform-x-100"
            >
              <TeamMemberSkills
                skills={team[active].skills}
                maxSkillsViewCount={3}
              />
            </CSSTransition>
          </div>
          <div className={scss.contacts}>
            <HeaderNavLink
              type="externalLink"
              target="_blank"
              href="https://calendly.com/umber-art/30min"
              fullWidth={isMobile}
              // background={"var(--green-2)"}
              contentColor={"var(--dark-2)"}
              position={"left-side-first"}
              icon={<PhoneIcon />}
              className={scss.callButton}
              innerWrapperClassName={scss.callButtonInner}
              disableDefaultHover
            >
              {isMobile ? "call" : "Schedule a call"}
            </HeaderNavLink>
            <HeaderNavLink
              fullWidth={isMobile}
              type="button"
              contentColor={"var(--green-2)"}
              borderColor={"var(--green-2)"}
              position={"left-side"}
              icon={<MailIcon />}
              onClick={openContactUsModal}
            >
              {isMobile ? "write" : "write to us"}
            </HeaderNavLink>
          </div>
          <div className={scss.memberImageContainer}>
            <CSSTransition
              in={animate}
              timeout={animationDuration}
              classNames="transform-scale"
            >
              <img
                className={scss.memberImage}
                src={team[active].photo}
                alt={team[active].firstName}
              />
            </CSSTransition>
          </div>
          <TeamMembersList
            team={team}
            activeIndex={active}
            setAnimate={setAnimate}
            setActive={setActive}
            classname={scss.teamMembersList}
            listMaxHeight={627}
          />
        </div>
      </div>
    </Section>
  );
};

export default observer(TeamMembersSection);
