import React from "react";
import BreadCrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";
import scss from "./styles.module.scss";

const BlogMainSection = () => {
  return (
    <div
      className={scss.container}
      style={{
        backgroundImage: "url(/assets/blogMainSectionBackground.webp)",
      }}
    >
      <BreadCrumbs />
      <div className={scss.contentWrapper}>
        <h1 className={scss.title}>blog</h1>
        <p className={scss.description}>
          Here you can find out more information about design, technology and
          business that we have collected from personal experience.
        </p>
      </div>
    </div>
  );
};

export default BlogMainSection;
