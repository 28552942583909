import React from "react";
import WorkflowTabs from "../WorkflowTabs/WorkflowTabs";
import { PageSidebar, PageWidget } from "../../../../api/contracts";
import PageLayoutSidebar from "../../../../components/Layouts/PageLayoutSidebar/PageLayoutSidebar";
import scss from "./styles.module.scss";

type Props = {
  index: number;
  setIndex: (index: number) => void;
  widgets: PageWidget[];
  sidebar: PageSidebar;
};
const Sidebar = (props: Props) => {
  return (
    <PageLayoutSidebar
      widgets={props.widgets}
      sidebar={props.sidebar}
      beforeWidgets={
        <>
          <WorkflowTabs
            position="vertical"
            activeIndex={props.index}
            setIndex={props.setIndex}
          />
          <div className={scss.divider} />
        </>
      }
    />
  );
};

export default Sidebar;
