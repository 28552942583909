import React from "react";
import scss from "./styles.module.scss";

const WorkflowBox = () => {
  return (
    <div className={scss.container}>
      <div className={scss.box}>
        <img src="/assets/workflow-box.webp" alt="workflow-box" />
      </div>
      <div className={scss.glass}>
        <img src="/assets/workflow-glass.webp" alt="workflow-glass" />
      </div>
      <div className={scss.items}>
        <div className={scss.item}>
          <img
            className={scss.itemImg}
            src="/assets/workflow-health-care.webp"
            alt="health care"
          />
        </div>
        <div className={scss.item}>
          <img
            className={scss.itemImg}
            src="/assets/workflow-finance.webp"
            alt="finance"
          />
        </div>
        <div className={scss.item}>
          <img
            className={scss.itemImg}
            src="/assets/workflow-trading.webp"
            alt="trading"
          />
        </div>
        <div className={scss.item}>
          <img
            className={scss.itemImg}
            src="/assets/workflow-corporate.webp"
            alt="corporate"
          />
        </div>
      </div>
    </div>
  );
};

export default WorkflowBox;
