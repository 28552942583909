import React from "react";
import cn from "classnames";
import Button from "../shared/Button/Button";
import { createPaginationArray } from "./createPaginationArray";
import ArrowChevronLeft from "../../icons/ArrowChevronLeft";
import ArrowChevronRight from "../../icons/ArrowChevronRight";
import scss from "./styles.module.scss";

interface IProps {
  pagesCount: number;
  currentPage: number;
  changePageHandler?: (pageNumber: number) => () => void;
  showMoreHandler?: () => void;
  withPaddings?: boolean;
  className?: string;
  nextPrevButtons?: boolean;
  isShowMore?: boolean;
  isPaginationButtons?: boolean;
  showMoreButtonFullWidth?: boolean;
}

const Pagination = ({
  pagesCount,
  currentPage,
  changePageHandler,
  showMoreHandler,
  withPaddings = true,
  className,
  nextPrevButtons,
  isPaginationButtons,
  isShowMore,
  showMoreButtonFullWidth,
}: IProps) => {
  const pagesArray = createPaginationArray(currentPage, pagesCount);

  return (
    <div
      className={cn(scss.paginationContainer, {
        [scss.withPaddings]: withPaddings,
      })}
    >
      {isShowMore && showMoreHandler ? (
        <Button
          className={scss.showMore}
          fullWidth={showMoreButtonFullWidth}
          onClick={showMoreHandler}
          variant="outline"
          disabled={currentPage >= pagesCount}
        >
          SHOW MORE
        </Button>
      ) : null}
      {isPaginationButtons
        ? pagesArray.map((el, i) => (
            <Button
              disabled={currentPage === el.value || el.type === "dot"}
              className={cn(
                scss.paginationButton,
                {
                  [scss.active]: currentPage === el.value,
                  [scss.disabled]:
                    currentPage === el.value || el.type === "dot",
                },
                className
              )}
              key={i}
              onClick={
                changePageHandler && el.type !== "dot"
                  ? changePageHandler(el.value as number)
                  : undefined
              }
            >
              {el.value}
            </Button>
          ))
        : null}
      {nextPrevButtons && (
        <div className={scss.prevNextButtonsContainer}>
          <Button
            aria-label="Button Prev"
            className={cn(
              scss.paginationButton,
              scss.prevNextButton,
              className
            )}
            onClick={
              changePageHandler
                ? changePageHandler(
                    currentPage === 1 ? pagesCount : currentPage - 1
                  )
                : undefined
            }
          >
            <ArrowChevronLeft />
          </Button>
          <Button
            aria-label="Button Next"
            className={cn(
              scss.paginationButton,
              scss.prevNextButton,
              className
            )}
            onClick={
              changePageHandler
                ? changePageHandler(
                    currentPage === pagesCount ? 1 : currentPage + 1
                  )
                : undefined
            }
          >
            <ArrowChevronRight />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Pagination;
