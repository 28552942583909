import React, { useEffect, useRef } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import PostStatistics from "./components/PostStatistics/PostStatistics";
import { PageBlockPost } from "../../../../../api/contracts";
import scss from "./styles.module.scss";

interface IProps {
  type?: "small" | "medium" | "big" | "default";
  postInfo: PageBlockPost;
  index?: number;
  mountWithAnimate?: boolean;
  className?: string;
  theme: "dark" | "light";
}

const PostCard = ({
  type,
  postInfo,
  index,
  mountWithAnimate,
  className,
  theme,
}: IProps) => {
  const mount = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const clickHandler = () => {
    navigate(`/blog/${postInfo.slug}`);
  };

  useEffect(() => {
    if (mountWithAnimate) {
      const timeout = setTimeout(() => {
        ref.current?.classList.add(scss.show);
        mount.current = true;
      }, (index || 0) * 25);

      return () => {
        clearTimeout(timeout);
      };
    }

    // eslint-disable-next-line
  }, []);

  switch (type) {
    case "big":
      return (
        <div
          onClick={clickHandler}
          className={cn(scss.bigPostWrapper, { [scss.dark]: theme === "dark" })}
        >
          <div className={scss.bigPostContentWrapper}>
            <div className={scss.bigPostTagsWrapper}>
              {postInfo?.tags?.map((tag) => (
                <span className={scss.bigPostTag} key={tag.id}>
                  {tag.title}
                </span>
              ))}
            </div>
            <h1 className={scss.bigPostTitle}>{postInfo.title}</h1>
            <p className={scss.bigPostDescription}>{postInfo.description}</p>
            {postInfo.postStatistics && (
              <PostStatistics statisticsInfo={postInfo.postStatistics} />
            )}
          </div>
          <div className={scss.bigPostImageWrapper}>
            <img
              className={scss.bigPostMainImage}
              src={postInfo.preview}
              alt="img1"
            />
          </div>
        </div>
      );
    case "medium":
      return (
        <div
          onClick={clickHandler}
          ref={ref}
          className={cn(
            scss.container,
            scss.mediumPostWrapper,
            {
              [scss.show]: mount.current || !mountWithAnimate,
              [scss.dark]: theme === "dark",
            },
            className
          )}
        >
          <p className={scss.mediumPostTitleTag}>
            {postInfo?.tags?.[0]?.title}
          </p>
          <div className={scss.mediumPostTitleWrapper}>
            <p className={scss.mediumPostTitle}>{postInfo?.title}</p>
          </div>
          <div className={scss.mediumPostImageContainer}>
            <img
              className={scss.mediumPostImage}
              src={postInfo.preview}
              alt={postInfo.title}
            />
          </div>
        </div>
      );
    case "small":
      return (
        <div
          onClick={clickHandler}
          ref={ref}
          className={cn(
            scss.container,
            scss.smallPostWrapper,
            {
              [scss.show]: mount.current || !mountWithAnimate,
              [scss.dark]: theme === "dark",
            },
            className
          )}
        >
          <div className={scss.smallPostTag}>{postInfo?.tags?.[0]?.title}</div>
          <div className={scss.smallPostImageContainer}>
            <img
              className={scss.smallPostImage}
              src={postInfo.previewMobile}
              alt={postInfo.title}
            />
          </div>
          <div className={scss.smallPostTitle}>{postInfo?.title}</div>
        </div>
      );
    default:
      return (
        <div
          onClick={clickHandler}
          ref={ref}
          className={cn(
            scss.container,
            scss.defaultPostWrapper,
            {
              [scss.show]: mount.current || !mountWithAnimate,
              [scss.dark]: theme === "dark",
            },
            className
          )}
        >
          <div className={scss.defaultPostImageContainer}>
            <img
              className={scss.defaultPostImage}
              src={postInfo.preview}
              alt={postInfo.title}
            />
          </div>
          <div className={scss.defaultPostTitleWrapper}>
            <p className={scss.defaultPostTitleTag}>
              {postInfo?.tags?.[0]?.title}
            </p>
            <p className={scss.defaultPostTitle}>{postInfo?.title}</p>
          </div>
        </div>
      );
  }
};

PostCard.defaultProps = {
  type: "small",
  theme: "light",
};

export default PostCard;
