import { memo, SVGProps } from "react";

const HummerIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.1">
        <path
          d="M-7.98506 60.5C-6.57981 60.5 -5.25706 59.9527 -4.26293 58.9572L26.0558 28.6385L18.6116 21.1942L-11.7072 51.5116C-15.0209 54.6865 -12.5858 60.5935 -7.98506 60.5Z"
          fill="#151922"
        />
        <path
          d="M27.5972 5.90287C27.3387 5.64437 26.9895 5.49999 26.6251 5.49999H10.1251C8.94262 5.46424 8.28124 7.03312 9.15299 7.84712C17.2545 15.9417 28.5886 27.2882 36.653 35.3471C37.1906 35.8847 38.0596 35.8847 38.5972 35.3471L46.8472 27.0971C47.3794 26.5842 47.3807 25.6644 46.8472 25.1529L27.5972 5.90287Z"
          fill="#151922"
        />
      </g>
    </svg>
  );
};

export default memo(HummerIcon);
