import React from "react";
import BreadCrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";
import WorkflowBox from "./WorkflowBox/WorkflowBox";
import scss from "./styles.module.scss";

const WorkflowHeaderSection = () => {
  return (
    <div
      className={scss.section}
      style={{ backgroundImage: "url(/assets/workflow-bg.webp)" }}
    >
      <BreadCrumbs pageTitle="Workflow" textColor="var(--white)" />
      <div className={scss.titleWrapper}>
        <h1 className={scss.title}>Build a product</h1>
      </div>
      <div className={scss.description}>
        We'll be your sidekick, using our superpowers to guide you through the
        product development process
      </div>
      <div
        className={scss.grid}
        style={{ backgroundImage: "url(/assets/workflow-grid.webp)" }}
      >
        <WorkflowBox />
      </div>
    </div>
  );
};

export default WorkflowHeaderSection;
