import React, { CSSProperties, useMemo } from "react";
import cn from "classnames";
import { PageSection } from "../../../../api/contracts";
import ContentSectionBlocksWrapper from "./components/ContentSectionBlocksWrapper/ContentSectionBlocksWrapper";
import { SectionContextProvider } from "../../../../context/SectionContext";
import scss from "./styles.module.scss";

type Props = {
  flexOrder?: number;
  sectionNumber?: number;
  borderColor?: string;
  className?: string;
  pageWithSidebar?: boolean;
  section: PageSection;
};

const Section = (props: Props) => {
  const style = useMemo(
    () =>
      ({
        "--section-border-color": props.borderColor,
        order: props.flexOrder,
      } as CSSProperties),
    [props.borderColor, props.flexOrder]
  );

  return (
    <SectionContextProvider section={props.section}>
      <div
        id={`section-${props.section.id}`}
        className={cn(
          scss.section,
          scss[`view-${props.section.horizontalViewType}`],
          {
            [scss.withoutPaddings]: props.section.withoutPaddings,
          }
        )}
      >
        <div
          className={cn(
            scss.container,
            props.className,
            scss[`margin-top-${props.section.marginTop}`],
            scss[`margin-bottom-${props.section.marginBottom}`],
            {
              [scss.withoutPaddings]: props.section.withoutPaddings,
              [scss.withoutBackground]: props.section.withoutBackground,
              [scss.border]: props.section.border,
            }
          )}
          style={style}
        >
          {(props.section.caption ||
            (!props.section.titleHidden && props.section.title) ||
            props.section.description) && (
            <div className={scss.header}>
              {props.section.caption && (
                <p className={scss.caption}>{props.section.caption}</p>
              )}
              {!props.section.titleHidden && props.section.title && (
                <h2
                  className={cn(
                    scss.title,
                    scss[`${props.section.titleType}Title`]
                  )}
                  style={{ maxWidth: props.section.titleMaxWidth }}
                >
                  {props.section.title}
                </h2>
              )}
              {props.section.description && (
                <>
                  {props.section.description.map((item, i) => (
                    <p key={i} className={scss.description}>
                      {item.title}
                    </p>
                  ))}
                </>
              )}
            </div>
          )}

          {props.section.blocks && (
            <div className={scss.blocks}>
              <div className={scss.blocksInner}>
                <ContentSectionBlocksWrapper
                  blocks={props.section.blocks}
                  pageWithSidebar={props.pageWithSidebar}
                  withoutPaddings={props.section.withoutPaddings}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </SectionContextProvider>
  );
};

export default Section;

Section.defaultProps = {
  titleType: "default",
};
