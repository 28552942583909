import { memo, SVGProps } from "react";

const DribbbleLogoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.1006 8.86811C10.7831 10.6006 11.2906 12.3856 11.6231 14.2231C12.0956 13.9431 12.5331 13.5931 12.9356 13.1906C13.9856 12.1406 14.6506 10.8281 14.8956 9.41061C13.8631 8.99061 12.7431 8.74561 11.5706 8.74561C11.0806 8.74561 10.5906 8.7806 10.1006 8.86811Z"
        fill="#D85888"
      />
      <path
        d="M12.9528 3.30328C12.9528 3.28578 12.9528 3.28578 12.9528 3.30328C11.6228 1.97328 9.87282 1.23828 8.00031 1.23828C7.26531 1.23828 6.54781 1.34328 5.88281 1.57078C6.96781 2.93578 7.93031 4.38828 8.73531 5.91079C10.2753 5.26329 11.6928 4.37078 12.9528 3.30328Z"
        fill="#D85888"
      />
      <path
        d="M13.5126 3.91553C12.1826 5.05303 10.7126 5.96303 9.12012 6.64553C9.34762 7.11803 9.57512 7.60803 9.78512 8.09803C10.3801 7.99303 10.9751 7.92303 11.5876 7.92303C12.7601 7.92303 13.9151 8.13303 15.0001 8.55303C15.0001 8.44803 15.0001 8.34303 15.0001 8.23803C15.0001 6.64553 14.4751 5.14053 13.5126 3.91553Z"
        fill="#D85888"
      />
      <path
        d="M3.69531 13.751C4.92031 14.7135 6.42532 15.2385 8.00032 15.2385C8.99782 15.2385 9.97782 15.0285 10.8703 14.626C10.5378 12.701 9.99532 10.8285 9.29532 9.04346C6.81032 9.72596 4.76281 11.476 3.69531 13.751Z"
        fill="#D85888"
      />
      <path
        d="M7.86001 8.67594C8.22751 8.51844 8.59501 8.39594 8.96251 8.29094C8.77001 7.83594 8.56001 7.39844 8.33251 6.96094C6.4425 7.66094 4.4475 8.01094 2.4175 8.01094C1.945 8.01094 1.4725 7.99344 1 7.95844V8.23844C1 10.1109 1.735 11.8609 3.0475 13.1909C3.5025 12.2809 4.0975 11.4584 4.8325 10.7234C5.7075 9.84844 6.74001 9.14844 7.86001 8.67594Z"
        fill="#D85888"
      />
      <path
        d="M7.9654 6.22575C7.12539 4.68575 6.16289 3.23324 5.06039 1.88574C4.32539 2.23574 3.64289 2.70824 3.04789 3.28574C1.98039 4.35325 1.31539 5.68325 1.08789 7.13575C1.52539 7.17075 1.98039 7.18825 2.41789 7.18825C4.36039 7.18825 6.23289 6.85575 7.9654 6.22575Z"
        fill="#D85888"
      />
    </svg>
  );
};

export default memo(DribbbleLogoIcon);
