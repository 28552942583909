import { memo, SVGProps } from "react";

const AmeriaLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="175"
      height="56"
      viewBox="0 0 175 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M164.431 22.2476L167.751 13.9888H159.078L164.431 22.2476Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.275 16.8109L137.995 36.8147H142.435L144.469 33.619H157.667L159.825 36.8147H164.431L151.275 16.8109ZM146.378 30.8384L151.275 23.4096L156.172 30.8384H146.378Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4622 16.8524L7.18164 36.8147H11.5393L13.7389 33.619H26.9364L28.97 36.8147H33.8672L20.4622 16.8524ZM20.4621 23.6171L15.731 30.7969H25.5253L20.4621 23.6171Z"
        fill="currentColor"
      />
      <path
        d="M38.5569 19.301V36.8147H43.0806V24.2812L53.2485 38.2257L63.6239 23.9492V36.8147H67.9815V19.301H62.1298L53.2485 31.71L44.4501 19.301H38.5569Z"
        fill="currentColor"
      />
      <path
        d="M75.6593 19.301H94.8331V22.2476H79.7265V26.7298H91.762V30.1329H79.7265V33.8265H94.8331V36.8147H75.6593V19.301Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.451 19.301H101.307V36.8147H105.499V29.7594H113.177L118.116 36.8147H122.847L117.908 29.7594C119.623 29.538 123.054 28.1906 123.054 24.5717C123.054 20.9527 119.319 19.55 117.451 19.301ZM105.499 27.1033V22.0401H116.704C117.368 22.2061 118.697 23.1108 118.697 24.5717C118.697 26.0325 117.368 26.8681 116.704 27.1033H105.499Z"
        fill="currentColor"
      />
      <path
        d="M128.864 19.301H133.181V36.8147H128.864V19.301Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(AmeriaLogo);
