import React from "react";
import { PageContextProvider } from "../../context/PageContext";
import PageSEO from "../components/PageSEO/PageSEO";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import PageSection from "../../components/shared/PageSection/PageSection";
import PageContent from "../components/PageContent/PageContent";
import ContentLayout from "../../components/Layouts/ContentLayout/ContentLayout";
import PageLayoutSidebar from "../../components/Layouts/PageLayoutSidebar/PageLayoutSidebar";
import Footer from "../../components/Footer/Footer";
import { Page } from "../../api/contracts";
import resolutionStore from "../../store/ResolutionStore";
import { observer } from "mobx-react-lite";
import { useFetchPage } from "../../api/query";
import NotFound from "../NotFound/NotFound";
import { useLocation, useParams } from "react-router-dom";
import PostPageMainHeader from "./components/PostPageHeaderSection/PostPageMainHeader";
import PostPageNavigationMenu from "./components/PostPageNavigationMenu/PostPageNavigationMenu";
import scss from "../Home/styles.module.scss";

const PostPage = observer((props: Page) => {
  const navSections = props.contentSections.filter((item) => item.withNavigate);
  const enabledSidebar = !!props.sidebarWidgets?.length;
  const isLaptop = resolutionStore.isLaptop;

  return (
    <PageContextProvider page={props}>
      <PageSEO {...props.seo} />
      <MainLayout>
        <PageSection>
          <PostPageMainHeader
            header={props.header}
            backgroundImage={props.headerBackgroundImage}
            title={props.title}
          />
        </PageSection>
        <PageContent sections={props.mainSections} />
        <ContentLayout
          enabledSidebar={enabledSidebar}
          sidebar={
            <PageLayoutSidebar
              widgets={props.sidebarWidgets}
              sidebar={props.sidebar}
              beforeWidgets={
                navSections.length ? (
                  <>
                    <PostPageNavigationMenu navItems={navSections} />
                  </>
                ) : null
              }
            />
          }
          className={scss.contentLayout}
        >
          {!isLaptop && navSections.length && (
            <PostPageNavigationMenu navItems={navSections} type={"dropdown"} />
          )}
          <PageContent
            sections={props.contentSections}
            pageWithSidebar={enabledSidebar}
          />
        </ContentLayout>
      </MainLayout>
      <Footer />
    </PageContextProvider>
  );
});

const Post = () => {
  const { slug } = useParams();
  const { pathname } = useLocation();
  const { data, status } = useFetchPage({
    slug: slug as string,
    isBlog: pathname.startsWith("/blog"),
  });

  if (status === "loading") return null;

  if (!data) return <NotFound />;

  return <PostPage {...data} />;
};

export default Post;
