import cn from "classnames";
import WorkflowTab from "./WorkflowTab";
import scss from "./styles.module.scss";

type Props = {
  position: "vertical" | "horizontal";
  activeIndex: number;
  setIndex: (index: number) => void;
};
const WorkflowTabs = (props: Props) => {
  return (
    <>
      <div className={scss.caption}>Types of cooperation</div>
      <div className={cn(scss.tabs, scss[props.position], "hiddenScroll")}>
        <div className={scss.tabsInner}>
          {tabs.map((tab, i) => (
            <WorkflowTab
              key={i}
              index={i}
              title={tab.title}
              position={props.position}
              description={tab.description}
              setIndex={props.setIndex}
              active={props.activeIndex === i}
            />
          ))}
        </div>
      </div>
    </>
  );
};

WorkflowTabs.defaultProps = {
  position: "vertical",
};

export default WorkflowTabs;

const tabs = [
  {
    title: "Build a product",
    description:
      "We'll be your sidekick, using our superpowers to guide you through the product development process.",
  },
  {
    title: "Project care",
    description:
      "Get the help you need to meet deadlines and exceed expectations with quickly and efficiently increases your team's capabilities.",
  },
  {
    title: "Prototyping",
    description:
      "Bring your business dreams to life. The ultimate testing ground for your ideas before they take the world by storm.",
  },
  {
    title: "Сonsultations",
    description:
      "Efficiency is key, and we've got the key to unlock it! Let’s streamline your product development process for maximum success.",
  },
];
