import React from "react";
import { PageBlockCases } from "../../../api/contracts";
import BlockCasesDefaultView from "./components/BlockCasesDefaultView/BlockCasesDefaultView";
import BlockCasesWithPaginationView from "./components/BlockCasesWithPaginationView/BlockCasesWithPaginationView";

type IProps = {
  block: PageBlockCases;
};

const BlockCases = ({ block }: IProps) => {
  switch (block.viewType) {
    case "default":
      return <BlockCasesDefaultView cases={block.cases} />;

    case "with-pagination":
      return <BlockCasesWithPaginationView cases={block.cases} />;

    default:
      return null;
  }
};

export default BlockCases;
