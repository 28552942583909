import { useQuery } from "react-query";
import {
  fetchCasePreview,
  fetchPage,
  fetchPosts,
  fetchStoryById,
  sendContactMessage,
} from "./api";
import usePreloader from "../hooks/usePreloader";
import { useMutation } from "react-query";
import { PostsCurrentType } from "../components/blocks/BlockPosts/components/PostsWithMainPostAndPagination/PostsWithMainPostAndPagination";

type UseFetchPageDto = {
  slug: string;
  isBlog?: boolean;
  tag?: string;
};

export const useFetchPage = ({ slug, isBlog, tag }: UseFetchPageDto) => {
  const preloader = usePreloader();

  return useQuery(
    ["page", slug, tag],
    () => {
      preloader(true);
      return fetchPage(slug, tag);
    },
    {
      ...(isBlog
        ? {
            refetchOnWindowFocus: false,
          }
        : {
            staleTime: Infinity,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
          }),

      onSettled: () => {
        preloader(false);
      },
    }
  );
};

export const useFetchCasePreview = (slug: string) => {
  return useQuery(
    ["case", slug],
    () => {
      // preloader(true, "PAGE");
      return fetchCasePreview(slug);
    },
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      // onSettled: () => {
      //   preloader(false);
      // },
    }
  );
};

export const useFetchStoryById = (id: string) => {
  return useQuery(["story", id], () => fetchStoryById(id), {
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export type UseFetchPostDto = {
  pageNumber: number;
  tag?: PostsCurrentType;
  mainPostSlug: string;
  pageSize: number;
};

export const useFetchPosts = () => {
  return useMutation(
    ["post"],
    ({
      pageNumber,
      mainPostSlug,
      tag = PostsCurrentType.ALL,
      pageSize,
    }: UseFetchPostDto) => {
      return fetchPosts({ pageNumber, mainPostSlug, tag, pageSize });
    }
  );
};

export const useSendContactMessage = () => {
  return useMutation(
    (params: Parameters<typeof sendContactMessage>[0]) =>
      sendContactMessage(params),
    {}
  );
};
