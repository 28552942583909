import React from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

interface IProps {
  title: string;
  avatar: string;
  classname?: string;
}

const Avatar = ({ avatar, title, classname }: IProps) => {
  return <img className={cn(scss.image, classname)} src={avatar} alt={title} />;
};

export default Avatar;
