import { memo, SVGProps } from "react";

const QuoteOpenIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 11.6028C0 15.6794 2.71698 18 5.75741 18C8.53908 18 10.8679 15.7422 10.8679 13.0453C10.8679 10.3484 8.92722 8.52962 6.469 8.52962C5.95148 8.52962 5.36927 8.59233 5.1752 8.71777C5.75741 6.45993 8.15094 3.82578 10.221 2.75958L6.66307 0C2.65229 2.69687 0 6.89895 0 11.6028ZM13.1968 11.6028C13.1968 15.6794 15.8491 18 18.8895 18C21.7358 18 24 15.7422 24 13.0453C24 10.3484 22.0593 8.52962 19.6658 8.52962C19.1482 8.52962 18.566 8.59233 18.372 8.71777C18.8895 6.45993 21.3477 3.82578 23.4178 2.75958L19.7951 0C15.7844 2.69687 13.1968 6.89895 13.1968 11.6028Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(QuoteOpenIcon);
