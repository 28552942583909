import React, { memo, SVGProps } from "react";

const LinkedInLogoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.54543 1.77271C3.54543 2.12332 3.44146 2.46606 3.24667 2.75758C3.05188 3.0491 2.77502 3.27632 2.4511 3.41049C2.12718 3.54466 1.77075 3.57977 1.42688 3.51137C1.083 3.44297 0.767136 3.27413 0.519217 3.02621C0.271298 2.77829 0.102464 2.46243 0.0340631 2.11855C-0.0343375 1.77468 0.000767923 1.41825 0.13494 1.09433C0.269113 0.770405 0.496327 0.493544 0.787848 0.298756C1.07937 0.103968 1.42211 0 1.77272 0C2.24287 0 2.69377 0.186768 3.02621 0.519216C3.35866 0.851664 3.54543 1.30256 3.54543 1.77271Z"
        fill="#1897DA"
      />
      <path
        d="M3.1435 4.72723V12.5591C3.14381 12.6169 3.1327 12.6741 3.11081 12.7276C3.08891 12.7811 3.05666 12.8297 3.01592 12.8706C2.97517 12.9116 2.92673 12.9441 2.87338 12.9663C2.82003 12.9885 2.76283 12.9999 2.70505 12.9999H0.836608C0.77883 13.0001 0.721592 12.9888 0.668183 12.9667C0.614774 12.9447 0.566247 12.9123 0.525392 12.8715C0.484537 12.8306 0.45216 12.7821 0.430122 12.7287C0.408084 12.6753 0.396818 12.618 0.396974 12.5603L0.396974 4.72723C0.396974 4.61063 0.443293 4.49881 0.52574 4.41636C0.608187 4.33392 0.72001 4.2876 0.836608 4.2876H2.70505C2.82144 4.28791 2.93296 4.33437 3.01515 4.41678C3.09734 4.49919 3.1435 4.61084 3.1435 4.72723Z"
        fill="#1897DA"
      />
      <path
        d="M12.9105 8.80459V12.5958C12.9106 12.649 12.9003 12.7016 12.88 12.7507C12.8598 12.7998 12.83 12.8444 12.7924 12.882C12.7549 12.9195 12.7103 12.9493 12.6611 12.9696C12.612 12.9898 12.5594 13.0002 12.5063 13H10.4972C10.4441 13.0002 10.3915 12.9898 10.3424 12.9696C10.2932 12.9493 10.2486 12.9195 10.2111 12.882C10.1735 12.8444 10.1437 12.7998 10.1235 12.7507C10.1032 12.7016 10.0929 12.649 10.093 12.5958V8.92159C10.093 8.37323 10.2538 6.52015 8.6595 6.52015C7.42451 6.52015 7.17278 7.78823 7.12315 8.35787V12.5958C7.12316 12.702 7.08139 12.8039 7.00686 12.8796C6.93234 12.9552 6.83105 12.9985 6.72488 13H4.78435C4.73132 13 4.67882 12.9896 4.62985 12.9692C4.58088 12.9489 4.5364 12.9191 4.49897 12.8816C4.46153 12.844 4.43187 12.7994 4.41169 12.7504C4.3915 12.7014 4.38119 12.6489 4.38135 12.5958V4.69307C4.38119 4.64005 4.3915 4.58752 4.41169 4.53849C4.43187 4.48946 4.46153 4.4449 4.49897 4.40735C4.5364 4.3698 4.58088 4.34001 4.62985 4.31968C4.67882 4.29936 4.73132 4.2889 4.78435 4.2889H6.72488C6.83207 4.2889 6.93488 4.33148 7.01068 4.40728C7.08647 4.48308 7.12906 4.58588 7.12906 4.69307V5.37616C7.5876 4.68835 8.26714 4.15771 9.71722 4.15771C12.9294 4.15771 12.9105 7.15715 12.9105 8.80459Z"
        fill="#1897DA"
      />
    </svg>
  );
};

export default memo(LinkedInLogoIcon);
