import { memo, SVGProps } from "react";

const TestingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.6364 20H15.3636V17.7778H23.6364V2.22222H2.36364V5.55556H0V2.22222C0 0.994923 1.05824 0 2.36364 0H23.6364C24.9418 0 26 0.994923 26 2.22222V17.7778C26 19.0051 24.9418 20 23.6364 20ZM13 20H10.6364C10.6422 17.3471 9.51921 14.8021 7.51755 12.9322C5.5288 11.0501 2.82181 9.99425 0 10V7.77774C3.44845 7.76916 6.75738 9.05739 9.18982 11.3556C11.6374 13.6427 13.0094 16.7556 13 20ZM8.27276 20H5.90909C5.91231 18.5261 5.28848 17.1123 4.17655 16.0733C3.07164 15.0277 1.5677 14.4412 0 14.4444V12.2222C2.19553 12.2159 4.30248 13.0358 5.85118 14.4989C7.40732 15.955 8.27927 17.9359 8.27276 20ZM3.54545 20H0V16.6667C1.9581 16.6667 3.54545 18.1591 3.54545 20Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(TestingIcon);
