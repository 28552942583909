import { memo, SVGProps } from "react";

const SyneosHealthLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="126"
      height="56"
      viewBox="0 0 126 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M113.118 22.7858C111.968 23.3744 110.685 23.6584 109.393 23.6105C108.1 23.5626 106.842 23.1844 105.738 22.5122C104.635 21.8401 103.723 20.8965 103.091 19.772C102.458 18.6475 102.126 17.3799 102.127 16.0907V8.36269C102.128 7.03006 101.793 5.71856 101.152 4.54869C100.512 3.37883 99.5874 2.38811 98.4629 1.66757C99.6134 1.07897 100.896 0.794998 102.189 0.842904C103.481 0.89081 104.739 1.26899 105.843 1.94115C106.946 2.61331 107.858 3.5569 108.49 4.68139C109.123 5.80587 109.455 7.07351 109.454 8.36269V16.0969C109.455 17.1375 109.661 18.1679 110.062 19.1288C110.462 20.0898 111.049 20.9625 111.789 21.6969C112.191 22.1075 112.636 22.4726 113.118 22.7858Z"
        fill="currentColor"
      />
      <path
        d="M99.3168 15.879H94.1362C92.9927 15.882 91.8635 15.6259 90.8338 15.13C89.8042 14.6341 88.9011 13.9114 88.1927 13.0166C87.4843 12.1217 86.9892 11.0781 86.7447 9.96448C86.5003 8.85089 86.5129 7.69648 86.7817 6.58847C87.0504 5.48045 87.5682 4.44782 88.296 3.56857C89.0238 2.68931 89.9425 1.98645 90.9828 1.51306C92.023 1.03967 93.1576 0.808151 94.3007 0.835983C95.4439 0.863814 96.5658 1.15027 97.5816 1.67371C97.1003 1.98065 96.6544 2.33956 96.2521 2.74394C95.2973 3.69926 94.6019 4.88096 94.2313 6.17793C93.8606 7.4749 93.827 8.84453 94.1334 10.158C94.4399 11.4715 95.0764 12.6858 95.9832 13.6866C96.8899 14.6874 98.037 15.4418 99.3168 15.879Z"
        fill="currentColor"
      />
      <path
        d="M124.97 16.0969C124.969 17.3854 124.636 18.652 124.003 19.7755C123.37 20.899 122.458 21.8417 121.355 22.5136C120.253 23.1854 118.995 23.5638 117.703 23.6125C116.412 23.6613 115.129 23.3788 113.979 22.792C114.46 22.4847 114.905 22.1258 115.308 21.7218C116.263 20.7667 116.959 19.5853 117.33 18.2887C117.701 16.992 117.736 15.6226 117.431 14.3089C117.126 12.9952 116.491 11.7803 115.586 10.7783C114.68 9.77628 113.535 9.02 112.256 8.58044H117.436C119.434 8.58538 121.348 9.37907 122.76 10.7878C124.172 12.1965 124.967 14.1055 124.97 16.0969Z"
        fill="currentColor"
      />
      <path
        d="M50.2881 43.4125H51.137V48.9378H58.5021V43.4125H59.3697V55.5023H58.5208V49.8151H51.137V55.5147H50.2881V43.4125Z"
        fill="currentColor"
      />
      <path
        d="M62.5161 51.7192C62.5627 52.5656 62.9268 53.3636 63.5361 53.9548C64.1454 54.5461 64.9554 54.8875 65.8054 54.9112C66.3485 54.9003 66.8816 54.7637 67.3626 54.5121C67.8436 54.2605 68.2592 53.9008 68.5767 53.4614L69.0948 53.9654C68.7196 54.4892 68.2268 54.9184 67.6557 55.2187C67.0846 55.519 66.451 55.6822 65.8054 55.6952C64.6956 55.6482 63.6486 55.1687 62.8898 54.36C62.1311 53.5512 61.7212 52.4777 61.7483 51.3707C61.7483 48.9503 63.6583 47.0525 65.7305 47.0525C67.8028 47.0525 69.5442 48.7823 69.5442 51.3707V51.7192H62.5161ZM62.5161 50.9414H68.7577C68.7677 50.1283 68.4541 49.3443 67.8856 48.7612C67.317 48.1781 66.5399 47.8434 65.7243 47.8303C64.9005 47.8665 64.1189 48.2036 63.5284 48.7773C62.9378 49.3511 62.5797 50.1214 62.5223 50.9414H62.5161Z"
        fill="currentColor"
      />
      <path
        d="M77.2407 50.3316C77.2576 50.0139 77.2099 49.6961 77.1006 49.3972C76.9913 49.0983 76.8226 48.8245 76.6047 48.5921C76.3867 48.3597 76.124 48.1736 75.8322 48.0448C75.5403 47.916 75.2254 47.8473 74.9063 47.8427C74.4383 47.8578 73.9791 47.9734 73.56 48.1818C73.141 48.3901 72.772 48.6862 72.4783 49.0498L71.9603 48.5334C72.3142 48.0893 72.7613 47.7277 73.2702 47.474C73.7791 47.2203 74.3376 47.0807 74.9063 47.0649C75.3307 47.0643 75.7508 47.15 76.1409 47.3168C76.5309 47.4835 76.8828 47.7278 77.1749 48.0347C77.467 48.3417 77.6932 48.7048 77.8398 49.1018C77.9863 49.4989 78.0501 49.9216 78.0272 50.3441V55.5272H77.247V54.1334C76.908 54.6159 76.4566 55.009 75.9314 55.2789C75.4062 55.5488 74.8231 55.6874 74.2322 55.6827C72.5844 55.6827 71.4609 54.5565 71.4609 53.3556C71.4609 52.1547 72.4159 51.0907 73.9576 50.8667L77.2407 50.3316ZM77.2407 51.8809V51.1094L74.1199 51.6258C72.8216 51.8312 72.2099 52.4907 72.2099 53.3556C72.2099 54.2205 72.9839 54.9049 74.2322 54.9049C74.6208 54.9132 75.0072 54.8451 75.3693 54.7044C75.7314 54.5638 76.0622 54.3534 76.3427 54.0853C76.6233 53.8172 76.8481 53.4966 77.0043 53.1419C77.1605 52.7871 77.2451 52.4052 77.2532 52.0178C77.2532 51.9764 77.2532 51.9349 77.2532 51.8934L77.2407 51.8809Z"
        fill="currentColor"
      />
      <path
        d="M81.7226 55.5147H80.9424V43.4125H81.7226V55.5147Z"
        fill="currentColor"
      />
      <path
        d="M85.4672 44.464H86.1601V47.2267H88.07V48.0044H86.1601V53.4427C86.1601 54.3947 86.6781 54.9111 87.4583 54.9111C87.849 54.9029 88.2337 54.8139 88.5881 54.6498V55.4275C88.2337 55.5917 87.849 55.6807 87.4583 55.6889C86.1601 55.6889 85.3861 54.9111 85.3861 53.4427V48.0044H83.7383V47.2267H84.868C84.9561 47.2272 85.0433 47.2091 85.1239 47.1737C85.2045 47.1382 85.2766 47.0862 85.3355 47.0209C85.3945 46.9557 85.4389 46.8787 85.4659 46.7952C85.4929 46.7116 85.5019 46.6233 85.4922 46.536V44.4702L85.4672 44.464Z"
        fill="currentColor"
      />
      <path
        d="M91.5898 48.6951C91.8692 48.1876 92.2828 47.7661 92.7857 47.4765C93.2887 47.1869 93.8616 47.0402 94.4422 47.0525C94.9008 47.0509 95.3548 47.1428 95.7764 47.3225C96.198 47.5023 96.5783 47.766 96.8939 48.0976C97.2096 48.4291 97.4539 48.8215 97.6119 49.2506C97.7699 49.6797 97.8382 50.1365 97.8127 50.5929V55.5147H97.0325V50.5929C97.0565 50.2392 97.0072 49.8843 96.8879 49.5503C96.7686 49.2162 96.5817 48.9102 96.3388 48.6512C96.0959 48.3922 95.8023 48.1856 95.476 48.0444C95.1498 47.9032 94.7979 47.8303 94.4422 47.8303C94.0548 47.839 93.673 47.9246 93.319 48.0819C92.9651 48.2393 92.6461 48.4652 92.3806 48.7467C92.1152 49.0281 91.9085 49.3594 91.7728 49.7212C91.637 50.0831 91.5748 50.4682 91.5898 50.8543V55.5147H90.8096V43.4125H91.5898V48.6889V48.6951Z"
        fill="currentColor"
      />
      <path
        d="M3.50117 24.024C3.50117 20.7573 6.32864 17.9387 10.7977 17.9387C15.4165 17.9387 17.6635 20.7573 17.6635 20.7573L14.9796 23.5822C14.49 23.0368 13.8947 22.5958 13.2297 22.2857C12.5646 21.9756 11.8435 21.8028 11.1098 21.7778C9.32464 21.7778 8.28228 22.8107 8.28228 24.0053C8.28228 27.8631 18.5561 25.6355 18.5561 33.0587C18.5561 36.792 15.7286 39.592 10.6604 39.592C9.17759 39.6223 7.70556 39.3352 6.34351 38.7502C4.98147 38.1652 3.76108 37.2959 2.76465 36.2009L5.44857 33.5253C6.06751 34.2193 6.82561 34.7761 7.67389 35.1598C8.52217 35.5435 9.44175 35.7456 10.3732 35.7529C12.6078 35.7529 13.7999 34.5644 13.7999 33.0773C13.7874 29.2444 3.50117 31.4533 3.50117 24.024Z"
        fill="currentColor"
      />
      <path
        d="M24.9231 39.0258L19.2432 24.6213H23.4126L26.8393 34.272L29.9601 25.592C30.0607 25.3111 30.2462 25.0681 30.4909 24.8965C30.7357 24.7249 31.0278 24.6331 31.327 24.6338H34.4479L28.6431 40.0587C27.3012 43.624 25.5223 45.4036 22.9819 45.4036C22.1167 45.4111 21.2576 45.2593 20.4478 44.9556V41.3902C20.9183 41.5938 21.4267 41.6956 21.9396 41.6889C22.4791 41.7112 23.0124 41.5665 23.4661 41.2746C23.9198 40.9827 24.2718 40.558 24.4737 40.0587L24.9231 39.0258Z"
        fill="currentColor"
      />
      <path
        d="M40.4767 26.4009C40.9757 25.7712 41.6083 25.2594 42.3292 24.9023C43.0501 24.5451 43.8413 24.3514 44.6461 24.3351C47.923 24.3351 50.3073 26.712 50.3073 30.7191V39.2H47.7357C47.3517 39.2 46.9834 39.0479 46.7118 38.7772C46.4402 38.5064 46.2877 38.1393 46.2877 37.7564V31.1546C46.3155 30.7704 46.2627 30.3846 46.1324 30.0219C46.0022 29.6592 45.7975 29.3275 45.5314 29.0482C45.2653 28.7689 44.9436 28.5479 44.587 28.3996C44.2303 28.2512 43.8465 28.1787 43.4602 28.1866C41.6751 28.1866 40.4829 29.3751 40.4829 31.6026V39.2H36.457V24.6213H40.4767V26.4009Z"
        fill="currentColor"
      />
      <path
        d="M56.7547 33.2329C57.0543 34.72 58.39 36.0516 60.6308 36.0516C61.6618 36.0514 62.6681 35.7365 63.5144 35.1494C63.8179 34.9485 64.1867 34.8701 64.546 34.9302C64.9052 34.9903 65.2282 35.1843 65.4493 35.4729L66.5853 36.9351C65.7643 37.747 64.7899 38.3883 63.7185 38.8219C62.6471 39.2556 61.4999 39.4729 60.3436 39.4613C59.1885 39.479 58.0443 39.2352 56.9973 38.7483C55.9503 38.2615 55.0276 37.5443 54.2988 36.6506C53.57 35.757 53.054 34.7102 52.7896 33.589C52.5253 32.4679 52.5194 31.3015 52.7725 30.1778C53.1467 28.4877 54.0983 26.9797 55.4646 25.9117C56.8308 24.8437 58.5268 24.282 60.2625 24.3227C61.222 24.3073 62.1748 24.4854 63.0636 24.8463C63.9523 25.2072 64.7588 25.7434 65.4344 26.4228C66.1101 27.1022 66.6411 27.9107 66.9954 28.7997C67.3498 29.6888 67.5203 30.6401 67.4966 31.5965C67.507 32.1437 67.4568 32.6904 67.3468 33.2267H56.7547V33.2329ZM56.7547 30.4142H63.4582C63.3251 29.6504 62.9209 28.9598 62.3192 28.4686C61.7176 27.9774 60.9588 27.7183 60.1814 27.7387C59.3931 27.7378 58.6273 28.0003 58.0061 28.4841C57.385 28.968 56.9444 29.6453 56.7547 30.408V30.4142Z"
        fill="currentColor"
      />
      <path
        d="M77.2654 39.4675C75.2454 39.4398 73.3137 38.6377 71.8713 37.2276C70.429 35.8175 69.5867 33.9079 69.5195 31.8951C69.5768 29.8859 70.4176 27.9781 71.8634 26.5772C73.3092 25.1762 75.2461 24.3925 77.2623 24.3925C79.2786 24.3925 81.2154 25.1762 82.6612 26.5772C84.107 27.9781 84.9479 29.8859 85.0051 31.8951C84.938 33.9068 84.0966 35.8156 82.6556 37.2255C81.2145 38.6354 79.2844 39.4382 77.2654 39.4675ZM77.2654 35.7342C77.7562 35.7191 78.2391 35.6066 78.6858 35.4033C79.1325 35.2 79.534 34.9101 79.867 34.5503C80.2 34.1906 80.4577 33.7683 80.6251 33.3081C80.7925 32.8479 80.8662 32.3591 80.8419 31.8702C80.8833 31.3769 80.8216 30.8805 80.6606 30.4122C80.4996 29.944 80.243 29.5141 79.9068 29.1499C79.5707 28.7856 79.1624 28.4948 78.7077 28.2959C78.2531 28.0969 77.762 27.9942 77.2654 27.9942C76.7689 27.9942 76.2778 28.0969 75.8232 28.2959C75.3685 28.4948 74.9602 28.7856 74.624 29.1499C74.2879 29.5141 74.0312 29.944 73.8703 30.4122C73.7093 30.8805 73.6476 31.3769 73.689 31.8702C73.6612 32.3611 73.7324 32.8526 73.8982 33.3157C74.0641 33.7788 74.3212 34.204 74.6545 34.5665C74.9879 34.9289 75.3906 35.2212 75.839 35.426C76.2874 35.6308 76.7724 35.7441 77.2654 35.7591V35.7342Z"
        fill="currentColor"
      />
      <path
        d="M95.4538 28.9271C95.1645 28.5708 94.8021 28.2804 94.3908 28.0754C93.9795 27.8704 93.529 27.7556 93.0695 27.7386C91.8773 27.7386 91.2844 28.3609 91.2844 28.9271C91.2719 30.9929 97.9505 29.96 97.9505 34.8444C97.9505 37.3706 95.8783 39.4489 92.1395 39.4489C87.9701 39.4489 86.1787 36.7733 86.1787 36.7733L88.4132 34.5458C88.7854 35.025 89.2568 35.4187 89.7954 35.7C90.3339 35.9814 90.9269 36.1437 91.5341 36.176C92.876 36.176 93.469 35.5538 93.469 34.8382C93.469 32.6106 86.7654 33.712 86.7654 28.896C86.7654 26.6684 89.1497 24.2915 92.4266 24.2915C93.3836 24.2628 94.335 24.448 95.2109 24.8334C96.0868 25.2189 96.865 25.7948 97.4886 26.5191L95.4538 28.9271Z"
        fill="currentColor"
      />
      <path
        d="M99.8843 36.7111H99.3975V36.4125H100.727V36.7111H100.24V38.024H99.8843V36.7111Z"
        fill="currentColor"
      />
      <path
        d="M100.921 36.4435H101.42L101.801 37.5511L102.157 36.4435H102.656V38.0551H102.319V36.9102L101.919 38.0551H101.645L101.245 36.9227V38.0551H100.908L100.921 36.4435Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(SyneosHealthLogo);
