import { memo, SVGProps } from "react";

const LinkedInBGIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="48"
      viewBox="0 0 40 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_4117_21242"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="0"
        width="39"
        height="48"
      >
        <path
          d="M40 4C40 1.79086 38.2091 0 36 0L17 0C15.2222 0 13.6578 1.17333 13.16 2.88L1.49333 42.88C0.746666 45.44 2.66667 48 5.33333 48H36C38.2091 48 40 46.2091 40 44V4Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_4117_21242)">
        <g opacity="0.15">
          <path
            d="M10.0716 12.6247C10.2099 13.4093 10.1125 14.2173 9.79164 14.9466C9.47077 15.6758 8.94085 16.2935 8.26891 16.7216C7.59697 17.1497 6.81318 17.3689 6.01666 17.3515C5.22014 17.3341 4.44666 17.0809 3.79403 16.6239C3.1414 16.167 2.63894 15.5267 2.35018 14.7842C2.06142 14.0416 1.99934 13.2301 2.17178 12.4523C2.34422 11.6745 2.74344 10.9653 3.31895 10.4143C3.89447 9.86339 4.62043 9.49549 5.40504 9.35714C6.45717 9.17162 7.5399 9.41166 8.41505 10.0244C9.29019 10.6372 9.88607 11.5726 10.0716 12.6247Z"
            fill="#1897DA"
          />
          <path
            d="M10.3378 19.3947L13.4282 36.9211C13.4517 37.0503 13.4494 37.1828 13.4215 37.3111C13.3936 37.4394 13.3407 37.5609 13.2656 37.6687C13.1906 37.7764 13.0951 37.8683 12.9844 37.939C12.8738 38.0097 12.7503 38.0578 12.621 38.0806L8.4397 38.8179C8.31046 38.841 8.17793 38.8384 8.04971 38.8102C7.92149 38.7819 7.80012 38.7286 7.69257 38.6533C7.58503 38.578 7.49342 38.4822 7.42303 38.3714C7.35264 38.2605 7.30484 38.1369 7.28239 38.0075L4.19155 20.4785C4.14554 20.2175 4.20507 19.949 4.35704 19.732C4.50901 19.5149 4.74097 19.3672 5.0019 19.3212L9.18317 18.5839C9.44376 18.5387 9.71166 18.5986 9.92811 18.7506C10.1446 18.9026 10.2919 19.1342 10.3378 19.3947Z"
            fill="#1897DA"
          />
          <path
            d="M33.8032 24.6658L35.2992 33.15C35.3205 33.2688 35.3181 33.3906 35.2922 33.5085C35.2662 33.6264 35.2172 33.738 35.148 33.8369C35.0788 33.9358 34.9907 34.02 34.8888 34.0847C34.7869 34.1494 34.6732 34.1933 34.5542 34.2139L30.0582 35.0067C29.9394 35.028 29.8176 35.0256 29.6997 34.9997C29.5818 34.9737 29.4702 34.9247 29.3713 34.8555C29.2724 34.7862 29.1882 34.6981 29.1235 34.5962C29.0588 34.4943 29.0149 34.3806 28.9943 34.2617L27.5444 26.0393C27.3281 24.8122 26.9565 20.6019 23.3888 21.231C20.6251 21.7183 20.5622 24.6554 20.6759 25.9497L22.3481 35.4336C22.3901 35.6712 22.3368 35.9158 22.1999 36.1144C22.0629 36.3131 21.8533 36.4499 21.6164 36.4952L17.2738 37.2609C17.1551 37.2819 17.0335 37.2792 16.9159 37.253C16.7983 37.2268 16.687 37.1777 16.5884 37.1085C16.4898 37.0392 16.4058 36.9512 16.3413 36.8494C16.2768 36.7477 16.233 36.6342 16.2124 36.5155L13.0941 18.8304C13.0728 18.7118 13.0751 18.5902 13.101 18.4725C13.1268 18.3548 13.1756 18.2433 13.2445 18.1446C13.3135 18.0458 13.4013 17.9615 13.5028 17.8967C13.6044 17.8319 13.7178 17.7878 13.8364 17.7669L18.179 17.0011C18.4189 16.9589 18.6658 17.0136 18.8653 17.1533C19.0648 17.293 19.2007 17.5063 19.243 17.7462L19.5125 19.2748C20.2673 17.5546 21.5786 16.099 24.8236 15.5268C32.0119 14.2593 33.1532 20.9791 33.8032 24.6658Z"
            fill="#1897DA"
          />
        </g>
      </g>
    </svg>
  );
};

export default memo(LinkedInBGIcon);
