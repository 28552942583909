import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import DropdownNavMenu from "./components/DropdownNavMenu/DropdownNavMenu";
import NavMenuItem from "./components/NavMenuItem/NavMenuItem";
import { PageSection } from "../../../../api/contracts";
import scss from "./styles.module.scss";

const throttle = require("lodash.throttle");

interface IProps {
  navItems: PageSection[];
  classname?: string;
  flexOrder?: number;
  type?: "default" | "dropdown";
}

const PostPageNavigationMenu = ({
  navItems,
  classname,
  flexOrder,
  type = "default",
}: IProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const prevIndex = useRef<number>(-1);
  const sections = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    sections.current = navItems.map(
      (section) =>
        document.getElementById(`section-${section.id}`) as HTMLDivElement
    );
  }, [navItems]);

  useEffect(() => {
    const scrollHandler = throttle(() => {
      const index = navItems.findIndex((el, i) => {
        const rect = sections.current[i]?.getBoundingClientRect();
        // eslint-disable-next-line
        if (!rect) return;
        const activeStart = rect.y < window.innerHeight / 3;
        const activeEnd =
          activeStart && rect.y + rect.height > window.innerHeight / 4;
        return activeStart && activeEnd;
      });

      if (index !== prevIndex.current && index !== -1) {
        setActiveIndex(index);
        prevIndex.current = index;
      }
    }, 100);

    document.addEventListener("scroll", scrollHandler);

    return () => document.removeEventListener("scroll", scrollHandler);
  }, [navItems]);

  if (type === "dropdown")
    return (
      <DropdownNavMenu
        navItems={navItems}
        activeIndex={activeIndex}
        flexOrder={flexOrder}
        classname={classname}
      />
    );
  else
    return (
      <div
        className={cn(scss.navBarWrapper, classname)}
        style={{ order: flexOrder }}
      >
        <h2 className={scss.title}>TABLE OF CONTENTS</h2>
        {navItems.map((item, i) => (
          <NavMenuItem
            key={item.id + i}
            title={item?.title}
            sectionNumber={i + 1}
            id={item.id}
            isActive={i === activeIndex}
          />
        ))}
      </div>
    );
};

export default PostPageNavigationMenu;
