import React, { useState } from "react";
import Avatar from "../../../../components/shared/Avatar/Avatar";
import ArrowBig from "../../../../icons/ArrowBig";
import cn from "classnames";
import useOpenContactUs from "../../../../hooks/useOpenContactUs";
import { Theme } from "../../../../api/contracts";
import Button from "../../../../components/shared/Button/Button";
import HummerIcon from "../../../../icons/HummerIcon";
import scss from "./styles.module.scss";

interface IProps {
  caption?: string;
  title?: string;
  subtitle?: string;
  size?: "big" | "medium" | "small";
  captionColor?: string;
  titleColor?: string;
  subtitleColor?: string;
  uppercaseTitle?: boolean;
  uppercaseAll?: boolean;
  sticky?: boolean;
  stickyOffset?: number;
  theme?: Theme;
}

const OurTeam = ({
  caption,
  captionColor,
  title,
  titleColor,
  size = "big",
  uppercaseTitle = true,
  sticky,
  stickyOffset,
  theme = "light",
  uppercaseAll,
  subtitle,
  subtitleColor,
}: IProps) => {
  const iconSize = size === "medium" ? 48 : size === "small" ? 40 : 56;
  const openContactUs = useOpenContactUs();
  const [broken, setBroken] = useState(false);
  switch (theme) {
    case "light": {
      const team = [
        {
          src: "/assets/val-contact.webp",
          alt: "valentin",
        },
        {
          src: "/assets/artem-contact.webp",
          alt: "artem",
        },
        {
          src: "/assets/kate-contact.webp",
          alt: "kate",
        },
      ];
      return (
        <div
          className={cn(scss.container, scss[theme], { [scss.sticky]: sticky })}
          onClick={openContactUs}
          style={{ top: sticky && stickyOffset ? stickyOffset : undefined }}
        >
          <p
            className={cn(scss.desc, scss[size])}
            style={{ color: captionColor }}
          >
            {caption || `need team for a project?`}
          </p>
          <h2
            className={cn(scss.title, scss[size], {
              [scss.uppercase]: uppercaseTitle,
            })}
            style={{ color: titleColor }}
          >
            {title || `We help you develop products and services`}
          </h2>
          <div className={scss.sectionFooter}>
            <div className={scss.avatarsWrapper}>
              {team.reverse().map((el) => (
                <Avatar
                  classname={scss.avatar}
                  title={el.alt}
                  avatar={el.src}
                  key={el.alt}
                />
              ))}
            </div>
            <div className={scss.arrowIconWrapper}>
              <ArrowBig width={iconSize} height={iconSize} />
            </div>
          </div>
        </div>
      );
    }
    case "dark": {
      const breakGlassHandler = () => {
        setBroken(true);
        setTimeout(() => {
          openContactUs();
          setBroken(false);
        }, 500);
      };

      return (
        <div
          className={cn(scss.container, scss[theme], {
            [scss.sticky]: sticky,
          })}
        >
          <div className={cn(scss.contentWrapper, { [scss.broken]: broken })}>
            <div className={scss.imageWrapper}>
              <img
                className={scss.image}
                src="/assets/callToUmber.webp"
                alt="call to us"
              />
            </div>
            <div className={scss.textWrapper}>
              <p
                className={cn(scss.caption, { [scss.uppercase]: uppercaseAll })}
                style={{ color: captionColor }}
              >
                {caption || "In case of"}
              </p>
              <p
                className={cn(scss.title, {
                  [scss.uppercase]: uppercaseAll || uppercaseTitle,
                })}
                style={{ color: titleColor }}
              >
                {title || "excited business idea"}
              </p>
              <p
                className={cn(scss.subtitle, {
                  [scss.uppercase]: uppercaseAll,
                })}
                style={{ color: subtitleColor }}
              >
                {subtitle || "break glass"}
              </p>
            </div>
          </div>
          <Button
            uppercase
            disabled={broken}
            type="button"
            size="large"
            onClick={breakGlassHandler}
            variant="success"
            rounding="small"
            className={scss.button}
          >
            <HummerIcon className={scss.buttonIcon} />
            break glass
          </Button>
        </div>
      );
    }
    default:
      return null;
  }
};

export default OurTeam;
