import { memo, SVGProps } from "react";

const CodeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.4 20H2.6C1.16406 20 0 18.8807 0 17.5V2.5C0 1.11929 1.16406 0 2.6 0H23.4C24.8359 0 26 1.11929 26 2.5V17.5C26 18.8807 24.8359 20 23.4 20ZM2.6 2.5V17.5H23.4V2.5H2.6ZM20.8 15H13V12.5H20.8V15ZM7.0382 15L5.2 13.2325L8.1809 10.3663L5.2 7.5L7.0382 5.7325L11.8573 10.3663L7.0395 15H7.0382Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(CodeIcon);
