import { createContext, ReactNode, useContext } from "react";
import { PageSection } from "../api/contracts";

const SectionContext = createContext<PageSection | undefined>(undefined);

type Props = {
  section: PageSection;
  children: ReactNode;
};
export const SectionContextProvider = (props: Props) => {
  return (
    <SectionContext.Provider value={props.section}>
      {props.children}
    </SectionContext.Provider>
  );
};

export const useSection = () => {
  const context = useContext(SectionContext);
  if (!context) {
    throw new Error(
      `You need to wrap the Component into a SectionContextProvider to provide the functionality of SectionContext.`
    );
  }
  return context;
};
