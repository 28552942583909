import axios from "axios";
import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const axiosClient = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});
