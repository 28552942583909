import Section from "../Sections/Section/Section";
import React from "react";
import { PageSection } from "../../../api/contracts";

type Props = {
  sections: PageSection[];
  pageWithSidebar?: boolean;
};
const PageContent = (props: Props) => {
  return (
    <>
      {props.sections?.map((section) => (
        <Section
          key={section.id}
          section={section}
          pageWithSidebar={props.pageWithSidebar}
          borderColor="var(--dark-2)"
        />
      ))}
    </>
  );
};

export default PageContent;
