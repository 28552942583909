import React, { useEffect, useState } from "react";
import CloseIcon from "../../../../../icons/CloseIcon";
import modalStore from "../../../../../store/ModalStore";
import { PageBlockStory } from "../../../../../api/contracts";
import cn from "classnames";
import ArrowChevronLeft from "../../../../../icons/ArrowChevronLeft";
import ArrowChevronRight from "../../../../../icons/ArrowChevronRight";
import { observer } from "mobx-react-lite";
import HeaderNavLink from "../../../../Header/components/HeaderNavLink/HeaderNavLink";
import resolutionStore from "../../../../../store/ResolutionStore";
import VideoItem from "../VideoItem/VideoItem";
import scss from "./styles.module.scss";

interface IProps {
  activeId: string;
  stories: PageBlockStory[];
}

const StoriesModal = ({ activeId, stories }: IProps) => {
  const [activeItemId, setActiveItemId] = useState(activeId);
  const [showStories, setShowStories] = useState(false);
  const isMobile = resolutionStore.isMobile;

  const closeHandler = () => modalStore.closeModal();

  const clickHandler = (id: string) => () => setActiveItemId(id);

  const nextPrevVideoHandler =
    (id: string, direction: "next" | "prev", pauseHandler?: () => void) =>
    () => {
      const activeIndex = stories.findIndex((item) => item.id === id);
      if (direction === "next") {
        if (pauseHandler) pauseHandler();
        setTimeout(() => {
          if (activeIndex === stories.length - 1)
            setActiveItemId(stories[0].id);
          else setActiveItemId(stories[activeIndex + 1].id);
        }, 100);
      }
      if (direction === "prev") {
        if (pauseHandler) pauseHandler();
        setTimeout(() => {
          if (activeIndex === 0)
            setActiveItemId(stories[stories.length - 1].id);
          else setActiveItemId(stories[activeIndex - 1].id);
        }, 100);
      }
    };

  useEffect(() => {
    setTimeout(() => setShowStories(true), 250);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const activeItem = document.getElementById(activeItemId);
      if (activeItem) {
        activeItem.scrollIntoView({
          block: "nearest",
          inline: "center",
          behavior: "smooth",
        });
      }
    }, 300);
  }, [activeItemId]);

  return (
    <div className={scss.storiesModalWrapper}>
      <div onClick={closeHandler} className={scss.closeIconWrapper}>
        <CloseIcon />
      </div>
      <h2 className={scss.storiesModalTitle}>
        {stories?.find((item) => item.id === activeItemId)?.title}
      </h2>
      <div
        className={cn(scss.storiesModalItemsWrapper, {
          [scss.showStories]: showStories,
        })}
      >
        {stories.map((story) => (
          <VideoItem
            key={story.id}
            setActiveItemId={clickHandler(story.id)}
            story={story}
            nextPrevVideoHandler={nextPrevVideoHandler}
            isActive={activeItemId === story.id}
          />
        ))}
      </div>
      <div className={scss.storiesModalActionsWrapper}>
        {isMobile && (
          <button
            className={scss.arrowMobile}
            onClick={nextPrevVideoHandler(activeItemId, "prev")}
          >
            <ArrowChevronLeft />
          </button>
        )}
        <HeaderNavLink
          type={"link"}
          to=" "
          disableDefaultHover
          className={scss.storiesModalButton}
          borderColor="var(--dark-3)"
        >
          {isMobile ? "more stories" : "watch more stories"}
        </HeaderNavLink>
        {isMobile && (
          <button
            className={scss.arrowMobile}
            onClick={nextPrevVideoHandler(activeItemId, "next")}
          >
            <ArrowChevronRight />
          </button>
        )}
      </div>
    </div>
  );
};

export default observer(StoriesModal);
