import React, { memo, useRef } from "react";
import { Location, Outlet } from "react-router-dom";
import Header from "../../Header/Header";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import scss from "./styles.module.scss";

const PageLayout = ({ location }: { location: Location }) => {
  const refKey = useRef(location.key);
  const refPathname = useRef(location.pathname);

  if (location.pathname !== refPathname.current) {
    refKey.current = location.key;
    refPathname.current = location.pathname;
  }

  return (
    <div className={scss.page}>
      <Header />
      <TransitionGroup component={null}>
        <CSSTransition
          key={refKey.current}
          classNames="fade-page"
          timeout={300}
        >
          <Outlet />
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default memo(PageLayout);
