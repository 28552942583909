import React from "react";
import StarIcon from "../../../icons/StarIcon";
import scss from "./styles.module.scss";

type Props = {
  rating: number;
};

const StarRating = ({ rating }: Props) => {
  const maxRating = 5;
  return (
    <div className={scss.container}>
      {Array.from({ length: maxRating }).map((_, i) => (
        <StarIcon key={i} fillOpacity={i < rating ? "1" : "0"} />
      ))}
    </div>
  );
};

export default StarRating;
