import { ChangeEvent, useEffect, useState } from "react";
import cn from "classnames";
import InfoFill from "../../../../icons/InfoFill";
import { observer } from "mobx-react-lite";
import resolutionStore from "../../../../store/ResolutionStore";
import scss from "./styles.module.scss";

type Props = {
  isValid: boolean | null;
  type: "input" | "textarea";
  value: string;
  name: string;
  onChange: (value: string) => void;
  label?: string;
  errorMessage?: string;
  disabled?: boolean;
};

const Field = (props: Props) => {
  const [showError, setShowError] = useState(false);
  const { isMobile, isTablet } = resolutionStore;
  const mobileMode = isMobile || isTablet;

  useEffect(() => {
    if (showError && (!mobileMode || props.isValid !== false)) {
      setShowError(false);
    }
  }, [mobileMode, props.isValid, showError]);

  useEffect(() => {
    if (showError) {
      const clickHandler = () => {
        requestAnimationFrame(() => {
          setShowError(false);
        });
      };

      document.addEventListener("click", clickHandler, true);
      return () => {
        document.removeEventListener("click", clickHandler, true);
      };
    }
  }, [showError]);

  return (
    <div
      className={cn(scss.fieldWrapper, {
        [scss.invalid]: props.isValid === false,
      })}
    >
      <div className={scss.fieldInner}>
        {props.type === "input" ? (
          <input
            disabled={props.disabled}
            name={props.name}
            value={props.value}
            className={cn(scss.field, {
              [scss.invalid]: props.isValid === false,
            })}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              props.onChange(e.target.value)
            }
          />
        ) : (
          <textarea
            disabled={props.disabled}
            name={props.name}
            value={props.value}
            className={cn(scss.field, scss.textarea, {
              [scss.invalid]: props.isValid === false,
            })}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              props.onChange(e.target.value)
            }
          />
        )}
        {props.label && <div className={scss.label}>{props.label}</div>}
        <div className={scss.bg} />
        <div className={scss.hoverBg} />
        <div className={scss.focusBg} />
        <div className={scss.invalidBg} />
      </div>
      {props.isValid === false ? (
        <div
          onClick={
            mobileMode && !showError
              ? (e) => {
                  setShowError(true);
                  e.preventDefault();
                  e.stopPropagation();
                }
              : undefined
          }
          className={cn(scss.fieldIcon, {
            [scss.mobile]: mobileMode,
            [scss.showError]: showError,
          })}
        >
          {props.errorMessage && (
            <div className={scss.tooltip}>{props.errorMessage}</div>
          )}
          <InfoFill width={24} height={24} />
        </div>
      ) : null}
    </div>
  );
};

export default observer(Field);
