import React from "react";
import { PageBlockPosts } from "../../../api/contracts";
import PostsWithMainPostAndPagination from "./components/PostsWithMainPostAndPagination/PostsWithMainPostAndPagination";
import PostsWithFilters from "./components/PostsWithFilters/PostsWithFilters";

type Props = {
  block: PageBlockPosts;
};

const BlockPosts = ({ block }: Props) => {
  switch (block.viewType) {
    case "with-main-post-and-pagination":
      return <PostsWithMainPostAndPagination block={block} />;
    case "with-filtered-by-tag":
      return <PostsWithFilters block={block} />;
    default:
      return null;
  }
};

export default BlockPosts;
