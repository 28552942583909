import { ReactNode } from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import resolutionStore from "../../../store/ResolutionStore";
import scss from "./styles.module.scss";

type Props = {
  children: ReactNode;
  enabledSidebar?: boolean;
  sidebar?: ReactNode;
  className?: string;
};
const ContentLayout = (props: Props) => {
  const { isLaptop } = resolutionStore.resolutionState;
  return (
    <div className={cn(scss.layout, props.className)}>
      <div className={scss.layoutContent}>{props.children}</div>
      {isLaptop && props.enabledSidebar && !!props.sidebar && (
        <div className={scss.layoutSidebar}>{props.sidebar}</div>
      )}
    </div>
  );
};

export default observer(ContentLayout);
