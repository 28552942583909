import {makeAutoObservable} from "mobx";
import {ReactNode} from "react";
import {PopupPlacement} from "../components/Popup/utils/popupPosition";

export type PopupOptions = {
    targetKey?: string;
    placement?: PopupPlacement;
    indentY?: number;
    indentX?: number;
    disableMouseDownHandler?: boolean;
    closeByOverlay?: boolean;
    closeByEsc?: boolean;
    disableOverlay?: boolean;
};

export interface IPopupState extends PopupOptions {
    open: boolean;
    Component?: ReactNode;
    target?: EventTarget;
    indentY?: number;
    indentX?: number;
}

export type PopupCloseType = "OUTSIDE" | "SCROLL" | "ESC" | "CUSTOM" | "ROUTE_CHANGE_INITIATOR";

class PopupStore {
    _state: IPopupState = {open: false};
    _tempComponent?: ReactNode;
    _defaultProps: PopupOptions = {closeByEsc: true, closeByOverlay: true, disableMouseDownHandler: true, indentY: 8};

    constructor() {
        makeAutoObservable(this);
    }

    openPopup = (target: HTMLElement, Component: ReactNode, options?: PopupOptions) => {
        const targetKey = options?.targetKey || `${target.offsetHeight}-${target.offsetWidth}-${target.offsetTop}-${target.offsetLeft}`;
        const isOpen = this.state.open;
        if (isOpen && this.state.targetKey && this.state.targetKey === targetKey) {
            return this.closePopup();
        }

        this._state = {
            open: true,
            Component: target === this._state.target ? Component : this._tempComponent,
            target,
            ...this._defaultProps,
            ...options,
            targetKey
        };

        this._tempComponent = Component;
    };

    ready = () => {
        this._state.Component = this._tempComponent;
    };

    closePopup = () => {
        this._close();
    };

    public checkOpenMenuByTargetKey = (targetKey: string) => {
        return this.state.open ? (this.state.targetKey ? this.state.targetKey === targetKey : false) : false;
    };

    get state() {
        return this._state;
    }

    private _close = () => {
        this._state = {open: false};
        this._tempComponent = null;
    };
}

export default new PopupStore();
