import { memo, SVGProps } from "react";

const BehanceBGIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="48"
      viewBox="0 0 32 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_4117_21255"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="48"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0L28 0C30.2091 0 32 1.79086 32 4V44C32 46.2091 30.2091 48 28 48H4C1.79086 48 0 46.2091 0 44V4Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_4117_21255)">
        <g opacity="0.15">
          <g clipPath="url(#clip0_4117_21255)">
            <path
              d="M11.0802 27.9837C10.4425 25.6037 8.83904 24.1478 6.32353 24.115C7.81225 22.8451 8.37228 21.3754 7.85912 19.4603C6.84731 15.6842 3.77749 15.5198 0.520391 16.3925L-8.44727 18.7954L-3.36717 37.7546L5.85163 35.2844C9.30836 34.3582 12.1124 31.8358 11.0802 27.9837ZM-3.39952 20.9117L0.523426 19.8606C2.03156 19.4565 3.5022 19.5152 3.96945 21.259C4.40082 22.8689 3.51832 23.7997 2.02435 24.2L-2.21413 25.3357L-3.39952 20.9117ZM-0.050656 33.415L-1.44966 28.1939L3.10694 26.9729C4.94735 26.4798 6.31659 26.9329 6.83699 28.875C7.35015 30.7901 6.12097 31.7613 4.41965 32.2172L-0.050656 33.415Z"
              fill="#5780FF"
            />
            <path
              d="M20.9368 31.6276C24.2261 30.7462 25.9604 28.6982 26.1443 25.2836L22.806 26.1781C22.7607 27.4464 21.4456 28.4627 20.2968 28.7705C18.0804 29.3644 16.5693 28.382 15.9799 26.1823L25.9134 23.5206C25.0482 19.0344 21.5907 15.9398 17.0573 17.1545C12.8601 18.2791 10.8539 22.1875 11.9579 26.3075C13.1036 30.5834 16.5888 32.7926 20.9368 31.6276ZM17.7035 20.0347C19.6031 19.5257 20.8569 20.3824 21.5042 22.1594L15.3494 23.8086C14.9908 21.9708 15.8901 20.5206 17.7035 20.0347Z"
              fill="#5780FF"
            />
            <path
              d="M11.9971 14.3848L19.709 12.3184L20.2094 14.1858L12.4975 16.2522L11.9971 14.3848Z"
              fill="#5780FF"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4117_21255">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(-10 13) rotate(-15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(BehanceBGIcon);
