import React from "react";
import ImageBlock from "./components/ImageBlock/ImageBlock";
import { ContentBlockType, PageSection } from "../../../../../../api/contracts";
import BlockCases from "../../../../../../components/blocks/BlockCases/BlockCases";
import BlockReviews from "../../../../../../components/blocks/BlockReviews/BlockReviews";
import BlockContactUs from "../../../../../../components/blocks/BlockContactUs/BlockContactUs";
import BlockSeparator from "../../../../../../components/blocks/BlockSeparator/BlockSeparator";
import BlockEmbed from "../../../../../../components/blocks/BlockEmbed/BlockEmbed";
import TextBlock from "./components/TextBlock/TextBlock";
import ListBlock from "./components/ListBlock/ListBlock";
import QuoteBlock from "./components/QuoteBlock/QuoteBlock";
import BlockPosts from "../../../../../../components/blocks/BlockPosts/BlockPosts";
import BlockStories from "../../../../../../components/blocks/BlockStories/BlockStories";

interface IProps {
  blocks: PageSection["blocks"];
  pageWithSidebar?: boolean;
  withoutPaddings?: boolean;
}

const ContentSectionBlocksWrapper = ({
  blocks,
  pageWithSidebar,
  withoutPaddings,
}: IProps) => {
  const blocksWithSteps: string[] = [];
  return (
    <>
      {blocks?.map((block, i) => {
        switch (block?.blockType) {
          case ContentBlockType.IMAGE_CONTENT_BLOCK:
            return (
              <ImageBlock
                key={block.id}
                block={block}
                roundBorders={!withoutPaddings}
              />
            );
          case ContentBlockType.TEXT_CONTENT_BLOCK: {
            if (block.stepTitle) blocksWithSteps.push(block.stepTitle);
            return (
              <TextBlock
                key={block.id}
                index={i}
                block={block}
                stepNumber={
                  block.stepTitle
                    ? blocksWithSteps.indexOf(block.stepTitle) + 1
                    : undefined
                }
              />
            );
          }
          case ContentBlockType.QUOTE_CONTENT_BLOCK:
            return <QuoteBlock key={block.id} block={block} />;
          case ContentBlockType.LIST_CONTENT_BLOCK:
            return <ListBlock key={block.id} block={block} />;
          case ContentBlockType.CASES_CONTENT_BLOCK:
            return <BlockCases key={block.id} block={block} />;
          case ContentBlockType.POSTS_CONTENT_BLOCK:
            return <BlockPosts key={block.id} block={block} />;
          case ContentBlockType.REVIEWS_CONTENT_BLOCK:
            return <BlockReviews key={block.id} block={block} />;
          case ContentBlockType.STORIES_CONTENT_BLOCK:
            return <BlockStories key={block.id} block={block} />;
          case ContentBlockType.CONTACT_US_CONTENT_BLOCK:
            return (
              <BlockContactUs
                key={block.id}
                theme={block.theme}
                pageWithSidebar={pageWithSidebar}
              />
            );
          case ContentBlockType.SEPARATOR_CONTENT_BLOCK:
            return <BlockSeparator key={block.id} block={block} />;
          case ContentBlockType.EMBED_CONTENT_BLOCK:
            return <BlockEmbed key={block.id} block={block} />;
          default:
            return null;
        }
      })}
    </>
  );
};

export default ContentSectionBlocksWrapper;
