import {useEffect, useRef, useState} from "react";

const useResizeObserver = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerSize, setContainerSize] = useState({height: 0, width: 0});

    const containerSizeRef = useRef({
        height: containerSize.height,
        width: containerSize.width
    });

    useEffect(() => {
        const node = containerRef.current as HTMLDivElement
        const ro = new ResizeObserver(([entry]) => {
            const {clientHeight, clientWidth} = entry.target;
            if (containerSizeRef.current.height !== clientHeight || containerSizeRef.current.width !== clientWidth) {
                setContainerSize({width: clientWidth, height: clientHeight});
                containerSizeRef.current = {width: clientWidth, height: clientHeight};
            }
        });
        ro.observe(node);
        return () => {
            ro.unobserve(node);
        };
    }, []);

    return {containerRef, containerSize};
};

export default useResizeObserver;
