import React from "react";
import scss from "./styles.module.scss";

interface IProps {
  skills: string[];
}

const TeamMemberSkillsPopup = ({ skills }: IProps) => {
  return (
    <div className={scss.skillsPopupWrapper}>
      <p className={scss.skillsPopupTitle}>all skills</p>
      <div className={scss.skillsPopupContent}>
        {skills.map((item, i) => (
          <span key={i} className={scss.skillPopupItem}>
            {item}
          </span>
        ))}
      </div>
    </div>
  );
};

export default TeamMemberSkillsPopup;
