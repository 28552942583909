import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// import Medical from "../pages/Medical/Medical";
// import Fintech from "../pages/Fintech/Fintech";
// import Crypto from "../pages/Crypto/Crypto";
// import Blog from "../pages/Blog/Blog";
import PageLayout from "../components/Layouts/PageLayout/PageLayout";
import Page from "../pages/Page/Page";
import NotFound from "../pages/NotFound/NotFound";
import Home from "../pages/Home/Home";
import Post from "../pages/Post/Post";

const Router = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route element={<PageLayout location={location} />}>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to="/" replace />} />
        {/*<Route path="/medical" element={<Medical />} />*/}
        {/*<Route path="/fintech" element={<Fintech />} />*/}
        {/*<Route path="/crypto" element={<Crypto />} />*/}
        {/*<Route path="/blog" element={<Blog />} />*/}
        <Route path="/blog/:slug" element={<Post />} />
        <Route path="/:slug" element={<Page />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;
