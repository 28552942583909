import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { DateRange, PageBlockCase, Tag } from "../../../../../api/contracts";
import { observer } from "mobx-react-lite";
import resolutionStore from "../../../../../store/ResolutionStore";
import Button from "../../../../shared/Button/Button";
import cn from "classnames";
import { getYearsDateRange } from "../../../../../utils/dateUtils";
import { Link, useNavigate } from "react-router-dom";
import scss from "./styles.module.scss";

interface IProps {
  caseCard: PageBlockCase;
  variant?: "default" | "secondary" | "alternate";
  className?: string;
  onClick?: () => void;
}

const CaseCardItem = ({
  caseCard,
  variant = "default",
  className,
  onClick,
}: IProps) => {
  const { phone, phoneLg, tablet, tabletLg } = resolutionStore.resolution;
  const isSmallImage = phone || phoneLg || tablet;
  const navigate = useNavigate();
  const caseTagsHiddenRef = useRef<HTMLDivElement>(null);
  const [hideTags, setHideTags] = useState(false);
  const hideTagsRef = useRef<boolean>(hideTags);

  useEffect(() => {
    const node = caseTagsHiddenRef.current as HTMLDivElement;
    const ro = new ResizeObserver(([entry]) => {
      const { scrollWidth, clientWidth } = entry.target;
      if (scrollWidth > clientWidth && !hideTagsRef.current) {
        hideTagsRef.current = true;
        setHideTags(true);
      } else if (scrollWidth === clientWidth && hideTagsRef.current) {
        hideTagsRef.current = false;
        setHideTags(false);
      }
    });
    if (node) ro.observe(node);
    return () => {
      if (node) ro.unobserve(node);
    };
  }, []);

  switch (variant) {
    case "secondary":
      return (
        <Link
          to={`/${caseCard.slug}`}
          className={cn(scss.caseCardWrapper, scss[variant], className)}
        >
          <div
            className={scss.caseCardSecondaryImage}
            style={{
              backgroundImage: `url(${caseCard.preview})`,
            }}
          />
          <div className={scss.caseCardSecondaryTextBlockWrapper}>
            <div className={scss.caseCardSecondaryTextWrapper}>
              <h3 className={scss.caseCardSecondaryTitle}>{caseCard.title}</h3>
              <p className={scss.caseCardSecondaryDescription}>
                {caseCard.description}
              </p>
            </div>
            <div className={scss.caseCardSecondaryTagsWrapper}>
              <CaseTags
                hideTags={hideTags}
                tags={caseCard.tags}
                date={caseCard?.dateRange}
                isMobile={phoneLg || phone}
              />
              <CaseTags
                tags={caseCard.tags}
                date={caseCard?.dateRange}
                isMobile={phoneLg || phone}
                className={scss.hiddenTags}
                ref={caseTagsHiddenRef}
              />
            </div>
          </div>
        </Link>
      );

    case "default":
      return (
        <div
          className={cn(scss.caseCardWrapper, scss[variant], className)}
          onClick={() => {
            if (isSmallImage || tabletLg) {
              navigate(`/${caseCard.slug}`);
              if (onClick) onClick();
            }
          }}
        >
          <div className={scss.caseCardImageSection}>
            <img
              src={isSmallImage ? caseCard.preview : caseCard.previewMobile}
              alt={caseCard.title}
            />
            <div className={scss.tagsWrapper}>
              {caseCard.tags?.map(({ title }, i) => (
                <p className={scss.tag} key={i}>
                  {title}
                </p>
              ))}
            </div>
          </div>
          {!(isSmallImage || tabletLg) && (
            <div className={scss.caseCardContentSection}>
              <p className={scss.caseCardCaption}>About the project</p>
              <div className={scss.caseCardContentWrapper}>
                <h3 className={scss.caseCardTitle}>{caseCard.title}</h3>
                <p className={scss.caseCardDescription}>
                  {caseCard.description}
                </p>
                <Button
                  className={scss.caseCardButton}
                  variant="success"
                  fullWidth
                  onClick={() => {
                    navigate(`/${caseCard.slug}`);
                    if (onClick) onClick();
                  }}
                >
                  Open case
                </Button>
              </div>
            </div>
          )}
        </div>
      );

    case "alternate":
      return (
        <div className={cn(scss.caseCardWrapper, scss[variant], className)}>
          <div className={scss.caseCardImageSection}>
            <img
              src={isSmallImage ? caseCard.preview : caseCard.previewMobile}
              alt={caseCard.title}
            />
            <div className={scss.tagsWrapper}>
              {caseCard.tags?.map(({ title }, i) => (
                <p className={scss.tag} key={i}>
                  {title}
                </p>
              ))}
            </div>
          </div>
          <div className={scss.caseCardContentSection}>
            <p className={scss.caseCardCaption}>About the project</p>
            <div className={scss.caseCardContentWrapper}>
              <h3 className={scss.caseCardTitle}>{caseCard.title}</h3>
              <p className={scss.caseCardDescription}>{caseCard.description}</p>
            </div>
            <div className={scss.caseCardButtonWrapper}>
              <Button
                className={scss.caseCardButton}
                variant="success"
                fullWidth
                onClick={() => {
                  navigate(`/${caseCard.slug}`);
                  if (onClick) onClick();
                }}
              >
                Open case
              </Button>
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default observer(CaseCardItem);

interface CaseTagsProps {
  tags?: Tag[];
  date?: DateRange;
  isMobile: boolean;
  className?: string;
  hideTags?: boolean;
}

const CaseTags = forwardRef(
  (
    { tags, date, isMobile, className, hideTags }: CaseTagsProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={cn(scss.caseCardSecondaryTagsInnerWrapper, className)}
      >
        <div className={scss.caseCardSecondaryTagsInnerContainer}>
          {!isMobile && date ? (
            <div className={scss.caseCardSecondaryTag}>
              {getYearsDateRange(date)}
            </div>
          ) : null}
          {!hideTags &&
            tags?.slice(0, 1).map(({ title }, i) => (
              <div className={scss.caseCardSecondaryTag} key={i}>
                {title}
              </div>
            ))}
        </div>
      </div>
    );
  }
);
