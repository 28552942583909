import { DateRange } from "../api/contracts";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getDateWithDay = (date: string) => {
  if (!date) return null;
  const _date = new Date(date);
  return `${_date.getDate()} ${
    months[_date.getMonth()]
  } ${_date.getFullYear()}`;
};

export const getMonthDateRange = (dateRange: DateRange | undefined) => {
  if (!dateRange) return null;
  const start = new Date(dateRange.start);

  if (dateRange.end) {
    const end = new Date(dateRange.end);
    return `${months[start.getMonth()]}. ${start.getFullYear()} - ${
      months[end.getMonth()]
    }. ${end.getFullYear()}`;
  }
  return `${months[start.getMonth()]}. ${start.getFullYear()} - NOW`;
};

export const getYearsDateRange = (dateRange: DateRange | undefined) => {
  if (!dateRange) return null;
  const start = new Date(dateRange.start);

  if (dateRange.end) {
    const end = new Date(dateRange.end);

    return start.getFullYear() === end.getFullYear()
      ? end.getFullYear()
      : `${start.getFullYear()} - ${end.getFullYear()}`;
  }
  return `${start.getFullYear()} - NOW`;
};
