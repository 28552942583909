import React from "react";
import Button from "../../../../../../shared/Button/Button";
// import SearchIcon from "../../../../../../icons/SearchIcon";
import { PostsCurrentType } from "../../PostsWithMainPostAndPagination";
// import Input from "../../../../../../components/shared/Input/Input";
import cn from "classnames";
// import FilterDropdownMenu from "./FilterDropdownMenu/FilterDropdownMenu";
import scss from "./styles.module.scss";

interface IProps {
  postsCurrentType?: PostsCurrentType;
  clickHandler?: (type: PostsCurrentType) => () => void;
}

const SearchAndFilter = ({ postsCurrentType, clickHandler }: IProps) => {
  // const [searchValue, setSearchValue] = useState<string>("");
  return (
    <div className={cn(scss.searchBar)}>
      {/*<Input*/}
      {/*  value={searchValue}*/}
      {/*  setValue={setSearchValue}*/}
      {/*  placeholder={"SEARCH..."}*/}
      {/*  classname={scss.searchButton}*/}
      {/*  iconBefore={<SearchIcon color={"var(grey-1)"} />}*/}
      {/*/>*/}
      {/*{isMobileOrTablet ? (*/}
      {/*  <FilterDropdownMenu*/}
      {/*    postsCurrentType={postsCurrentType}*/}
      {/*    clickHandler={clickHandler}*/}
      {/*  />*/}
      {/*) : (*/}
      <div className={cn(scss.filterButtonsWrapper, "hiddenScroll")}>
        {Object.values(PostsCurrentType || {}).map((item) => (
          <Button
            key={item}
            className={cn(scss.filterButton, {
              [scss.active]: postsCurrentType === item,
            })}
            rounding="small"
            variant={postsCurrentType === item ? "success" : "outline"}
            onClick={
              clickHandler ? clickHandler(item as PostsCurrentType) : undefined
            }
          >
            {item}
          </Button>
        ))}
      </div>
      {/*)}*/}
    </div>
  );
};

export default SearchAndFilter;
