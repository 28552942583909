import React from "react";
import scss from "./styles.module.scss";
import { PageBlockQuote } from "../../../../../../../../api/contracts";
import QuoteOpenIcon from "../../../../../../../../icons/QuoteOpenIcon";
import cn from "classnames";

interface IProps {
  block: PageBlockQuote;
}

const QuoteBlock = ({ block }: IProps) => {
  return (
    <div className={cn(scss.container)}>
      <div className={scss.icon}>
        <QuoteOpenIcon />
      </div>
      <div className={scss.subContainer}>
        <blockquote className={scss.text}>{block.title}</blockquote>
        <div className={cn(scss.icon, scss.closeIcon)}>
          <QuoteOpenIcon />
        </div>
      </div>
    </div>
  );
};

export default QuoteBlock;
