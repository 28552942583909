import { memo, SVGProps } from "react";

const DataRootLabsLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="194"
      height="56"
      viewBox="0 0 194 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.1926 12.1172C41.4807 12.1172 42.5249 11.0498 42.5249 9.73302C42.5249 8.41626 41.4807 7.34882 40.1926 7.34882C38.9045 7.34882 37.8604 8.41626 37.8604 9.73302C37.8604 11.0498 38.9045 12.1172 40.1926 12.1172Z"
        fill="currentColor"
      />
      <path
        d="M78.0774 12.1172C79.3655 12.1172 80.4098 11.0498 80.4098 9.73302C80.4098 8.41626 79.3655 7.34882 78.0774 7.34882C76.7894 7.34882 75.7451 8.41626 75.7451 9.73302C75.7451 11.0498 76.7894 12.1172 78.0774 12.1172Z"
        fill="currentColor"
      />
      <path
        d="M78.0774 47.7458C79.3655 47.7458 80.4098 46.6784 80.4098 45.3616C80.4098 44.0448 79.3655 42.9774 78.0774 42.9774C76.7894 42.9774 75.7451 44.0448 75.7451 45.3616C75.7451 46.6784 76.7894 47.7458 78.0774 47.7458Z"
        fill="currentColor"
      />
      <path
        d="M40.4667 45.4827C40.1834 45.4827 39.9533 45.2491 39.9512 44.9595V44.9068C39.9501 44.7671 40.0036 44.6327 40.0995 44.5334C40.1956 44.434 40.3262 44.3777 40.4629 44.377C40.7459 44.3769 40.9758 44.6107 40.9774 44.9001V44.948C40.9796 45.0879 40.9274 45.2228 40.832 45.3232C40.7368 45.4235 40.6063 45.4809 40.4695 45.4827H40.4667Z"
        fill="currentColor"
      />
      <path
        d="M40.1926 47.7458C41.4807 47.7458 42.5249 46.6784 42.5249 45.3616C42.5249 44.0448 41.4807 42.9774 40.1926 42.9774C38.9045 42.9774 37.8604 44.0448 37.8604 45.3616C37.8604 46.6784 38.9045 47.7458 40.1926 47.7458Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0371 10.6249C38.396 11.5101 39.2413 12.087 40.1786 12.0866C41.4575 12.0846 42.4935 11.0251 42.495 9.71777C42.495 8.75955 41.9303 7.89572 41.0643 7.52912C40.1983 7.16251 39.2015 7.36536 38.5389 8.04304C37.8761 8.72074 37.6781 9.73979 38.0371 10.6249ZM38.1214 8.8475C38.4662 7.99714 39.2782 7.44289 40.1786 7.44327C41.4068 7.44481 42.4021 8.46223 42.4036 9.71777C42.404 10.6383 41.8618 11.4683 41.03 11.8207C40.1981 12.1732 39.2405 11.9786 38.6038 11.3277C37.9671 10.6768 37.7767 9.69786 38.1214 8.8475Z"
        fill="currentColor"
      />
      <path
        d="M115.489 12.0866C116.769 12.0866 117.807 11.026 117.807 9.71773C117.807 8.40942 116.769 7.34882 115.489 7.34882C114.209 7.34882 113.172 8.40942 113.172 9.71773C113.172 11.026 114.209 12.0866 115.489 12.0866Z"
        fill="currentColor"
      />
      <path
        d="M153.725 12.0866C155.005 12.0866 156.042 11.026 156.042 9.71773C156.042 8.40942 155.005 7.34882 153.725 7.34882C152.444 7.34882 151.407 8.40942 151.407 9.71773C151.407 11.026 152.444 12.0866 153.725 12.0866Z"
        fill="currentColor"
      />
      <path
        d="M115.489 47.6204C116.769 47.6204 117.807 46.5598 117.807 45.2515C117.807 43.9431 116.769 42.8826 115.489 42.8826C114.209 42.8826 113.172 43.9431 113.172 45.2515C113.172 46.5598 114.209 47.6204 115.489 47.6204Z"
        fill="currentColor"
      />
      <path
        d="M153.725 47.6204C155.005 47.6204 156.042 46.5598 156.042 45.2515C156.042 43.9431 155.005 42.8826 153.725 42.8826C152.444 42.8826 151.407 43.9431 151.407 45.2515C151.407 46.5598 152.444 47.6204 153.725 47.6204Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.32529 23.2666C9.60004 23.2666 10.6604 23.7039 11.481 24.5679V19.2086H13.5276V35.4616H11.6564V34.4839C11.2966 34.8277 10.8844 35.1125 10.4353 35.3276C9.77842 35.6457 9.05538 35.8042 8.32529 35.7902C6.75232 35.7902 5.46979 35.207 4.52444 34.0688C3.5791 32.9307 3.09961 31.4076 3.09961 29.5288C3.09961 27.65 3.59859 26.127 4.59364 24.9859C5.58868 23.8448 6.84394 23.2666 8.32529 23.2666ZM11.481 27.8833C11.481 27.1874 11.2208 26.5469 10.7013 25.9803C10.1809 25.4165 9.47822 25.1308 8.61084 25.1297C7.49788 25.1297 6.65292 25.504 6.06818 26.2669C5.48342 27.0299 5.19106 28.1283 5.19106 29.5279C5.19106 30.9275 5.49415 32.0326 6.06818 32.7878C6.6422 33.543 7.49788 33.925 8.61084 33.925C9.42657 33.925 10.1205 33.6732 10.6633 33.1883C11.2062 32.7033 11.481 32.1279 11.481 31.4796V27.8833Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8228 23.9467C23.1239 23.9467 24.1995 24.2695 25.0219 24.9058C25.853 25.5485 26.2742 26.4302 26.2733 27.5253V35.4732H24.4402V34.4617C24.1315 34.8003 23.7602 35.0807 23.3456 35.2893C22.7265 35.623 21.9461 35.7913 21.0243 35.7913C19.7347 35.7913 18.7318 35.4842 18.044 34.8718C17.364 34.2786 16.9847 33.4306 17.0047 32.5474C17.0047 31.6022 17.3295 30.8437 17.938 30.315C18.5465 29.7862 19.5676 29.3412 20.9737 28.9918C22.1534 28.7049 22.9968 28.4356 23.4811 28.1919C23.9387 27.963 24.1613 27.6329 24.1613 27.1732C24.1613 26.752 23.9722 26.4118 23.5633 26.124C23.1545 25.8362 22.5909 25.69 21.8869 25.69C20.4139 25.69 19.5427 26.3732 19.2228 27.779L19.195 27.8977L17.3333 27.379L17.3514 27.278C17.5091 26.3308 18.0274 25.474 18.8053 24.8745C19.5924 24.2593 20.6079 23.9467 21.8228 23.9467ZM24.2692 31.8412V29.3798C23.8709 29.7449 22.9367 30.12 21.4456 30.5154C20.4904 30.7738 19.835 31.0515 19.4979 31.3429C19.1726 31.6201 18.9935 32.0229 19.0097 32.4407C19.0097 33.522 19.7108 34.048 21.1542 34.048C22.1562 34.048 22.9338 33.8356 23.4706 33.4044C24.0075 32.9731 24.2692 32.4619 24.2692 31.8412Z"
        fill="currentColor"
      />
      <path
        d="M36.2604 21.5775H34.3945V24.3316H30.9082V26.1104H34.3737V32.4118C34.3737 33.5241 34.6181 34.3736 35.1007 34.9358C35.5833 35.4981 36.3668 35.791 37.4209 35.791H39.4623V33.8845H37.501C37.0102 33.8845 36.683 33.7654 36.5276 33.5326C36.3722 33.2997 36.2813 32.837 36.2813 32.2006V26.1104H40.1775V24.3316H36.2604V21.5775Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.631 23.9467C50.9318 23.9467 52.0073 24.2696 52.8297 24.906C53.6607 25.5489 54.0819 26.431 54.0819 27.5264V35.473H52.249V34.4614C51.9402 34.7999 51.5692 35.0805 51.1544 35.2891C50.5354 35.623 49.7552 35.7913 48.8335 35.7913C47.544 35.7913 46.5411 35.484 45.8534 34.8716C45.173 34.2784 44.7935 33.43 44.8132 32.5464C44.8132 31.6009 45.138 30.8422 45.7464 30.3133C46.3548 29.7844 47.3759 29.3392 48.7819 28.9897C49.9614 28.7028 50.8048 28.4333 51.289 28.1896C51.7466 27.9605 51.9692 27.6304 51.9692 27.1705C51.9692 26.7493 51.7799 26.4088 51.3712 26.121C50.9624 25.8331 50.3989 25.6869 49.6949 25.6869C48.2221 25.6869 47.3472 26.3739 47.031 27.7802L47.0033 27.8989L45.1418 27.3801L45.16 27.279C45.3176 26.3315 45.8359 25.4744 46.6137 24.8747C47.4008 24.2594 48.416 23.9467 49.631 23.9467ZM52.077 31.8437V29.3815C51.6759 29.7467 50.7447 30.1219 49.2537 30.5174C48.2985 30.7758 47.6433 31.0537 47.3062 31.3452C46.981 31.6225 46.8018 32.0254 46.8181 32.4434C46.8181 33.5251 47.5192 34.0511 48.9624 34.0511C49.9643 34.0511 50.7418 33.8387 51.2786 33.4073C51.8153 32.976 52.077 32.4645 52.077 31.8437Z"
        fill="currentColor"
      />
      <path
        d="M80.3494 24.6697C79.6727 24.1926 78.859 23.9464 77.922 23.9464C77.1809 23.9439 76.4533 24.136 75.8175 24.5018C75.3407 24.7616 74.93 25.1202 74.6153 25.5513V24.1392H72.6191V35.791H74.7057V29.3205C74.7057 28.3071 74.9859 27.4855 75.5384 26.8786C76.0584 26.2897 76.827 25.9568 77.6308 25.9722C78.7765 25.9722 79.4919 26.4932 79.8218 27.5667L79.8615 27.6945L81.8885 26.8672L81.8517 26.7633C81.5297 25.8538 81.024 25.1468 80.3494 24.6697Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.7395 25.5379C86.7585 24.4827 88.0117 23.9467 89.4627 23.9467C90.8997 23.9467 92.1398 24.4845 93.1343 25.5389C94.1289 26.5933 94.634 28.0503 94.634 29.8695C94.634 31.7024 94.1466 33.1577 93.1764 34.2084C92.2063 35.259 90.9709 35.7913 89.4627 35.7913C87.9544 35.7913 86.6854 35.2563 85.6964 34.2092C84.7075 33.1623 84.2061 31.6896 84.2061 29.8695C84.2061 28.0493 84.7206 26.5933 85.7395 25.5379ZM91.717 32.9397C92.3169 32.2108 92.6216 31.1776 92.6216 29.8695C92.6216 28.5467 92.3151 27.5033 91.7273 26.7881C91.1396 26.0729 90.3766 25.7099 89.4599 25.7099C88.5552 25.6906 87.6929 26.0858 87.1277 26.779C86.5204 27.4942 86.2129 28.5338 86.2129 29.8695C86.2129 31.2052 86.5204 32.2448 87.1277 32.959C87.6929 33.6522 88.5552 34.0474 89.4599 34.0281C90.3625 34.0281 91.1171 33.6687 91.717 32.9397Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.6429 25.5379C100.663 24.4827 101.915 23.9467 103.366 23.9467C104.8 23.9467 106.043 24.4845 107.038 25.5389C108.032 26.5933 108.537 28.0503 108.537 29.8695C108.537 31.7024 108.05 33.1577 107.08 34.2084C106.11 35.259 104.873 35.7913 103.366 35.7913C101.859 35.7913 100.589 35.2563 99.5998 34.2092C98.6108 33.1623 98.1094 31.6896 98.1094 29.8695C98.1094 28.0493 98.623 26.5933 99.6429 25.5379ZM105.623 32.9397C106.223 32.2108 106.528 31.1776 106.528 29.8695H106.525C106.525 28.5467 106.221 27.5033 105.634 26.7881C105.046 26.0729 104.283 25.7099 103.366 25.7099C102.461 25.6906 101.599 26.0858 101.034 26.779C100.427 27.4942 100.12 28.5338 100.12 29.8695C100.12 31.2052 100.427 32.2448 101.034 32.959C101.599 33.6522 102.461 34.0474 103.366 34.0281C104.27 34.0281 105.023 33.6687 105.623 32.9397Z"
        fill="currentColor"
      />
      <path
        d="M118.527 21.5775H116.66V24.3316H113.172V26.1104H116.64V32.4118C116.64 33.5241 116.883 34.3736 117.367 34.9358C117.851 35.4981 118.633 35.791 119.688 35.791H121.73V33.8845H119.764C119.272 33.8845 118.945 33.7654 118.79 33.5326C118.634 33.2997 118.543 32.837 118.543 32.2006V26.1104H122.441V24.3316H118.527V21.5775Z"
        fill="currentColor"
      />
      <path
        d="M145.372 19.2086H139.925V21.0888H143.46V33.9108H139.822V35.791H149.092V33.9108H145.372V19.2086Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.387 23.9467C158.687 23.9467 159.763 24.2695 160.586 24.9058C161.415 25.5485 161.837 26.4302 161.837 27.5253V35.4732H160.005V34.4617C159.696 34.8 159.324 35.0805 158.909 35.2893C158.291 35.623 157.511 35.7913 156.588 35.7913C155.299 35.7913 154.297 35.4842 153.608 34.8718C152.928 34.2789 152.549 33.4308 152.569 32.5474C152.569 31.6022 152.896 30.8437 153.503 30.315C154.11 29.7862 155.131 29.3412 156.537 28.9918C157.717 28.7049 158.561 28.4356 159.045 28.1919C159.501 27.963 159.725 27.6329 159.725 27.1732C159.725 26.752 159.535 26.4118 159.127 26.124C158.718 25.8362 158.154 25.69 157.452 25.69C155.978 25.69 155.107 26.3732 154.787 27.779L154.76 27.8977L152.897 27.379L152.917 27.278C153.074 26.3308 153.592 25.474 154.37 24.8745C155.156 24.2593 156.171 23.9467 157.387 23.9467ZM159.833 31.8412V29.3798C159.431 29.7449 158.501 30.12 157.009 30.5154C156.054 30.7738 155.4 31.0515 155.063 31.3429C154.737 31.62 154.557 32.0228 154.574 32.4407C154.574 33.522 155.276 34.048 156.718 34.048C157.719 34.048 158.498 33.8356 159.034 33.4044C159.572 32.9731 159.833 32.4619 159.833 31.8412Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.518 24.5679C169.334 23.7039 170.397 23.2666 171.673 23.2666C173.184 23.2666 174.453 23.8536 175.428 24.9975C176.405 26.1416 176.899 27.652 176.899 29.5288C176.899 31.4057 176.427 32.9258 175.474 34.0688C174.52 35.2118 173.243 35.7902 171.672 35.7902C170.942 35.8042 170.219 35.6456 169.561 35.3275C169.112 35.1126 168.7 34.8277 168.341 34.4839V35.4616H166.471V19.2086H168.518V24.5679ZM173.93 32.7888C174.514 32.0248 174.807 30.9285 174.807 29.5288C174.807 28.1292 174.501 27.0232 173.927 26.2689C173.352 25.5147 172.497 25.1317 171.383 25.1317C170.514 25.1317 169.812 25.4155 169.297 25.9812C168.78 26.5469 168.517 27.1874 168.517 27.8843V31.4805C168.517 32.1288 168.788 32.7003 169.334 33.1892C169.881 33.6781 170.571 33.926 171.387 33.926C172.5 33.926 173.345 33.5528 173.93 32.7888Z"
        fill="currentColor"
      />
      <path
        d="M190.799 32.4591C190.797 32.0804 190.752 31.7033 190.664 31.3338C190.553 30.9999 190.368 30.6917 190.123 30.4293C189.839 30.1057 189.387 29.8189 188.776 29.5772C188.001 29.287 187.204 29.0495 186.393 28.8666C185.215 28.5788 184.354 28.326 183.835 28.1155C183.208 27.8609 183.004 27.7 182.943 27.6091C182.853 27.4757 182.807 27.2523 182.807 26.9443C182.807 26.6365 183.02 26.3257 183.437 26.0527C183.871 25.7705 184.511 25.6271 185.327 25.6271C186.943 25.6271 187.971 26.2936 188.472 27.666L188.51 27.7726L190.343 27.2055L190.311 27.0997C190.039 26.185 189.45 25.3823 188.637 24.82C187.812 24.2409 186.692 23.9467 185.306 23.9467C183.921 23.9467 182.837 24.252 182.048 24.854C181.259 25.4562 180.835 26.1768 180.804 26.9821C180.774 27.803 181.064 28.4685 181.672 28.9603C182.279 29.4521 183.443 29.8952 185.254 30.3327C186.861 30.7142 187.889 31.0442 188.298 31.3118C188.688 31.5682 188.883 31.9442 188.883 32.4618C188.883 33.5575 187.921 34.0907 185.945 34.0907C183.778 34.0907 182.601 33.1797 182.342 31.3062L182.326 31.1859L180.375 31.5406L180.388 31.6455C180.538 32.8589 181.076 33.8626 181.99 34.6321C182.906 35.4015 184.243 35.7913 185.969 35.7913C187.635 35.7913 188.88 35.4686 189.67 34.8307C190.459 34.1928 190.845 33.3884 190.799 32.4591Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(DataRootLabsLogo);
