import { memo, SVGProps } from "react";

const ChartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.7778 20H2.22222C0.994923 20 0 19.0051 0 17.7778V2.22222C0 0.994923 0.994923 0 2.22222 0H17.7778C19.0051 0 20 0.994923 20 2.22222V17.7778C20 19.0051 19.0051 20 17.7778 20ZM2.22222 2.22222V17.7778H17.7778V2.22222H2.22222ZM15.5556 15.5556H13.3333V7.77778H15.5556V15.5556ZM11.1111 15.5556H8.88889V4.44444H11.1111V15.5556ZM6.66667 15.5556H4.44444V10H6.66667V15.5556Z"
      fill="currentColor"
    />
  </svg>
);

export default memo(ChartIcon);
