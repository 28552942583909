import { memo, SVGProps } from "react";

const ReleaseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.9411 5.40743L29.97 0.268918C29.6663 0.092747 29.3218 0 28.971 0C28.6203 0 28.2758 0.092747 27.972 0.268918L19.98 4.84541L11.988 0.268918C11.6843 0.092747 11.3397 0 10.989 0C10.6383 0 10.2937 0.092747 9.99001 0.268918L1.01898 5.40743C0.710357 5.58171 0.453371 5.83524 0.274327 6.14208C0.0952823 6.44892 0.000610104 6.79805 0 7.15372V17.4307C0.000610104 17.7864 0.0952823 18.1355 0.274327 18.4424C0.453371 18.7492 0.710357 19.0028 1.01898 19.177L9.01099 23.7335V32.8463C9.0116 33.202 9.10627 33.5511 9.28532 33.8579C9.46436 34.1648 9.72135 34.4183 10.03 34.5926L19.021 39.7311C19.3247 39.9073 19.6693 40 20.02 40C20.3707 40 20.7152 39.9073 21.019 39.7311L29.97 34.5926C30.2787 34.4183 30.5356 34.1648 30.7147 33.8579C30.8937 33.5511 30.9884 33.202 30.989 32.8463V23.7335L38.981 19.177C39.2896 19.0028 39.5466 18.7492 39.7257 18.4424C39.9047 18.1355 39.9994 17.7864 40 17.4307V7.15372C39.9954 6.79402 39.8948 6.44216 39.7085 6.13495C39.5222 5.82774 39.2571 5.57647 38.9411 5.40743ZM3.996 16.2666V8.23763L10.989 4.22316L17.982 8.23763V16.2666L10.989 20.281L3.996 16.2666ZM26.973 31.6821L19.98 35.6965L12.987 31.6821V23.6532L19.98 19.6387L26.973 23.6532V31.6821ZM35.964 16.2666L28.971 20.281L21.978 16.2666V8.23763L28.971 4.22316L35.964 8.23763V16.2666Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(ReleaseIcon);
