import React from "react";
import scss from "./styles.module.scss";
import { PageBlockList } from "../../../../../../../../api/contracts";
import cn from "classnames";

interface IProps {
  block: PageBlockList;
}

const ListBlock = ({ block }: IProps) => {
  return (
    <div className={cn(scss.container)}>
      <h5 className={scss.title}>{block.title}</h5>
      <ol className={scss.list}>
        {block.listItems.map((item, i) => (
          <li
            key={block.id + i}
            className={cn(scss.listItem, {
              [scss.numbered]: block.listType === "numbered",
            })}
          >
            {item.title}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default ListBlock;
