import React from "react";
import cn from "classnames";
import scss from "./styles.module.scss";

type IProps = {
  className?: string;
  lineClassName?: string;
};

const FigureSeparator = ({ className, lineClassName }: IProps) => {
  return (
    <div className={cn(scss.figureSeparatorWrapper, className)}>
      <div className={cn(scss.figureSeparator, scss.top, lineClassName)}>
        <div className={cn(scss.island)} />
      </div>
      <div className={cn(scss.figureSeparator, scss.bottom, lineClassName)}>
        <div className={cn(scss.island)} />
      </div>
    </div>
  );
};

export default FigureSeparator;
