import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import cn from "classnames";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide, SwiperRef } from "swiper/react";
import { PageBlockReview } from "../../../../../api/contracts";
import { useFetchCasePreview } from "../../../../../api/query";
import CaseCardItem from "../../../BlockCases/components/CaseCardItem/CaseCardItem";
import modalStore from "../../../../../store/ModalStore";
import CloseIcon from "../../../../../icons/CloseIcon";
import Icon from "../../../../shared/Icon/Icon";
import Button from "../../../../shared/Button/Button";
import ArrowChevronLeft from "../../../../../icons/ArrowChevronLeft";
import ArrowChevronRight from "../../../../../icons/ArrowChevronRight";
import PreloaderIcon from "../../../../../icons/PreloaderIcon";
import ModalReviewItem from "./components/ModalReviewItem/ModalReviewItem";
import scss from "./styles.module.scss";

type Props = {
  reviews: PageBlockReview[];
  reviewIndex: number;
};

const BlockReviewModal = ({ reviews, reviewIndex }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const closeIconRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<SwiperRef | null>(null);

  useEffect(() => {
    const onClose = (e: MouseEvent) => {
      if (
        containerRef.current === (e.target as Node) ||
        closeIconRef.current === (e.target as Node)
      )
        modalStore.closeModal();
    };

    window.addEventListener("click", onClose);

    return () => {
      window.removeEventListener("click", onClose);
    };
  }, []);

  return (
    <div className={scss.reviewModalWrapper} ref={containerRef}>
      <div className={scss.closeIconWrapper} ref={closeIconRef}>
        <Icon className={scss.closeIcon} onClick={modalStore.closeModal}>
          <CloseIcon />
        </Icon>
      </div>
      <Swiper
        ref={swiperRef}
        initialSlide={reviewIndex}
        slidesPerView={1}
        spaceBetween={72}
        loop={true}
        pagination={{
          clickable: true,
          horizontalClass: scss.pagination,
          bulletClass: scss.dot,
          bulletActiveClass: scss.activeDot,
        }}
        modules={[Pagination]}
        className={cn("mySwiper", scss.swiper)}
      >
        {reviews.map((item, i) => (
          <SwiperSlide key={i}>
            <div className={scss.reviewModalInnerWrapper}>
              <Button
                className={scss.buttonPrev}
                onClick={() => swiperRef.current?.swiper.slidePrev()}
                aria-label="Prev slide"
              >
                <ArrowChevronLeft />
              </Button>
              <Button
                className={scss.buttonNext}
                onClick={() => swiperRef.current?.swiper.slideNext()}
                aria-label="Next slide"
              >
                <ArrowChevronRight />
              </Button>
              <ModalReviewItem review={item} />
              {item.caseSlug ? (
                <CaseBlock caseSlug={item.caseSlug as string} />
              ) : (
                <EmptyCaseBlock />
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default observer(BlockReviewModal);

const CaseBlock = ({ caseSlug }: { caseSlug: string }) => {
  const { data, status } = useFetchCasePreview(caseSlug);

  if (status === "loading")
    return (
      <div className={scss.preloader}>
        <PreloaderIcon />
      </div>
    );

  if (!data) return null;

  return (
    <CaseCardItem
      caseCard={data}
      onClick={modalStore.closeModal}
      variant="alternate"
    />
  );
};

const EmptyCaseBlock = () => {
  return (
    <div
      style={{ backgroundImage: "url(/assets/emptyCaseBg.webp)" }}
      className={scss.emptyCaseWrapper}
    >
      <div className={scss.emptyCaseTitle}>
        The project is under a non-disclosure agreement
      </div>
    </div>
  );
};
