import React from "react";
import Icon from "../../components/shared/Icon/Icon";
import CloseIcon from "../../icons/CloseIcon";
import ContactForm from "./components/ContactForm/ContactForm";
import modalStore from "../../store/ModalStore";
import Telegram from "../../components/shared/social/Telegram";
import WhatsApp from "../../components/shared/social/WhatsApp";
import PhoneIcon from "../../icons/PhoneIcon";
import scss from "./styles.module.scss";

const ContactUs = () => {
  return (
    <div className={scss.root}>
      <div className={scss.container}>
        <div className={scss.mainSection}>
          <div className={scss.mainSectionInner}>
            <div className={scss.header}>
              <Icon
                className={scss.closeIcon}
                onClick={modalStore.closeModal}
                size="large"
              >
                <CloseIcon width={30} height={30} />
              </Icon>
              <div className={scss.team}>
                <div className={scss.teamItem}>
                  <img src="/assets/val-contact.webp" alt="valentin" />
                </div>
                <div className={scss.teamItem}>
                  <img src="/assets/artem-contact.webp" alt="artem" />
                </div>
                <div className={scss.teamItem}>
                  <img src="/assets/anastasia-contact.webp" alt="anastasia" />
                </div>
              </div>
              <div className={scss.headerText}>
                We will contact you <br />
                within <span>24 business hours</span>
              </div>
            </div>
            <div className={scss.content}>
              <div>
                Let's turn your{" "}
                <span className={scss.contentAccent}>vision</span> into a{" "}
                <span className={scss.contentAccent}>reality</span>
              </div>
              our team is ready to collaborate with you
            </div>
            <div className={scss.footer}>
              <div className={scss.footerText}>Schedule a call</div>
              <a
                className={scss.footerContact}
                href="https://calendly.com/umber-art/30min"
                target="_blank"
                rel="noreferrer"
              >
                <PhoneIcon />
                choose the time
              </a>
            </div>
          </div>
          <div className={scss.socialsMobile}>
            <a
              href="https://t.me/valan"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <Telegram size={48} />
            </a>
            <a
              href="https://wa.me/380964429365"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <WhatsApp size={48} />
            </a>
          </div>
        </div>
        <div className={scss.formSection}>
          <div className={scss.formSectionInner}>
            <ContactForm />
          </div>
        </div>
        <div className={scss.socialSection}>
          <div className={scss.socialSectionInner}>
            <Icon
              className={scss.closeIcon}
              onClick={modalStore.closeModal}
              size="large"
            >
              <CloseIcon width={30} height={30} />
            </Icon>
            <div className={scss.social}>
              <a
                href="https://t.me/valan"
                target="_blank"
                rel="nofollow noreferrer"
              >
                <Telegram size={80} />
              </a>
              <a
                href="https://wa.me/380964429365"
                target="_blank"
                rel="nofollow noreferrer"
              >
                <WhatsApp size={80} />
              </a>
            </div>
          </div>
        </div>
        <div className={scss.footer}>
          <div className={scss.footerText}>Schedule a call</div>
          <a
            className={scss.footerContact}
            href="https://calendly.com/umber-art/30min"
            target="_blank"
            rel="noreferrer"
          >
            <PhoneIcon />
            choose the time
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
