import React from "react";
import BreadCrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";
import Avatar from "../../../../components/shared/Avatar/Avatar";
import { observer } from "mobx-react-lite";
import PostStatistics from "../../../../components/blocks/BlockPosts/components/PostCard/components/PostStatistics/PostStatistics";
import { PageHeader } from "../../../../api/contracts";
import scss from "./styles.module.scss";

type IProps = {
  header: PageHeader;
  title: string;
  backgroundImage?: string;
};

const PostPageMainHeader = ({ header, title, backgroundImage }: IProps) => {
  const author = header.authors?.[0];
  const tags = header.tags.map((tag) => tag.title);

  return (
    <>
      <div
        className={scss.container}
        style={{
          backgroundImage: `url(${
            backgroundImage || "/assets/blogMainSectionBackground.webp"
          })`,
        }}
      >
        <div className={scss.contentSection}>
          <BreadCrumbs pageTitle={header.breadCrumbsTitle} />
          <h1 className={scss.postTitle}>{title}</h1>
          <p className={scss.postText}>{header.description}</p>
          <div>
            {author && (
              <div className={scss.authorContainer}>
                <Avatar title={author.firstName} avatar={author.avatar} />
                <div className={scss.authorName}>
                  {`${author.firstName} ${author.lastName}`}
                </div>
                <div
                  className={scss.authorRole}
                >{`Umber - ${author.shortRole}`}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {header.statistics && (
        <div className={scss.postInfoWrapper}>
          <PostStatistics
            statisticsInfo={header.statistics}
            contentColor={"var(--light-1)"}
          />
          <div className={scss.tagsInfo}>{tags.join(" | ")}</div>
        </div>
      )}
    </>
  );
};

export default observer(PostPageMainHeader);
