import { memo, SVGProps } from "react";

const ArrowChevronRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 8L1.90463 16L0 14.1178L6.19612 7.99467L0 1.87155L1.90463 0L10 8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(ArrowChevronRight);
