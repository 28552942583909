import { useEffect, useState } from "react";
import { runInAction } from "mobx";

interface UsePaginationProps {
  contentPerPage: number;
  count: number;
  seeAllItems?: boolean;
}

interface UsePaginationReturn {
  tempPage: number;
  totalPages: number;
  firstContentIndex: number;
  lastContentIndex: number;
  setPage: (page: number) => void;
  showMore?: () => void;
}

type UsePagination = (arg: UsePaginationProps) => UsePaginationReturn;

const usePagination: UsePagination = ({
  contentPerPage,
  count,
  seeAllItems,
}) => {
  const [pageContentCount, setPageContentCount] =
    useState<number>(contentPerPage);
  const [page, setPage] = useState(1);
  const [tempPage, setTempPage] = useState<number>(page);
  const pageCount = Math.ceil(count / contentPerPage);
  const lastContentIndex = tempPage * contentPerPage;
  const firstContentIndex = page * contentPerPage - contentPerPage;

  useEffect(() => {
    if (seeAllItems && tempPage > 1 && page > 1) {
      runInAction(() => {
        setPage(1);
        setTempPage(1);
      });
    }
  }, [seeAllItems, page, tempPage]);

  const setPageSAFE = (num: number) => {
    if (num > pageCount) {
      runInAction(() => {
        setPage(pageCount);
        setTempPage(pageCount);
      });
    } else if (num < 1) {
      runInAction(() => {
        setPage(1);
        setTempPage(1);
      });
    } else {
      runInAction(() => {
        setPage(num);
        setTempPage(num);
        setPageContentCount(contentPerPage);
      });
    }
  };

  const showMore = () => {
    if (page < pageCount) {
      runInAction(() => {
        setPageContentCount(contentPerPage + pageContentCount);
        setTempPage(tempPage + 1);
      });
    }
  };

  return {
    totalPages: pageCount,
    setPage: setPageSAFE,
    firstContentIndex,
    lastContentIndex,
    tempPage,
    showMore,
  };
};

export default usePagination;
