import {memo, SVGProps} from 'react';

const EyeIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.9332 5.7C14.3178 2.1825 11.2789 0 8 0C4.72114 0 1.6822 2.1825 0.0667645 5.7C0.0227292 5.79462 0 5.89676 0 6C0 6.10324 0.0227292 6.20538 0.0667645 6.3C1.6822 9.8175 4.72114 12 8 12C11.2789 12 14.3178 9.8175 15.9332 6.3C15.9773 6.20538 16 6.10324 16 6C16 5.89676 15.9773 5.79462 15.9332 5.7V5.7ZM8 10.5C5.45689 10.5 3.06572 8.7825 1.6822 6C3.06572 3.2175 5.45689 1.5 8 1.5C10.5431 1.5 12.9343 3.2175 14.3178 6C12.9343 8.7825 10.5431 10.5 8 10.5ZM8 3C7.36732 3 6.74885 3.17595 6.22279 3.50559C5.69674 3.83524 5.28673 4.30377 5.04461 4.85195C4.8025 5.40013 4.73915 6.00333 4.86258 6.58527C4.98601 7.16721 5.29067 7.70176 5.73805 8.12132C6.18542 8.54088 6.75541 8.8266 7.37593 8.94236C7.99645 9.05811 8.63964 8.9987 9.22416 8.77164C9.80868 8.54458 10.3083 8.16006 10.6598 7.66671C11.0113 7.17336 11.1989 6.59334 11.1989 6C11.1989 5.20435 10.8619 4.44129 10.262 3.87868C9.66205 3.31607 8.8484 3 8 3V3ZM8 7.5C7.68366 7.5 7.37442 7.41203 7.1114 7.2472C6.84837 7.08238 6.64337 6.84811 6.52231 6.57402C6.40125 6.29994 6.36958 5.99834 6.43129 5.70736C6.493 5.41639 6.64534 5.14912 6.86902 4.93934C7.09271 4.72956 7.3777 4.5867 7.68796 4.52882C7.99823 4.47094 8.31982 4.50065 8.61208 4.61418C8.90434 4.72771 9.15414 4.91997 9.32989 5.16664C9.50564 5.41332 9.59944 5.70333 9.59944 6C9.59944 6.39782 9.43093 6.77936 9.13098 7.06066C8.83102 7.34196 8.4242 7.5 8 7.5Z"
                fill="currentColor"/>
        </svg>

    );
};

export default memo(EyeIcon);