import { memo, SVGProps } from "react";

const FileCoinLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="139"
      height="56"
      viewBox="0 0 139 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6128 10.4165C11.1481 10.325 3.54751 17.9733 3.46875 27.3213C3.46875 36.7477 11.1481 44.396 20.534 44.396C29.9199 44.396 37.5073 36.8392 37.5992 27.5697C37.5861 18.0649 29.9986 10.508 20.6128 10.4165ZM28.7253 18.0649C28.384 18.3263 27.9639 18.2348 27.6226 17.8949C27.3601 17.6465 27.1894 17.385 26.94 17.1366C26.4281 16.4567 25.6667 16.3783 25.0628 16.9666C24.6297 17.3981 24.2096 17.9864 23.9602 18.5878C23.3695 20.3659 22.9363 22.2355 22.3455 24.1051L27.0319 24.7849L26.6906 26.0531L22.1749 25.3733L21.6629 28.0927L26.52 28.7725L26.1787 30.0407L21.4004 29.3609C21.0591 30.4722 20.8884 31.6488 20.4684 32.6686C20.0483 33.8583 19.6151 35.0481 19.1031 36.1463C18.4205 37.5975 17.226 38.6042 15.6113 38.8657C14.6793 39.0357 13.6554 38.9572 12.8809 38.3558C12.6183 38.1859 12.3689 37.8459 12.3689 37.5975C12.3689 37.2576 12.5396 36.8392 12.8021 36.6693C12.9728 36.5777 13.3928 36.6693 13.6554 36.7477C13.9179 37.0092 14.1673 37.336 14.338 37.676C14.85 38.3558 15.5326 38.4343 16.2152 37.9374C16.9765 37.2576 17.4097 36.3163 17.6592 35.388C18.1711 33.3484 18.6831 31.4004 19.1163 29.3609V29.0209L14.6005 28.3411L14.7712 27.0729L19.4576 27.7527L20.0483 25.1249L15.1913 24.3666L15.3619 23.0069L20.3896 23.6867C20.5602 23.1768 20.6521 22.7584 20.8228 22.327C21.2429 20.7973 21.6761 19.2677 22.7 17.908C23.7239 16.5483 24.9184 15.6984 26.7037 15.79C27.4651 15.79 28.2396 16.0384 28.7516 16.6398C28.8303 16.7313 29.001 16.9013 29.001 17.0712C28.9747 17.385 28.9747 17.8164 28.7253 18.0649Z"
        fill="currentColor"
      />
      <path
        d="M87.4644 29.9791C84.187 29.9791 80.9836 29.9791 77.7802 29.9791C77.5209 33.5937 80.2984 35.8436 83.8907 34.94C84.835 34.7002 85.7238 34.2392 86.7052 33.8519C86.8904 34.2576 87.1126 34.774 87.3533 35.3273C84.9461 37.1899 82.2982 37.6878 79.5022 36.6735C76.8173 35.6961 75.7989 33.4277 75.5211 30.7721C75.336 28.9464 75.5582 27.1759 76.4655 25.5346C78.206 22.3257 82.3168 21.2561 85.0943 23.3769C86.7608 24.631 87.2978 26.4567 87.4459 28.43C87.4829 28.891 87.4644 29.3705 87.4644 29.9791ZM77.7987 28.6329C80.2984 28.6329 82.7612 28.6329 85.2239 28.6329C85.6498 26.5305 84.2055 24.3728 82.2612 24.1699C79.8911 23.9302 77.8913 25.885 77.7987 28.6329Z"
        fill="currentColor"
      />
      <path
        d="M101.87 30.3481C101.944 27.4711 102.648 25.2765 104.722 23.7643C108.888 20.7214 114.387 23.1926 114.98 28.3563C115.202 30.2743 115.035 32.1369 114.147 33.8889C112.943 36.2679 110.554 37.5588 107.944 37.2822C105.036 36.9687 103 35.2905 102.24 32.5058C102.018 31.6759 101.944 30.8091 101.87 30.3481ZM112.925 29.7579C112.758 28.8912 112.684 27.9506 112.425 27.0654C111.906 25.3134 110.684 24.2991 109.073 24.17C107.129 24.0225 105.666 24.7786 104.87 26.4937C103.888 28.633 103.851 30.8276 104.814 32.9853C105.592 34.7003 107.203 35.5671 109.11 35.4196C110.758 35.2905 111.98 34.2209 112.48 32.3767C112.702 31.5468 112.776 30.68 112.925 29.7579Z"
        fill="currentColor"
      />
      <path
        d="M50.0605 17.66C53.5787 17.66 57.0599 17.66 60.6521 17.66C60.6151 18.3054 60.5595 18.9509 60.5225 19.6333C57.7635 19.6333 55.0601 19.6333 52.3196 19.6333C52.3196 21.8094 52.3196 23.9118 52.3196 26.1248C54.949 26.1248 57.5598 26.1248 60.2077 26.1248C60.2077 26.7887 60.2077 27.342 60.2077 27.969C57.5969 27.969 54.986 27.969 52.2826 27.969C52.2826 30.9566 52.2826 33.8888 52.2826 36.858C51.5049 36.858 50.8012 36.858 50.0605 36.858C50.0605 30.4586 50.0605 24.0778 50.0605 17.66Z"
        fill="currentColor"
      />
      <path
        d="M134.923 36.8949C134.219 36.8949 133.608 36.8949 132.905 36.8949C132.886 36.6182 132.868 36.3601 132.868 36.1019C132.831 33.2249 132.812 30.348 132.775 27.4711C132.775 27.2682 132.756 27.0653 132.719 26.8625C132.386 24.7601 131.405 24.0224 129.294 24.3359C128.035 24.5204 126.942 25.0736 126.239 26.1248C125.887 26.6412 125.628 27.3235 125.628 27.9321C125.553 30.6431 125.591 33.3725 125.591 36.0834C125.591 36.3232 125.591 36.5814 125.591 36.8764C124.887 36.8764 124.239 36.8764 123.535 36.8764C123.535 32.1737 123.535 27.4711 123.535 22.7499C124.128 22.7499 124.683 22.7499 125.313 22.7499C125.368 23.3585 125.424 23.9855 125.479 24.7048C125.665 24.5204 125.794 24.4097 125.924 24.2991C127.664 22.6946 129.701 22.1229 132.016 22.6208C133.108 22.8606 133.96 23.5245 134.33 24.5757C134.627 25.424 134.867 26.3277 134.886 27.2313C134.96 30.3111 134.923 33.3909 134.923 36.4707C134.96 36.5814 134.923 36.7105 134.923 36.8949Z"
        fill="currentColor"
      />
      <path
        d="M100.129 23.6536C99.9622 24.1331 99.8141 24.6126 99.6845 24.9998C98.7031 24.7601 97.7958 24.4281 96.8699 24.3175C94.8146 24.0777 93.3703 25.0552 92.4815 26.8256C91.4816 28.8173 91.4445 30.8828 92.3704 32.9114C93.4999 35.4195 96.444 36.3047 99.0179 34.9585C99.2401 34.8478 99.4623 34.7187 99.74 34.5527C99.8696 34.9769 100.036 35.3457 100.073 35.7515C100.092 35.9359 99.8881 36.1941 99.7215 36.3232C98.4809 37.1899 97.0551 37.4297 95.5923 37.2452C92.1482 36.858 89.8336 34.4052 89.5558 30.9381C89.3521 28.3378 89.8521 25.9773 91.8149 24.0962C93.9443 22.0491 97.9809 21.8278 100.129 23.6536Z"
        fill="currentColor"
      />
      <path
        d="M74.1144 35.3089C74.1699 35.9543 74.2255 36.5076 74.281 37.1346C73.2441 37.1346 72.2627 37.2453 71.3184 37.0977C70.1148 36.9133 69.5222 35.9912 69.3556 34.4606C69.2815 33.7044 69.2074 32.9668 69.2074 32.2106C69.1889 27.5448 69.2074 22.8975 69.2074 18.2317C69.2074 18.1026 69.226 17.9551 69.226 17.7706C69.9111 17.6784 70.5777 17.6047 71.3369 17.5125C71.3369 17.8628 71.3369 18.121 71.3369 18.3977C71.3554 23.2848 71.3554 28.1719 71.3739 33.059C71.3739 33.2434 71.3739 33.4278 71.3924 33.6122C71.5406 35.1798 72.059 35.5855 73.6329 35.3458C73.7626 35.3089 73.8922 35.3273 74.1144 35.3089Z"
        fill="currentColor"
      />
      <path
        d="M63.4111 22.713C64.0963 22.713 64.7258 22.713 65.4109 22.713C65.4109 27.4341 65.4109 32.1184 65.4109 36.8579C64.7629 36.8579 64.1148 36.8579 63.4111 36.8579C63.4111 32.1553 63.4111 27.471 63.4111 22.713Z"
        fill="currentColor"
      />
      <path
        d="M120.221 36.8948C119.517 36.8948 118.906 36.8948 118.221 36.8948C118.221 32.1921 118.221 27.471 118.221 22.7314C118.869 22.7314 119.517 22.7314 120.221 22.7314C120.221 27.4526 120.221 32.1368 120.221 36.8948Z"
        fill="currentColor"
      />
      <path
        d="M63.4111 20.1127C63.4111 19.2829 63.4111 18.4899 63.4111 17.66C64.0777 17.66 64.7258 17.66 65.4295 17.66C65.4295 18.4714 65.4295 19.2644 65.4295 20.1127C64.7629 20.1127 64.1333 20.1127 63.4111 20.1127Z"
        fill="currentColor"
      />
      <path
        d="M118.221 17.6416C118.887 17.6416 119.517 17.6416 120.202 17.6416C120.202 18.4715 120.202 19.2645 120.202 20.1128C119.535 20.1128 118.906 20.1128 118.221 20.1128C118.221 19.3198 118.221 18.5084 118.221 17.6416Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default memo(FileCoinLogo);
