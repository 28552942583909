import React from "react";
import { PageBlockImage } from "../../../../../../../../api/contracts";
import cn from "classnames";
import scss from "./styles.module.scss";

interface IProps {
  block: PageBlockImage;
  roundBorders?: boolean;
}

const ImageBlock = ({ block, roundBorders }: IProps) => {
  return (
    <img
      className={cn(scss.image, { [scss.roundBorders]: roundBorders })}
      src={block.url}
      alt={block.id}
    />
  );
};

export default ImageBlock;
