import React, { ReactNode } from "react";
import classNames from "classnames";
import scss from "./styles.module.scss";

interface IProps {
  children: ReactNode;
  className?: string;
  title?: string;
  subtitle?: string;
  titleMaxWidth?: number;
}

const Section = ({
  subtitle,
  children,
  title,
  className,
  titleMaxWidth,
}: IProps) => {
  return (
    <div className={classNames(scss.sectionContainer, className)}>
      {subtitle && <h3 className={scss.sectionSubtitle}>{subtitle}</h3>}
      {title && (
        <h2 className={scss.sectionTitle} style={{ maxWidth: titleMaxWidth }}>
          {title}
        </h2>
      )}
      {children}
    </div>
  );
};

export default Section;
