import { memo, SVGProps } from "react";

const DribbbleBGIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="40"
      height="48"
      viewBox="0 0 40 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_4117_21265"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="0"
        width="39"
        height="48"
      >
        <path
          d="M1.49333 5.12C0.746666 2.56 2.66667 0 5.33333 0L36 0C38.2091 0 40 1.79086 40 4V44C40 46.2091 38.2091 48 36 48H17C15.2222 48 13.6578 46.8267 13.16 45.12L1.49333 5.12Z"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_4117_21265)">
        <g opacity="0.15">
          <path
            d="M19.6052 24.4619C22.1369 27.8832 24.3134 31.5239 26.1345 35.384C27.0121 34.4863 27.7709 33.4548 28.4215 32.328C30.1185 29.3887 30.8103 26.0975 30.5126 22.823C27.9846 22.5065 25.3669 22.6281 22.7782 23.3218C21.6964 23.6117 20.6353 23.9788 19.6052 24.4619Z"
            fill="#D85888"
          />
          <path
            d="M22.6112 10.488C22.6008 10.4493 22.6008 10.4493 22.6112 10.488C18.888 8.33839 14.5895 7.75091 10.4553 8.85865C8.83258 9.29346 7.31059 9.94975 5.97697 10.8454C9.17997 13.2172 12.1643 15.8547 14.8422 18.7399C17.8592 16.3993 20.4608 13.5902 22.6112 10.488Z"
            fill="#D85888"
          />
          <path
            d="M24.2091 11.5089C21.9456 14.8071 19.2384 17.6858 16.1263 20.1348C16.9081 21.0434 17.7002 21.9906 18.4537 22.9482C19.7053 22.3644 20.9775 21.8579 22.3298 21.4955C24.9185 20.8019 27.5927 20.5823 30.2367 20.8677C30.1746 20.6359 30.1124 20.404 30.0503 20.1722C29.1082 16.6563 27.0588 13.6441 24.2091 11.5089Z"
            fill="#D85888"
          />
          <path
            d="M8.35275 39.031C11.6267 40.4313 15.2601 40.7001 18.7374 39.7684C20.9397 39.1783 22.9791 38.1349 24.7115 36.7182C22.8386 32.6649 20.5331 28.8517 17.9317 25.3248C12.849 28.3017 9.36374 33.3767 8.35275 39.031Z"
            fill="#D85888"
          />
          <path
            d="M14.5455 25.3624C15.2637 24.7973 16.0026 24.3094 16.7518 23.8602C16.0576 22.9695 15.3352 22.1278 14.5741 21.2965C10.8154 23.9601 6.61787 25.913 2.136 27.1139C1.09281 27.3934 0.0392581 27.6343 -1.02464 27.8366L-0.858998 28.4548C0.248741 32.5889 2.90676 36.0178 6.59133 38.1777C7.05754 35.8994 7.88462 33.7315 9.07255 31.674C10.4868 29.2245 12.3522 27.0682 14.5455 25.3624Z"
            fill="#D85888"
          />
          <path
            d="M13.3284 19.8912C10.5628 16.9881 7.5785 14.3507 4.34722 12.0279C2.93153 13.2354 1.70421 14.6824 0.732201 16.3094C-0.99313 19.2977 -1.67452 22.6275 -1.31753 25.969C-0.330902 25.7874 0.684009 25.5569 1.64993 25.2981C5.93862 24.1489 9.87606 22.3071 13.3284 19.8912Z"
            fill="#D85888"
          />
        </g>
      </g>
    </svg>
  );
};

export default memo(DribbbleBGIcon);
