import cn from "classnames";
import { PageBlockSeparator } from "../../../api/contracts";
import scss from "./styles.module.scss";

type Props = {
  block: PageBlockSeparator;
};
const BlockSeparator = (props: Props) => {
  return (
    <div
      className={cn(
        scss.separator,
        scss[props.block.theme],
        scss[`padding-top-${props.block.marginTop}`],
        scss[`padding-bottom-${props.block.marginBottom}`]
      )}
    >
      <div className={scss.line} />
    </div>
  );
};

export default BlockSeparator;
