import React from "react";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import ResolutionDetector from "./components/ResolutionDetector/ResolutionDetector";
import Sidebar from "./components/Sidebar/Sidebar";
import Popup from "./components/Popup/Popup";
import Modal from "./components/Modal/Modal";
import { queryClient } from "./api/apiClient";
import ScrollToTop from "./routes/ScrollToTop";
import GlobalPreloader from "./components/GlobalPreloader/GlobalPreloader";
import "./analytics";
import "swiper/css";

function App() {
  return (
    <>
      <ResolutionDetector />
      <GlobalPreloader />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Router />
          <ScrollToTop />
          <Sidebar key="sidebar" />
          <Popup key="popup" />
          <Modal key="modal" />
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
}

export default App;
