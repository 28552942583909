import React from "react";
import CaseCardItem from "../CaseCardItem/CaseCardItem";
import { PageBlockCase } from "../../../../../api/contracts";
import scss from "./styles.module.scss";

type Props = {
  cases: PageBlockCase[];
};

const BlockCasesDefaultView = ({ cases }: Props) => {
  return (
    <div className={scss.cases}>
      <div className={scss.gridLayout}>
        {cases.map((item, i) => (
          <CaseCardItem caseCard={item} variant="secondary" key={i} />
        ))}
      </div>
    </div>
  );
};

export default BlockCasesDefaultView;
