export const createPaginationArray = (
  currentPage: number,
  pagesLength: number
): { value: string | number; type: "number" | "dot" }[] => {
  if (pagesLength === 1) return [];
  if (pagesLength > 1 && pagesLength < 7) {
    return Array.from({ length: pagesLength }).map((_, i) => ({
      value: i + 1,
      type: "number",
    }));
  }
  if (currentPage < 5) {
    return [
      { value: 1, type: "number" },
      { value: 2, type: "number" },
      { value: 3, type: "number" },
      { value: 4, type: "number" },
      { value: 5, type: "number" },
      { value: "...", type: "dot" },
      { value: pagesLength, type: "number" },
    ];
  }
  if (currentPage > pagesLength - 4) {
    return [
      { value: 1, type: "number" },
      { value: "...", type: "dot" },
      { value: pagesLength - 4, type: "number" },
      { value: pagesLength - 3, type: "number" },
      { value: pagesLength - 2, type: "number" },
      { value: pagesLength - 1, type: "number" },
      { value: pagesLength, type: "number" },
    ];
  }
  return [
    { value: 1, type: "number" },
    { value: "...", type: "dot" },
    { value: currentPage - 1, type: "number" },
    { value: currentPage, type: "number" },
    { value: currentPage + 1, type: "number" },
    { value: "...", type: "dot" },
    { value: pagesLength, type: "number" },
  ];
};
