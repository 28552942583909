import { memo, SVGProps } from "react";

const TelegramIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="80" height="80" rx="40" fill="#4599DF" />
      <g clipPath="url(#clip0_4119_49818)">
        <path
          d="M35.695 45.3017L35.0333 54.6083C35.98 54.6083 36.39 54.2017 36.8817 53.7134L41.32 49.4717L50.5167 56.2067C52.2033 57.1467 53.3917 56.6517 53.8467 54.655L59.8833 26.3683L59.885 26.3667C60.42 23.8733 58.9833 22.8983 57.34 23.51L21.8567 37.095C19.435 38.035 19.4717 39.385 21.445 39.9967L30.5167 42.8183L51.5883 29.6333C52.58 28.9767 53.4817 29.34 52.74 29.9967L35.695 45.3017Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4119_49818">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(20 20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(TelegramIcon);
