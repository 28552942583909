// ********** POST PAGE POST CONTRACTS ********** //

export enum ContentBlockType {
  TEXT_CONTENT_BLOCK = "TEXT_CONTENT_BLOCK",
  IMAGE_CONTENT_BLOCK = "IMAGE_CONTENT_BLOCK",
  CASES_CONTENT_BLOCK = "CASES_CONTENT_BLOCK",
  POSTS_CONTENT_BLOCK = "POSTS_CONTENT_BLOCK",
  QUOTE_CONTENT_BLOCK = "QUOTE_CONTENT_BLOCK",
  LIST_CONTENT_BLOCK = "LIST_CONTENT_BLOCK",
  VIDEO_CONTENT_BLOCK = "VIDEO_CONTENT_BLOCK",
  REVIEWS_CONTENT_BLOCK = "REVIEWS_CONTENT_BLOCK",
  CONTACT_US_CONTENT_BLOCK = "CONTACT_US_CONTENT_BLOCK",
  SEPARATOR_CONTENT_BLOCK = "SEPARATOR_CONTENT_BLOCK",
  EMBED_CONTENT_BLOCK = "EMBED_CONTENT_BLOCK",
  STORIES_CONTENT_BLOCK = "STORIES_CONTENT_BLOCK",
}

export enum VideoType {
  UMBER_VIDEO = "UMBER_VIDEO",
  YOUTUBE_VIDEO = "YOUTUBE_VIDEO",
}

export enum WidgetType {
  CONTACT_US = "CONTACT_US",
}

export type Theme = "dark" | "light";

export type MarginType = "none" | "x-small" | "small" | "medium" | "large";

export enum PostSectionType {
  BASIC_CONTENT_SECTION = "BASIC_CONTENT_SECTION",
}

export type PaginationDto = {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
};

export type PostStatisticsDto = {
  createdDate: string;
  viewsCount: string;
  timeToRead: number;
};

// ********** TEAM PAGE CONTRACTS ********** //

export type TeamMemberDto = {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  fullRole: string;
  photo: string;
  backgroundImage: string;
  skills: string[];
  description: {
    title: string;
    text: string;
  }[];
};

export type StoryDto = {
  id: string;
  videoSrc: string;
  title: string;
  author: Partial<TeamMemberDto>;
};

export type PageType =
  | "CASE"
  | "POST"
  | "PAGE"
  | "HOME"
  | "WORKFLOW"
  | "TEAM"
  | "BLOG";

export type DateRange = {
  start: string;
  end?: string;
};

export type Tag = { id: number; title: string };

export type PageHeader = {
  breadCrumbsTitle: string;
  description: string;
  statistics?: {
    createdDate: string;
    viewsCount: string;
    timeToRead: number;
  };
  tags: Tag[];
  authors?: Array<{
    firstName: string;
    lastName: string;
    avatar: string;
    shortRole: string;
    fullRole: string;
  }>;
  dateRange?: DateRange;
};

export type PageSeo = {
  title: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogUrl?: string;
  ogType?: string;
  ogImage?: string;
  ogImageWidth?: number;
  ogImageHeight?: number;
};

export type PageBlockCase = {
  id: string;
  title: string;
  description: string;
  slug: string;
  dateRange?: DateRange;
  tags?: Tag[];
  preview?: string;
  previewMobile?: string;
};

export type PageBlockCases = {
  id: string;
  blockType: ContentBlockType.CASES_CONTENT_BLOCK;
  viewType: "default" | "with-pagination";
  cases: Array<PageBlockCase>;
};

export type PageBlockPost = {
  id: string;
  slug: string;
  title: string;
  description: string;
  tags?: Tag[];
  preview?: string;
  previewMobile?: string;
  postStatistics: PostStatisticsDto;
};

export type PageBlockPosts = {
  id: string;
  blockType: ContentBlockType.POSTS_CONTENT_BLOCK;
  viewType: "with-filtered-by-tag" | "with-main-post-and-pagination";
  filterByTag?: Tag;
  currentTag?: string;
  pagination: PaginationDto;
  mainPost?: PageBlockPost;
  posts: Array<PageBlockPost>;
};

export type PageBlockImage = {
  id: string;
  blockType: ContentBlockType.IMAGE_CONTENT_BLOCK;
  title: string;
  description: string;
  url: string;
};

export type PageBlockText = {
  id: string;
  blockType: ContentBlockType.TEXT_CONTENT_BLOCK;
  direction: "horizontal" | "vertical";
  title: string;
  stepTitle?: string;
  description: Array<{
    subtitle?: string;
    text: Array<{
      title: string;
    }>;
  }>;
};

export type PageBlockList = {
  id: string;
  blockType: ContentBlockType.LIST_CONTENT_BLOCK;
  listType: "numbered" | "dotted";
  title: string;
  description: string;
  listItems: Array<{ title: string }>;
};

export type PageBlockQuote = {
  id: string;
  blockType: ContentBlockType.QUOTE_CONTENT_BLOCK;
  title: string;
};

export type PageBlockContactUs = {
  id: string;
  blockType: ContentBlockType.CONTACT_US_CONTENT_BLOCK;
  theme: Theme;
};

export type PageBlockReviews = {
  id: string;
  blockType: ContentBlockType.REVIEWS_CONTENT_BLOCK;
  reviews: Array<PageBlockReview>;
};

export type PageBlockReview = {
  id: string;
  author: {
    authorName: string;
    authorRole: string;
    companyName: string;
    companyLocation: string;
    companyLogoImg?: string;
    companySmallLogoImg?: string;
    authorImg?: string;
  };
  dateRange: DateRange;
  content: {
    title: string;
    description: string[];
  };
  caseSlug?: string;
  clutchRating?: number;
};

export type PageBlockSeparator = {
  id: string;
  blockType: ContentBlockType.SEPARATOR_CONTENT_BLOCK;
  marginTop: MarginType;
  marginBottom: MarginType;
  theme: Theme;
};

export type PageBlockEmbed = {
  id: string;
  blockType: ContentBlockType.EMBED_CONTENT_BLOCK;
  html: string;
};

export type YouTubeVideo = {
  videoType: VideoType.YOUTUBE_VIDEO;
  videoId: string;
};

export type UmberVideo = {
  videoType: VideoType.UMBER_VIDEO;
  coverImg: string;
};

export type PageBlockStory = {
  id: string;
  blockType: ContentBlockType.VIDEO_CONTENT_BLOCK;
  title: string;
  author: {
    firstName: string;
    lastName: string;
    avatar: string;
    shortRole: string;
    fullRole: string;
  };
} & (YouTubeVideo | UmberVideo);

export type PageBlockStories = {
  id: string;
  blockType: ContentBlockType.STORIES_CONTENT_BLOCK;
  stories: PageBlockStory[];
};

export type PageSection = {
  id: string;
  type: PostSectionType;
  withNavigate: boolean;
  title?: string;
  titleMaxWidth?: number;
  titleHidden: boolean;
  titleType: "default" | "center";
  withoutPaddings: boolean;
  withoutBackground: boolean;
  marginTop: MarginType;
  marginBottom: MarginType;
  border?: boolean;
  blocks: Array<
    | PageBlockImage
    | PageBlockCases
    | PageBlockReviews
    | PageBlockContactUs
    | PageBlockSeparator
    | PageBlockEmbed
    | PageBlockPosts
    | PageBlockText
    | PageBlockList
    | PageBlockQuote
    | PageBlockStories
  >;
  description?: Array<{ title: string }>;
  caption?: string;
  horizontalViewType:
    | "phone-lg"
    | "tablet"
    | "tablet-lg"
    | "desktop"
    | "desktop-lg"
    | "desktop-xlg";
};

export type Page = {
  created: string;
  title: string;
  type: PageType;
  header: PageHeader;
  seo: PageSeo;
  sidebar: PageSidebar;
  mainSections: PageSection[];
  contentSections: PageSection[];
  sidebarWidgets: Array<PageWidget>;
  headerBackgroundImage?: string;
};

export type PageSidebar = {
  marginTop?: number;
};

export type PageWidget = ContactUsWidget;

export type ContactUsWidget = {
  id: string;
  widgetType: WidgetType;
  theme: Theme;
  sticky: boolean;
  stickyOffset?: number;
};

// ********** HOME PAGE CONTRACTS ********** //

export type WorkflowStageDto = {
  title: string;
  accentColor: string;
  steps: string[];
};
