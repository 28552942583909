import React from "react";
import CalendarIcon from "../../../../../../../icons/CalendarIcon";
import EyeIcon from "../../../../../../../icons/EyeIcon";
import ClockIcon from "../../../../../../../icons/ClockIcon";
import { PostStatisticsDto } from "../../../../../../../api/contracts";
import { getDateWithDay } from "../../../../../../../utils/dateUtils";
import scss from "./styles.module.scss";

interface IProps {
  statisticsInfo: PostStatisticsDto;
  contentColor?: string;
}

const PostStatistics = ({ statisticsInfo, contentColor }: IProps) => {
  return (
    <div
      className={scss.bigPostStatistics}
      style={{ color: contentColor || undefined }}
    >
      <div className={scss.bigPostDate}>
        <CalendarIcon />
        {getDateWithDay(statisticsInfo.createdDate)}
      </div>
      <div className={scss.bigPostViewsCount}>
        <EyeIcon />
        {`${statisticsInfo.viewsCount} views`}
      </div>
      {!!statisticsInfo.timeToRead && (
        <div className={scss.bigPostTimeToRead}>
          <ClockIcon />
          {`${statisticsInfo.timeToRead} min read`}
        </div>
      )}
    </div>
  );
};

export default PostStatistics;
