import WorkflowCard from "./WorkflowCard";
import Grid from "../../../../components/shared/Grid/Grid";
import scss from "./styles.module.scss";

const WorkflowPrinciples = () => {
  return (
    <div className={scss.section}>
      <div className={scss.principles}>
        <div className={scss.principlesLeftLine} />
        <div className={scss.principlesTitle}>Principles</div>
        <div className={scss.principlesRightLine} />
      </div>

      <Grid columns={{ lg: 4, md: 2 }} mobileScroll className={scss.grid}>
        <WorkflowCard
          title="Transparency"
          description="By cooperating with us, you get transparency of processes just like
            in your company."
          image="/assets/principles-transparency.webp"
        />
        <WorkflowCard
          title="User-Centered Design"
          description="If content is king for marketing, then users are kings for design.
            After all, their needs and pains are a guide for design."
          image="/assets/principles-design.webp"
        />
        <WorkflowCard
          title="Technical Expertise"
          description="  A properly selected technical stack and built architecture are the
            foundation for the project. And we are building a solid foundation."
          image="/assets/principles-expertise.webp"
        />
        <WorkflowCard
          title="Analytics"
          description="The data shows us the next steps in product development and directly
            influences business goals and their achievement."
          image="/assets/principles-analytics.webp"
        />
      </Grid>
    </div>
  );
};

export default WorkflowPrinciples;
