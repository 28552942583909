import {memo, SVGProps} from 'react';

const ArrowBig = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M56 50.6667H3.26379e-07L3.26378e-07 56L56 56V50.6667Z" fill="currentColor"/>
            <path d="M56 52V0H50.6667V52H56Z" fill="currentColor"/>
            <path d="M56 50.6667L4 0L0 4L53.2374 56L56 50.6667Z" fill="currentColor"/>
        </svg>

    );
};

export default memo(ArrowBig);