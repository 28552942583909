import React from "react";
import cn from "classnames";
import MailIcon from "../../../icons/MailIcon";
import HeaderNavLink from "../../Header/components/HeaderNavLink/HeaderNavLink";
import resolutionStore from "../../../store/ResolutionStore";
import { observer } from "mobx-react-lite";
import useOpenContactUs from "../../../hooks/useOpenContactUs";
import Telegram from "../../shared/social/Telegram";
import WhatsApp from "../../shared/social/WhatsApp";
import PhoneIcon from "../../../icons/PhoneIcon";
import scss from "./styles.module.scss";

type Props = {
  containerClassName?: string;
  title?: string;
  pageWithSidebar?: boolean;
  theme?: "light" | "dark";
};

const BlockContactUs = ({
  containerClassName,
  title,
  pageWithSidebar,
  theme = "dark",
}: Props) => {
  const { phone, phoneLg, tablet, tabletLg, laptop, laptopHd, laptopLg } =
    resolutionStore.resolution;
  const isLaptop = laptopLg || laptopHd || laptop;
  const isMobile = phone || phoneLg || tablet;
  const openContactUs = useOpenContactUs();

  const teamMembers =
    (!pageWithSidebar && isLaptop) || tabletLg
      ? [
          {
            src: "/assets/val-contact.webp",
            alt: "valentin",
          },
          { src: "/assets/artem-contact.webp", alt: "artem" },
          {
            src: "/assets/anastasia-contact.webp",
            alt: "Anastacia",
          },
        ]
      : isMobile
      ? [{ src: "/assets/artem-contact.webp", alt: "artem" }]
      : [
          { src: "/assets/artem-contact.webp", alt: "artem" },
          {
            src: "/assets/anastasia-contact.webp",
            alt: "Anastacia",
          },
        ];

  const style = {
    backgroundImage:
      theme === "dark"
        ? "url(/assets/contactUsGradientDark.webp)"
        : "url(/assets/contactUsGradientLight.webp)",
  };

  return (
    <div
      style={style}
      className={cn(scss.container, containerClassName, scss[theme])}
    >
      <div className={scss.titleWrapper}>
        <h2 className={scss.title}>
          {title ||
            "Your journey to creating an amazing product can begin with our expertise"}
        </h2>
      </div>
      <div className={scss.socialsWrapper}>
        <a
          href="https://t.me/valan"
          target="_blank"
          rel="nofollow noreferrer"
          aria-label="Telegram"
        >
          <Telegram size={48} />
        </a>
        <a
          href="https://wa.me/380964429365"
          target="_blank"
          rel="nofollow noreferrer"
          aria-label="WhatsApp"
        >
          <WhatsApp size={48} />
        </a>
      </div>
      <div className={scss.teamWrapper}>
        <div className={scss.teamText}>
          We will contact you within <span>24 business hours</span>
        </div>
        <div className={scss.avatarsWrapper}>
          {teamMembers.map((item, i) => (
            <div className={scss.avatarWrapper} key={i}>
              <div className={scss.avatar}>
                <img width={64} height={64} src={item.src} alt={item.alt} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={scss.teamTextWrapper}>
        <div className={scss.teamText}>
          We will contact you within <span>24 business hours</span>
        </div>
      </div>
      <div className={scss.separator} />
      <div className={scss.actionsWrapper}>
        <HeaderNavLink
          type="button"
          onClick={openContactUs}
          className={scss.contactUsButton}
          borderColor={theme === "dark" ? "var(--green-2)" : "var(--grey-9)"}
          contentColor={theme === "dark" ? "var(--green-2)" : "var(--grey-9)"}
          background={"transparent"}
        >
          <MailIcon />
          contact us
        </HeaderNavLink>
        <HeaderNavLink
          type="externalLink"
          href="https://calendly.com/umber-art/30min"
          target="_blank"
          className={scss.callButton}
          borderColor="transparent"
        >
          <PhoneIcon />
          Schedule a call
        </HeaderNavLink>
      </div>
    </div>
  );
};

export default observer(BlockContactUs);
